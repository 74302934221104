import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Button, Grid } from "@mui/material";
import SectionA from "./SectionA";
import SectionB1 from "./SectionB1";
import SectionB2 from "./SectionB2";
import SectionC from "./SectionC";
import { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import { localeCurrency } from "../../Store/Store";
import DialogLafaz from "./DialogLafaz";

// const nisab: number = 23035;
const nisab: number = 2303;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
  // '&:last-child':{
  //   borderBottom: 0,
  // }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  //borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [totalA, setTotalA] = React.useState(0);
  const [totalB1, setTotalB1] = React.useState(0);
  const [totalB2, setTotalB2] = React.useState(0);
  const [totalC, setTotalC] = React.useState(0);
  const [sectionA_exist, setSectionAExist] = React.useState(false);
  const [jumlahABC, setJumlahABC] = React.useState(0);
  const [simpananTahunan, setSimpananTahunan] = React.useState(0);
  const [jumlahZakatSetahun, setJumlahZakatSetahun] = React.useState(0);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleTotalChangeA1 = (value: React.SetStateAction<number>) => {
    setTotalA(value);
    setSectionAExist(!!value);
    calculateJumlahABC(totalA, totalB1, totalB2, totalC);
  };

  const handleTotalChangeB1 = (value: React.SetStateAction<number>) => {
    setTotalB1(value);
    calculateJumlahABC(totalA, totalB1, totalB2, totalC);
  };

  const handleTotalChangeB2 = (value: React.SetStateAction<number>) => {
    setTotalB2(value);
  };

  const handleTotalChangeC = (value: React.SetStateAction<number>) => {
    setTotalC(value);
    calculateJumlahABC(totalA, totalB1, totalB2, totalC);
  };

  const calculateJumlahABC = (a: number, b1: number, b2: number, c: number) => {
    const result = a - (b1 + b2 + c);
    setJumlahABC(result);
    // console.log("test:", result);
  };

  const handleSimpananTahunanChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    // You might want to add some validation or checks here if needed
    setSimpananTahunan(newValue);
    // Update the total whenever the input changes
    updateJumlahZakat();
  };

  const updateJumlahZakat = () => {
    // Calculate the total and update the state
    const jumlahZakatSetahun =
      ((totalA - (totalB1 + totalB2 + totalC) - simpananTahunan) * 2.5) / 100;
    setJumlahZakatSetahun(jumlahZakatSetahun >= 0 ? jumlahZakatSetahun : 0);
  };

  useEffect(() => {
    calculateJumlahABC(totalA, totalB1, totalB2, totalC);

    updateJumlahZakat();
  });

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      // href="/"
      // onClick={handleClick}
    >
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
      Utama
    </Link>,
    <Typography key="3" color="text.primary">
      Bakul Zakat
    </Typography>,
  ];

  return (
    <>
      <Box style={{ paddingBottom: "20px" }}>
        <Breadcrumbs
          // separator={<NavigateNextIcon fontSize="small" />}
          separator="›"
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Typography variant="h6">Kalkulator Zakat Pendapatan</Typography>
      <Grid container direction="column">
        <Grid item xs={12} p={3}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>BAHAGIAN A: KOMPONEN PENDAPATAN</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SectionA onResultChange={handleTotalChangeA1}></SectionA>
            </AccordionDetails>
          </Accordion>
          <Grid container spacing={1} p={1}>
            <Grid item xs={8}>
              <Typography textAlign={"end"}>Jumlah [A] :</Typography>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ backgroundColor: "#E9E9E9", width: "200px" }} px={1}>
                {" "}
                RM {totalA || 0}{" "}
              </Box>
            </Grid>
          </Grid>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>B1 : Had Kifayah Isi Rumah</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SectionB1
                onResultChange={handleTotalChangeB1}
                sectionA_exist={sectionA_exist}
              />
            </AccordionDetails>
          </Accordion>
          <Grid container spacing={1} p={1}>
            <Grid item xs={8}>
              <Typography textAlign={"end"}>Jumlah [B] :</Typography>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ backgroundColor: "#E9E9E9", width: "200px" }} px={1}>
                {" "}
                RM {totalB1 || 0}
              </Box>
            </Grid>
          </Grid>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>
                B2 : Had Kifayah Tambahan (Jika Berkaitan)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <SectionB2
                  onResultChange={handleTotalChangeB2}
                  sectionA_exist={false}
                ></SectionB2>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Grid container spacing={1} p={1}>
            <Grid item xs={8}>
              <Typography textAlign={"end"}>Jumlah [B2] :</Typography>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ backgroundColor: "#E9E9E9", width: "200px" }} px={1}>
                {" "}
                RM {totalB2 || 0}{" "}
              </Box>
            </Grid>
          </Grid>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography>BAHAGIAN C: TOLAKAN LAIN</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <SectionC
                  onResultChange={handleTotalChangeC}
                  sectionA_exist={false}
                ></SectionC>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Grid container spacing={1} p={1}>
            <Grid item xs={8}>
              <Typography textAlign={"end"}>Jumlah [C] :</Typography>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ backgroundColor: "#E9E9E9", width: "200px" }} px={1}>
                {" "}
                RM {totalC || 0}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column">
            <Grid
              item
              xs={12}
              sx={{ backgroundColor: "#E9E9E9", height: "60px", p: 2 }}
            >
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography>D. JUMLAH BAHAGIAN (A) - (B + C )</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#E9ECEF",
                      border: "1px solid #CED4DA",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                    }}
                  >
                    <div style={{ padding: "5px" }}>RM </div>
                    <input
                      type="number"
                      id="jumlahABC"
                      value={jumlahABC}
                      style={{
                        height: "30px",
                        width: "100%",
                        padding: "0",
                        border: "0px solid #CED4DA",
                        paddingLeft: "0.6rem",
                        outline: "none",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ backgroundColor: "#F6F6F6", height: "60px", p: 2 }}
            >
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography>
                    E. TOLAK SIMPANAN TAHUNAN SEMASA TABUNG HAJI (jika ada)
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#E9ECEF",
                      border: "1px solid #CED4DA",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                    }}
                  >
                    <div style={{ padding: "5px" }}>RM</div>
                    <input
                      type="number"
                      id="simpananTahunan"
                      value={simpananTahunan}
                      onChange={handleSimpananTahunanChange}
                      style={{
                        height: "30px",
                        width: "100%",
                        padding: "0",
                        border: "0px solid #CED4DA",
                        paddingLeft: "0.6rem",
                        outline: "none",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ backgroundColor: "#E9E9E9", height: "60px", p: 2 }}
            >
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography>JUMLAH ZAKAT ANDA (SETAHUN)</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#E9ECEF",
                      border: "1px solid #CED4DA",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                    }}
                  >
                    <div style={{ padding: "5px" }}>RM </div>
                    <input
                      type="number"
                      id="jumlahZakatSetahun"
                      value={jumlahZakatSetahun.toFixed(2)}
                      style={{
                        height: "30px",
                        width: "100%",
                        padding: "0",
                        border: "0px solid #CED4DA",
                        paddingLeft: "0.6rem",
                        outline: "none",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {jumlahZakatSetahun < nisab ? (
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "#D3D3D3",
                  p: 2,
                  mt: 2,
                  height: "70px",
                }}
              >
                <Typography color={"red"}>
                  Anda tidak layak dikenakan zakat kerana tidak mencapai nisab{" "}
                  <strong>{localeCurrency(nisab)}</strong>
                </Typography>
              </Grid>
            ) : null}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                fullWidth
                // sx={{ backgroundColor: "#808080" }}
              >
                Reset
              </Button>
              {/* <Button
                variant="contained"
                fullWidth
                // sx={{ backgroundColor: "#808080" }}
              >
                Bayar Zakat
              </Button> */}
              <DialogLafaz amount={jumlahZakatSetahun} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
