import React, { Fragment } from "react";

import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import MuiAlert from "@material-ui/lab/Alert";

import CircularProgress from "@mui/material/CircularProgress";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

import { auth } from "../../config/Firebase";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Form {
  state?: string;
  district?: string;
  refid?: string;
  [key: string]: number | string | object | any;
}

interface PropsTotal {
  id?: string;
  data?: any;
  callback?: (data: any) => void;
}

const CategoryForm: React.FC<PropsTotal> = ({ id, data, callback }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [querystatus, setQuerystatus] = React.useState<string>("");
  const [token, setToken] = React.useState<any>("");
  const [form, setForm] = React.useState<Form>({
    state: "",
    district: "",
    refid: "",
    CheckedTick: true,
  });

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof data !== "undefined") {
      if (typeof data !== "undefined") {
        setForm(data);
      }
    }
  }, [data]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event?.target?.id ? event?.target?.id : event?.target?.name]:
        event.target.value,
    });
  };

  const saveForm = () => {
    setLoading(true);
    const data = new URLSearchParams();
    // console.log(form);
    for (let f in form) {
      if (form[f] !== "" && form[f] !== null) {
        data.append(f, form[f]);
      }
    }

    if (form.name === "" || form.description === "" || form.code === "") {
      window.alert("Missing required fields.");

      setLoading(false);
      return false;
    }

    let catapi: string = "";
    if (
      typeof form?.id !== "undefined" &&
      form?.id !== "new" &&
      form?.id !== "undefined"
    ) {
      catapi = "/api/category/update/" + form?.id;
    } else {
      catapi = "/api/category/create";
    }

    fetch(catapi, {
      method: "post",
      body: data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);

        setQuerystatus(data.metadata.status);
        if (data?.metadata?.status === "ERROR") {
        } else {
          if (data?.category) {
            // console.log(data?.category, form);
            let newform: any = { ...form };
            for (let n in form) {
              if (typeof data.category[n] !== "undefined") {
                newform[n] = data.category[n];
              }
            }
            if (typeof newform.id === "undefined") {
              newform.id = data.category.id;
            }
            if (
              typeof data?.category?.photourl !== "undefined" &&
              data?.category?.photourl !== ""
            ) {
              newform.photourl = data?.category?.photourl;
            }
            setForm(newform);
          }
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setQuerystatus("ERROR");
        setLoading(false);
      });
  };

  const gotoList = () => {
    if (callback) {
      callback(form);
    } else {
      navigate(`/dashboard/category/`);
    }
  };

  // console.log(data, form);
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {querystatus !== "" && (
        <Grid item xs={12} style={{ paddingBottom: "20px" }}>
          <Fragment>
            {querystatus === "NEW_DUPLICATE" && (
              <Alert severity="error">
                Query failed - Agency already registered.
              </Alert>
            )}
            {querystatus === "UPDATED" && (
              <Alert severity="success">Agency has been updated.</Alert>
            )}
            {querystatus === "SAVED" && (
              <Alert severity="success">Agency has been saved.</Alert>
            )}
            {querystatus === "ERROR" && (
              <Alert severity="error">Query failed - Unknown Reason.</Alert>
            )}
          </Fragment>
        </Grid>
      )}

      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>
            Name<i style={{ color: "red" }}>*</i>
          </FormLabel>
          <br></br>
          <TextField
            size="small"
            name="name"
            id="name"
            label=""
            value={form?.name ? form?.name : ""}
            onChange={handleInputChange}
            variant="outlined"
            style={{ width: "100%" }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>
            Description<i style={{ color: "red" }}>*</i>
          </FormLabel>
          <br></br>
          <TextField
            size="small"
            name="description"
            id="description"
            label=""
            value={form?.description ? form?.description : ""}
            onChange={handleInputChange}
            variant="outlined"
            style={{ width: "100%" }}
          />
        </FormControl>
      </Grid>

      {form?.photourl && form?.photourl !== "" && (
        <Grid item xs={12} sm={12}>
          <img src={form?.photourl} alt="logo" style={{ width: "100%" }} />
        </Grid>
      )}
      <Grid item xs={12}>
        <label className="label">
          <i>
            Note: Fields marked with asterik (<i style={{ color: "red" }}>*</i>{" "}
            ) are compulsory fields.
          </i>
        </label>
      </Grid>
      <Grid item xs={12} sm={5} md={3}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => saveForm()}
          startIcon={<SaveIcon />}
          fullWidth
          disabled={loading ? true : false}
        >
          Save
        </Button>
      </Grid>
      {loading ? (
        <>
          <Grid item xs={12} sm={1} md={1} style={{ textAlign: "center" }}>
            <CircularProgress size={20} />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            {form?.id ? "Updating..." : "Saving..."}
          </Grid>
        </>
      ) : (
        <Fragment>
          <Grid item xs={12} sm={2} md={6}></Grid>
          <Grid item xs={12} sm={5} md={3}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => gotoList()}
              startIcon={<ListAltIcon />}
              fullWidth
              disabled={loading ? true : false}
            >
              Cancel
            </Button>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default CategoryForm;
