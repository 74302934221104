import React from "react";
import { Typography, Grid, Paper } from "@mui/material";
import LatestContributor from "../../pages/Store/LatestContributor";
import ProductStacked from "../Charts/ProductStacked";
import { auth } from "../../config/Firebase";

// const overview = [
//   {
//     header: "Jumlah Kempen",
//     content: "",
//   },
//   {
//     header: "Jumlah Agihan",
//     content: "",
//   },
//   {
//     header: "Bilangan Agihan",
//     content: "",
//   },
//   {
//     header: "Jumlah Peserta",
//     content: "",
//   },
// ];

function Index() {
  const [token, setToken] = React.useState<any>("");
  const [products, setProducts] = React.useState<any[]>();
  const [overview, setOverview] = React.useState<any[]>();
  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (
      typeof products === "undefined" &&
      typeof token !== "undefined" &&
      token !== ""
    ) {
      fetch("/api/contributor/product", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setProducts(data.list);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }, [token, products]);

  React.useEffect(() => {
    if (
      typeof overview === "undefined" &&
      typeof token !== "undefined" &&
      token !== ""
    ) {
      fetch("/api/contributor/dboverview", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setOverview(data.list);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }, [token, overview]);

  return (
    <div className="card_container">
      <Typography variant="h6" fontWeight="bold" marginTop="10px">
        Selamat Datang ke LZS CrowdZakat
      </Typography>
      {/* <Typography variant="subtitle1">
        Mula perniagaan anda pada hari ini
      </Typography> */}
      <Grid
        container
        spacing={2}
        marginTop="8px"
        alignItems="stretch"
        justifyContent="center"
      >
        {overview &&
          overview.map((a: any, index: any) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                sx={{
                  backgroundColor: "#F5F5F5",
                  borderColor: "#707070",
                  padding: "8px",
                  boxShadow: "none",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color="#003A19"
                  textAlign="end"
                >
                  {a.header}
                </Typography>
                <Typography
                  variant="h4"
                  color="#003A19"
                  textAlign="end"
                  fontWeight="bold"
                  sx={{
                    overflow: "hidden",
                    // textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {a.content}
                </Typography>
              </Paper>
            </Grid>
          ))}
        <Grid item xs={12} lg={6}>
          <Paper
            sx={{
              backgroundColor: "#F5F5F5",
              borderColor: "#707070",
              padding: "8px",
              boxShadow: "none",
            }}
          >
            <Typography variant="h6">Kempen</Typography>
            <div style={{ height: "50vh" }}>
              <ProductStacked datas={products} />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper
            sx={{
              backgroundColor: "#F5F5F5",
              borderColor: "#707070",
              padding: "8px",
              boxShadow: "none",
            }}
          >
            <Typography variant="h6">Pemberi Zakat Aktif</Typography>
            <LatestContributor />
          </Paper>
        </Grid>
      </Grid>
      {/* <Paper>
        
      </Paper> */}
    </div>
  );
}

export default Index;
