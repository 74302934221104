import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
// import { Headers, fetchPlus } from "../../store/headers";

interface PropsTotal {
  id: string;
  label?: string;
  row?: number | string;
  defaultValue?: string;
  setValue?: (
    id: string,
    value: string | AgencyArray[],
    row?: number | string
  ) => void;
  filter?: number | string;
  data?: any[];
  token?: string;
}

export interface Category {
  code?: number;
  name?: string;
}

export interface AgencyArray {
  code: number;
  name?: string;
}

const SelectProduct: React.FC<PropsTotal> = (props) => {
  const { id, defaultValue, setValue, row, data, token, label } = props;
  const [categorylist, setCategoryList] = React.useState<Category[]>();
  const [selectagent, setSelectagent] = React.useState<string>(
    defaultValue || ""
  );
  React.useEffect(() => {
    let isMounted = true;

    if (typeof token !== "undefined" && token !== "") {
      if (typeof data !== "undefined" && data.length > 0) {
        setCategoryList(data);
      } else {
        fetch("/api/category/list", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (isMounted) {
              setCategoryList(data.list);
            }
          });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [data, token]);

  React.useEffect(() => {
    if (typeof defaultValue !== "undefined") {
      setSelectagent(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event?: SelectChangeEvent) => {
    if (typeof event !== "undefined" && event.target && event.target.value) {
      setSelectagent(event.target.value);
      if (setValue) {
        setValue(id, event.target.value, row);
      }
    }
  };

  if (typeof categorylist !== "undefined") {
    return (
      <>
        {label && (
          <InputLabel id={id ? id : "category"}>
            {label ? label : id}
          </InputLabel>
        )}
        <Select
          labelId={id}
          label={label ? label : id}
          id={id ? id : "category"}
          value={selectagent}
          onChange={handleChange}
        >
          {categorylist &&
            categorylist.map((count: any, idx) => (
              <MenuItem
                key={idx}
                value={count.category_id ? count.category_id.toString() : ""}
              >
                {count.category_name}
              </MenuItem>
            ))}
        </Select>
      </>
    );
  } else {
    return <></>;
  }
};
export default SelectProduct;
