import React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import GroupIcon from "@mui/icons-material/Group";
import CircularProgress from "@mui/material/CircularProgress";

import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";

import { auth } from "../../config/Firebase";

import "./Index.css";

interface PropsTotal {
  q?: string;
}
const Index: React.FC<PropsTotal> = ({ q }) => {
  // const classes = useStyles();
  const [overview, setOverview] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>({
    membership: false,
    participant: false,
  });
  const [token, setToken] = React.useState<any>("");
  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  const reloadMembership = () => {
    setLoading({
      ...loading,
      membership: true,
    });
    fetch("/api/membership/grep", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setOverview(undefined);

        setLoading({
          ...loading,
          membership: false,
        });
      })
      .catch(() => {
        setLoading({
          ...loading,
          membership: false,
        });
      });
  };

  const reloadParticipant = () => {
    setLoading({
      ...loading,
      participant: true,
    });
    fetch("/api/participant/grep", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setOverview(undefined);
        setLoading({
          ...loading,
          participant: false,
        });
      })
      .catch(() => {
        setLoading({
          ...loading,
          participant: false,
        });
      });
  };

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/dashboard/overview", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            console.log(data);
            setOverview(data.overviews);
          }
        });
    };

    if (typeof overview === "undefined" && token !== "") {
      fetchData();
    }
    return () => {
      isMounted = true;
    };
  }, [overview, token]);

  const overdiews: any[] = [
    {
      id: "membership",
      label: "Membership",
      icon: <GroupIcon className="icon" />,
      value: overview?.membership ? overview?.membership : 0,
      subvalue: null,
      reload: reloadMembership,
    },
    {
      id: "participant",
      label: "Participant",
      icon: <ContactEmergencyIcon className="icon" />,
      value: overview?.participant ? overview?.participant : 0,
      subvalue: null,
      reload: reloadParticipant,
    },
  ];

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          Data Admin
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={3}
          padding={1}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          {overdiews &&
            overdiews.map((ov: any, idx: any) => (
              <Grid item xs={12} sm={6} md={6} lg={3} key={idx}>
                <Paper
                  className="overviewcard"
                  onClick={() => {
                    ov.reload();
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <div className="container">
                    <div className="iconmaincontainer">{ov.icon}</div>
                    <Grid
                      container
                      // spacing={2}
                      // padding={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className="subcontainer"
                    >
                      <Grid item xs={12} sm={3}></Grid>
                      <Grid item xs={12} sm={9} style={{ textAlign: "right" }}>
                        <Typography variant="subtitle2">{ov.label}</Typography>
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "right" }}>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "bold", color: "#2C2B78" }}
                        >
                          {loading[ov.id] ? (
                            <CircularProgress size={20} />
                          ) : (
                            <>
                              {ov.prefix} {ov.value.toLocaleString()}
                            </>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Index;
