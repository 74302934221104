function numberWithCommas(x: number) {
  return x
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function localeCurrency(x: number) {
  // return "RM " + numberWithCommas(x);
  return new Intl.NumberFormat("ms-MY", {
    style: "currency",
    currency: "MYR",
  }).format(x);
}

function BigButton() {
  return <button style={{ padding: "20px 40px" }}>Big</button>;
}

// 👇️ named exports
export { numberWithCommas, localeCurrency, BigButton };
