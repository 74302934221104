import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import CircularProgress from "@mui/material/CircularProgress";

import { auth } from "../../../config/Firebase";

const AgencyIndex = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [directory, setDirectory] = React.useState<any[]>();
  const [page, setPage] = React.useState(1);
  const [agencies, setAgencies] = React.useState<any[]>();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<any>("");

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    // setPage(value);
    if (
      typeof directory !== "undefined" &&
      typeof directory[value - 1] !== "undefined"
    ) {
      navigate("/store/agency/directory/" + directory[value - 1]);
    }
  };
  // const [token, setToken] = React.useState<any>("");
  //   const { tenant } = useSelector((state: RootState) => state.userState);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      setLoading(true);
      let fetchurl: string =
        "/api/agency/list/all?limit=20&alphabet=" + (id ? id : "");
      fetch(fetchurl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setAgencies(data.agencies);
            setLoading(false);
          }
        });
    };

    if (
      typeof agencies === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      token !== null
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [agencies, id, token]);

  React.useEffect(() => {
    if (typeof id !== "undefined" && typeof directory !== "undefined") {
      const idx = directory?.indexOf(id);
      if ((idx + 1)?.toString() !== page.toString()) {
        setPage(idx + 1);
        setAgencies(undefined);
      }
    }
  }, [id, page, directory]);

  React.useEffect(() => {
    if (typeof directory === "undefined") {
      let newalpha: any[] = [];
      for (let i = 65; i <= 90; i++) {
        newalpha.push(String.fromCharCode(i));
      }
      setDirectory(newalpha);
    }
  }, [directory]);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        minHeight: "calc(100vh - 70px)",
      }}
    >
      <Container fixed>
        <Grid
          container
          spacing={2}
          padding={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Direktori Toko Mustahik
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Grid
                container
                spacing={2}
                padding={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {agencies &&
                  agencies.length > 0 &&
                  agencies.map((agency: any, idx: any) => (
                    <Grid item xs={12} sm={3} key={idx}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        {agency.name}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {page}
            {directory && directory.length > 0 && (
              <Pagination
                count={directory.length}
                variant="outlined"
                page={page}
                onChange={handleChange}
                siblingCount={2}
                boundaryCount={3}
                renderItem={(item: any) => (
                  <PaginationItem {...item} page={directory[item.page - 1]} />
                )}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AgencyIndex;
