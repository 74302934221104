import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface SectionAProps {
  onResultChange?: (result: number) => void;
}

interface Values {
  A1: number;
  A2: number;
  A3: number;
  A4: number;
}

const SectionA: React.FC<SectionAProps> = ({ onResultChange }) => {
  const [values, setValues] = useState<Values>({
    A1: 0,
    A2: 0,
    A3: 0,
    A4: 0,
  });

  useEffect(() => {
    if (values !== null) {
      const newTotal = values.A1 + values.A2 + values.A3 + values.A4;

      onResultChange && onResultChange(newTotal);
    }
  }, [onResultChange, values]);

  const handleInputChange = (
    event: { target: { value: string } },
    id: string
  ) => {
    const newValue = event.target.value;
    setValues((prevValues) => ({ ...prevValues, [id]: parseFloat(newValue) }));
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#2980B9" }}>
            <TableRow>
              <TableCell sx={{ color: "#ffffff" }}>Bil</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Butiran</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>RM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className={`tableRow ${
                  values.A1 !== null && values.A1 !== 0
                    ? "blackText"
                    : "grayText"
                }`}
              >
                A1
              </TableCell>
              <TableCell
                className={`tableRow ${
                  values.A1 !== null ? "blackText" : "grayText"
                }`}
              >
                Hasil Penggajian & Upah Tahunan
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <input
                    type="number"
                    id="A1"
                    value={values.A1}
                    onChange={(e) => handleInputChange(e, "A1")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A2</TableCell>
              <TableCell>Hasil Bebas & Profesional Tahunan</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <input
                    type="number"
                    id="A2"
                    value={values.A2}
                    onChange={(e) => handleInputChange(e, "A2")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A3</TableCell>
              <TableCell> Hasil al-Mustaghallat</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <input
                    type="number"
                    id="A3"
                    value={values.A3 || ""}
                    onChange={(e) => handleInputChange(e, "A3")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A4</TableCell>
              <TableCell>Hasil Pemberian atau Agihan</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <input
                    type="number"
                    id="A4"
                    value={values.A4 || ""}
                    onChange={(e) => handleInputChange(e, "A4")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SectionA;
