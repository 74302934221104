import React, { useCallback } from "react";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Type {
  [key: string]: number | string | object | any;
}

interface DialogProps {
  id?: any;
  type?: string;
  data?: Type;
  callback?: (status?: string, url?: string) => void;
  token: string;
  name?: string;
  maxsize?: number;
  folder?: boolean;
}

interface Upload {
  status?: string | any;
  statusMsg?: string;
  name?: string;
  size?: any;
  [key: string]: number | string | object | any;
}

const presigned_server = "/api/user/s3/presigned";

const DialogPanel = (props: DialogProps) => {
  const { id, type, token, name, callback, maxsize, folder } = props;

  const [upload, setUpload] = React.useState<Upload>({
    status: "OK",
    statusMsg: "",
    name: "",
  });

  // ``uploadFile` accepts the current filename and the pre-signed URL. It then uses `Fetch API`
  // to upload this file to S3 at `play.min.io:9000` using the URL:

  const uploadFiles = useCallback(
    (file: any, url: any, tmpurl: any) => {
      fetch(url, {
        method: "PUT",
        body: file,
      })
        .then((e: any) => {
          // console.log(e, file);
          let info: object | any = { file: {}, upload: {} };
          for (let f in file) {
            info.file[f] = file[f];
          }
          for (let f in e) {
            info.upload[f] = e[f];
          }
          // updateData(file, JSON.stringify(info));
          setUpload({
            status: "COMPLETED",
            statusMsg: "Uploaded",
            name: file.name,
          });
          if (typeof callback !== "undefined") {
            callback("COMPLETED", tmpurl);
          }
        })
        .catch((e) => {
          console.error(e);
          setUpload({
            status: "ERROR",
            statusMsg: e,
            name: file.name,
          });
        });
    },
    [callback]
  );

  // `retrieveNewURL` accepts the name of the current file and invokes the `/presignedUrl` endpoint to
  // generate a pre-signed URL for use in uploading that file:

  const retrieveNewURL = useCallback(
    (file: any, cb: any) => {
      //fetch(`${presigned_server}?file=${profile?.email}/${agency}/${file.name}`, Headers()).then((response) => {
      let fname: string = "";
      if (
        typeof folder !== "undefined" &&
        folder &&
        typeof name !== "undefined" &&
        name !== ""
      ) {
        fname = name + "/" + file?.name;
      } else if (
        ((typeof folder !== "undefined" && !folder) ||
          typeof folder === "undefined") &&
        typeof name !== "undefined" &&
        name !== ""
      ) {
        fname = name;
      } else {
        fname = file?.name;
      }
      let filename: string = encodeURIComponent(type + "/" + id + "/" + fname);
      filename = filename.replace(/&/gi, "%26");

      // console.log(token);

      if (token !== "") {
        fetch(`${presigned_server}?file=${filename}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response: any) => {
            // console.log(response);
            if (
              typeof response?.error !== "undefined" &&
              response?.error !== ""
            ) {
              //   console.log(response);
              window.alert("error");
            } else {
              response.json().then((resp: any) => {
                cb(file, resp.url, resp.tmp_url);
              });
            }
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        window.alert("missing token");
      }
    },
    [token, id, name, type, folder]
  );

  const signUpload = useCallback(
    (file: any, tmptoken?: any) => {
      // console.log(token);

      retrieveNewURL(file, (file: any, url: any, tmpurl?: any) => {
        // Upload the file to the server.
        // console.log(">>>", url, tmpurl, file);
        //url = url.replace("%2F", "/");
        uploadFiles(file, url, tmpurl);
      });
    },
    [retrieveNewURL, uploadFiles]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      console.log(acceptedFiles);
      const maxfilesize = maxsize ? maxsize * 1000000 : 2000000;
      if (acceptedFiles[0].size <= maxfilesize) {
        // if (acceptedFiles[0].size <= 2000000) {
        setUpload({
          status: "UPLOADING",
          statusMsg: "Uploading...",
          name: acceptedFiles[0].name,
        });
        signUpload(acceptedFiles[0], token);
      } else {
        setUpload({
          status: "ERROR",
          statusMsg:
            "Exceed maximum file size, Maximum Allowed file size " +
            (maxfilesize / 1000000).toFixed(2) +
            "MB",
          size: acceptedFiles[0].size,
          name: acceptedFiles[0].name,
        });
      }
    },
    [token, signUpload, maxsize]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "10px",
        paddingTop: "5%",
        textAlign: "center",
        verticalAlign: "middle",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <>
          {upload.status === "COMPLETED" && (
            <AddTaskIcon style={{ color: "#00ff00" }} />
          )}
          {upload.status === "ERROR" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ErrorIcon style={{ color: "#ff0000" }} />
              <br />
              <Typography variant="caption">{upload?.statusMsg}</Typography>
            </div>
          )}
          {upload.status === "UPLOADING" ? (
            <CircularProgress />
          ) : (
            <p>Drag 'n' drop photo files here, or click to select files</p>
          )}
        </>
      )}
    </div>
  );
};

export default DialogPanel;
