import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Alert, AlertTitle } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import type { RootState } from "../store/index";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

// export const IsGrantedAccess = (module: string, exit?: boolean) => {
export const IsGrantedAccess = (module: string, profile?: any) => {
  // const { profile } = useSelector((state: RootState) => state.userState);
  // return true;
  let granted: boolean = false;
  if (profile && profile.access) {
    const access = profile?.access.find((acc: any) => {
      return module === acc.name;
    });

    if (typeof access !== "undefined") {
      granted = true;
    } else {
      granted = false;
    }
  }
  return granted;

  // if (exit && !granted) {
  //   console.log(exit, granted);
  //   return (
  //     <AuthDisplay>
  //       <Alert severity="error">
  //         <AlertTitle>
  //           <strong>Forbidden!</strong>
  //         </AlertTitle>
  //         <Typography>
  //           You don’t have enough permission to access this module.x
  //         </Typography>
  //       </Alert>
  //     </AuthDisplay>
  //   );
  // } else {
  //   return granted;
  // }
};

export const AuthDisplay: React.FunctionComponent<any> = (props) => {
  const { children } = props;
  const { profile } = useSelector((state: RootState) => state.userState);

  return (
    <div
      style={{
        // width: "calc(100vw - 50px)",
        // height: "100vh",
        border: "0px solid #ff0000",
        paddingTop: "50px",
      }}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Grid
          container
          spacing={5}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {!profile?.id && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img src="/images/logo.png?v=1" width="120" alt="" />
            </Grid>
          )}
          <Grid item xs={12}>
            {children ? (
              children
            ) : (
              <Alert severity="error">
                <AlertTitle>
                  <strong>Forbidden!</strong>
                </AlertTitle>
                <Typography>
                  You don’t have enough permission to access this module.
                </Typography>
              </Alert>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

// export const IsGrantedAccess: any = {};
