import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Info from "./Info";

const useStyles = makeStyles((theme) => ({
  rootheader: {
    flexGrow: 1,
    width: "100%",
    border: "0px solid #ff0000",
    margin: "0px !important",
    padding: "0px !important",
  },
  center: {
    textAlign: "center",
  },
}));

interface Type {
  [key: string]: number | string | object | any;
}

interface DialogProps {
  index?: any;
  open?: boolean;
  data?: Type;
  callback?: (data: Type) => void;
  user?: any;
}

const DialogPanel = (props: DialogProps) => {
  const classes = useStyles();
  const { callback, open, data } = props;

  const handleClose = (status: boolean) => {
    //callback to calendar... placeholder for feuture

    if (status) {
      if (callback) {
        callback({ status: status });
      }
    } else {
      if (callback) {
        callback({ status: status });
      }
    }
  };

  console.log(data);
  return (
    <Dialog
      disableEscapeKeyDown
      open={open || false}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>
        <Grid container spacing={0} className={classes.rootheader}>
          <Grid item xs={12} sm={12}>
            {data?.coname ? data?.coname : data?.dig_lit_id}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Info data={data} />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPanel;
