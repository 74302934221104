// import React from "react";
// import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Stack,
  Button,
} from "@mui/material";

const steps = [
  {
    header: "Pemesanan",
    description: "Tanggal Pemesanan",
    icon: " ",
  },
  {
    header: "Pembayaran",
    description: "Tanggal Pembayaran",
    icon: " ",
  },
  {
    header: "Diproses",
    description: "Tanggal proses",
    // icon: <LocalShippingIcon />,
  },
  {
    header: "Terima Pesanan",
    description: "Tanggal Terima",
    icon: " ",
  },
];

const handleButtonClick = () => {};

const OrderInfo = () => {
  return (
    <div>
      <Typography variant="h5" fontWeight="bold">
        Pesanan
      </Typography>
      <Box
        sx={{
          width: "100%",
          border: "1px solid black",
          borderRadius: "5px",
          backgroundColor: "#F8F8F8",
          borderColor: "#D3CFCF",
          padding: "25px",
          marginTop: "20px",
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold" marginBottom="20px">
          Order ID:{" "}
        </Typography>
        <Stepper activeStep={0} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step.header}</StepLabel>
              <StepContent>{step.description}</StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Two Grid items side by side */}
      <Grid container spacing={2} marginTop="25px">
        <Grid item xs={12} sm={6}>
          {/* First Grid item */}
          <Paper
            style={{
              boxShadow: "none",
              backgroundColor: "#F8F8F8",
              padding: "20px",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              marginBottom="10px"
            >
              Items
            </Typography>
            <Grid container>
              <Grid item xs={4.5}>
                <Typography fontWeight="bold">Nama Produk</Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography fontWeight="bold">Harga</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography fontWeight="bold">Kuantiti</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography fontWeight="bold">Total</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ paddingTop: "20px", marginBottom: "20px" }}
              // key={index}
            >
              <Grid item xs={4.5}>
                <Stack direction="row">
                  <img
                    // src={key.photos[0] ? key.photos[0] : "/images/default.png"}
                    // alt={key.product_name}
                    style={{
                      width: "40px",
                      height: "45px",
                      marginRight: "10px",
                    }}
                  />
                  <Stack direction="column">
                    <Typography fontSize={12} fontWeight="bold">
                      {/* {key.agency_name} */}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={2.5}>
                {/* <Typography>Rp.{key.price}</Typography> */}
              </Grid>
              <Grid item xs={2}>
                {/* <Typography>{key.quantity}</Typography> */}
              </Grid>
              <Grid item xs={3}>
                {/* <Typography>Rp. {key.total_price}</Typography> */}
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                paddingTop: "10px",
                borderTop: "1px solid black",
                borderColor: "#e0e0e0",
              }}
            >
              <Grid item>
                <Typography fontSize={15}>Harga Jualan</Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight="bolder" fontSize={20}>
                  -
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                paddingTop: "10px",
                borderTop: "1px solid black",
                borderColor: "#e0e0e0",
              }}
            >
              <Grid item>
                <Typography fontSize={15}>Potongan Harga</Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight="bolder" fontSize={20}>
                  -
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                paddingTop: "10px",
                borderTop: "1px solid black",
                borderColor: "#e0e0e0",
              }}
            >
              <Grid item>
                <Typography fontSize={15}>Jumlah Pajak: </Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight="bolder" fontSize={20}>
                  {/* {localeCurrency(taxAmount ? taxAmount - 0 : 0)} */}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                paddingTop: "10px",
                borderTop: "1px solid black",
                borderColor: "#e0e0e0",
              }}
            >
              <Grid item>
                <Typography fontSize={15}>Biaya Pengiriman:</Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight="bolder" fontSize={20}>
                  {/* {localeCurrency(shippingCost ? shippingCost - 0 : 0)} */}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                paddingTop: "10px",
                borderTop: "1px solid black",
                borderColor: "#e0e0e0",
              }}
            >
              <Grid item>
                <Typography fontSize={15}>Jumlah:</Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight="bolder" fontSize={20}>
                  {/* {localeCurrency(totalPayment ? totalPayment - 0 : 0)} */}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* Second Grid item */}
          <Paper
            style={{
              boxShadow: "none",
              backgroundColor: "#F8F8F8",
              padding: "20px",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              marginBottom="10px"
            >
              Shipping Address
            </Typography>
            <Grid container>{/* Content for the second column */}</Grid>
          </Paper>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{ marginTop: "25px", backgroundColor: "#15552F" }}
        onClick={handleButtonClick}
      >
        Terima Pesanan
      </Button>
    </div>
  );
};

export default OrderInfo;
