import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import ErrorText from "../../components/ErrorText/Index";
import { auth } from "../../config/Firebase";
import logging from "../../config/Logging";
import IPageProps from "../../interfaces/Page";
// import firebase from "firebase";
import firebase from "firebase/app";
import "firebase/auth";

// import Avatar from "@material-ui/core/Avatar";
import Button from "@mui/material/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@mui/material/Grid";

// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// import type { RootState } from "../../store/index";

// import SaveIcon from "@mui/icons-material/Save";

// import Container from "@material-ui/core/Container";
// import PediLoginImg from "../../Asset/Images/PEDi.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <a
        href="https://www.akatselangor.com.my/"
        target="_blank"
        rel="noreferrer"
      >
        Lembaga Zakat Selangor
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "120px",
    height: "100px",
    borderRadius: "unset",
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  adlogin: {
    margin: theme.spacing(1, 0, 0),
    backgroundColor: "#EF4F91",
  },
  googlesignin: {
    margin: theme.spacing(1, 0, 2),
    // backgroundColor: "#e5e5e5",
    // borderRadius: "15px",
    color: "#ffffff",
    textTransform: "unset",
    lineHeight: "unset",
  },
}));

// export default function SignIn() {
const LoginForm: React.FunctionComponent<IPageProps> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tenant, callback } = props;
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  // const { tenant } = useSelector((state: RootState) => state.userState);

  const signInWithEmailAndPassword = (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (typeof email !== "undefined" && password !== "") {
      if (error !== "") setError("");

      setAuthenticating(true);

      auth
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          // logging.info(result);
          logging.error("Authentication Success!");

          if (typeof callback !== "undefined") {
            callback("ok");
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          // logging.error(error);
          logging.error(email + "Authentication fail!");
          setAuthenticating(false);
          setError(error.message);
        });
    } else if (typeof email !== "undefined" && password === "") {
      if (auth.isSignInWithEmailLink(window.location.href)) {
        auth
          .signInWithEmailLink(email, window.location.href)
          .then((result) => {
            logging.info(result);
            if (typeof callback !== "undefined") {
              callback("ok");
            } else {
              navigate("/");
            }
          })
          .catch((error) => {
            logging.error(error);
            setAuthenticating(false);
            setError(error.message);
          });
      } else {
      }
    }
  };

  const signInWithProvider = (provider: any) => {
    setAuthenticating(true);
    // console.log(auth);
    if (typeof auth.signInWithPopup !== "undefined") {
      auth
        .signInWithPopup(provider)
        .then((result) => {
          logging.info(result);
          if (typeof callback !== "undefined") {
            callback("ok");
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          logging.error(error);
          setAuthenticating(false);
          setError(error.message);
        });
    }
  };

  // const adLogin = () => {
  //   let url = "";
  //   // console.log(url);
  //   if (/(localhost|127.0.0.1|172.16.1.62)/i.test(window.location.host)) {
  //     url = "https://pik.tuxgeo.dev";
  //   }
  //   // console.log(url);
  //   window.open(
  //     url + "/login/adfs/signin",
  //     "geocommlogin",
  //     "height=600,width=500"
  //   );
  // };

  const handleMessage = (e: any) => {
    if (e?.data?.token) {
      auth
        .signInWithCustomToken(e.data.token)
        .then((result) => {
          logging.info(result);
          navigate("/");
        })
        .catch((error) => {
          logging.error(error);
          setAuthenticating(false);
          setError(error.message);
        });
    }
  };

  window.addEventListener("message", handleMessage);

  return (
    <form
      className={classes.form}
      noValidate
      onSubmit={(e: any) => signInWithEmailAndPassword(e)}
    >
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={3} md={3} xl={2} style={{ textAlign: "center" }}>
          <img
            src={`${tenant?.logo ? tenant?.logo : "/images/logo.png?v=1"}`}
            alt="i3"
            style={{ height: "100%", maxHeight: "100px" }}
          />
        </Grid>
        <Grid item xs={12} sm={9} md={9} xl={10}>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{
                  color: "#235E9E",
                  // fontSize: "40px",
                  font: "normal normal 600 30px/50px Poppins",
                  letterSpacing: "0px",
                }}
              >
                Selamat Datang!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                style={{
                  // fontSize: "40px",
                  // font: "normal normal medium 18px/23px Poppins",
                  letterSpacing: "0px",
                }}
              >
                Log masuk dengan data yang anda masukkan semasa pendaftaran
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <ErrorText error={error} />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          <Button
            disabled={authenticating}
            type="button"
            fullWidth
            // variant="outlined"
            size="large"
            // color="secondary"
            onClick={() =>
              signInWithProvider(new firebase.auth.GoogleAuthProvider())
            }
            className={classes.googlesignin}
            style={{
              backgroundColor: tenant?.theme_color
                ? tenant?.theme_color
                : "#e5e5e5",
              color: tenant?.theme_font_color
                ? tenant?.theme_font_color
                : "#ffffff",
            }}
            startIcon={
              <Icon>
                <img
                  src="/images/google.png"
                  style={{ height: "100%", zIndex: "999" }}
                  alt="google"
                />
              </Icon>
            }
          >
            Sign In With Google
          </Button>
        </Grid>
        {/* <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            disabled={authenticating}
            type="button"
            size="large"
            fullWidth
            // variant="outlined"
            // color="secondary"
            onClick={() =>
              signInWithProvider(new firebase.auth.OAuthProvider("yahoo.com"))
            }
            className={classes.googlesignin}
            style={{
              backgroundColor: tenant?.theme_color
                ? tenant?.theme_color
                : "#e5e5e5",
              color: tenant?.theme_font_color
                ? tenant?.theme_font_color
                : "#ffffff",
            }}
            startIcon={
              <Icon>
                <img
                  src="/images/yahoo.png"
                  style={{ height: "100%", zIndex: "999" }}
                  alt="yahoo"
                />
              </Icon>
            }
          >
            Sign In With Yahoo
          </Button>
        </Grid> */}

        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            // paddingBottom: "10px",
            paddingTop: "20px",
          }}
        >
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={5} style={{ textAlign: "center" }}>
              <hr />
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              OR
            </Grid>
            <Grid item xs={5} style={{ textAlign: "center" }}>
              <hr />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Alamat e-mail"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => setEmail(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Kata Laluan"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => setPassword(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Link
            to="/forget"
            variant="body2"
            style={{
              color: "#0450AA",
              textDecoration: "none",
            }}
          >
            Terlupa Katalaluan?
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
          <Link
            to="/register"
            variant="body2"
            style={{
              color: "#0450AA",
              textDecoration: "none",
            }}
          >
            {"Log masuk kali pertama? aktifkan disini"}
          </Link>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            disabled={authenticating}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className={classes.submit}
            style={{
              backgroundColor: tenant?.theme_color
                ? tenant?.theme_color
                : "#e5e5e5",
              // borderRadius: "15px",
              color: tenant?.theme_font_color
                ? tenant?.theme_font_color
                : "#ffffff",
              textTransform: "unset",
            }}
          >
            Daftar Masuk
          </Button>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <div style={{ padding: "25px" }}>
            <Copyright />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
