import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { FormGroup, Spinner } from "reactstrap";
// import AuthContainer from "../../components/AuthContainer/Index";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";

import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import ErrorText from "../../components/ErrorText/Index";
import { auth } from "../../config/Firebase";
import logging from "../../config/Logging";
import IPageProps from "../../interfaces/Page";
import queryString from "querystring";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://www.akatselangor.com.my/">
        Lembaga Zakat Selangor
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const ResetPasswordPage: React.FunctionComponent<IPageProps> = (props) => {
  const classes = useStyles();
  const [verifying, setVerifying] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);
  const [changing, setChanging] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [oobCode, setOobCode] = useState<string>("");
  const [error, setError] = useState<string>("");

  // const navigate = useNavigate();

  useEffect(() => {
    logging.info("Extracting code");
    let stringParams: any = {};
    if (props?.location?.search) {
      stringParams = queryString.parse(props.location.search);
    } else {
      stringParams = queryString.parse(window.location.search);
    }

    // let stringParams = queryString.parse(props.location.search);

    if (stringParams) {
      let oobCode = stringParams.oobCode as string;

      if (oobCode) {
        logging.info("Code found");
        verifyPasswordResetLink(oobCode);
      } else {
        logging.error("Unable to find code");
        setVerified(false);
        setVerifying(false);
      }
    } else {
      logging.error("Unable to find code");
      setVerified(false);
      setVerifying(false);
    }
    // eslint-disable-next-line
  }, []);

  const verifyPasswordResetLink = (_oobCode: string) => {
    auth
      .verifyPasswordResetCode(_oobCode)
      .then((result) => {
        logging.info(result);
        setOobCode(_oobCode);
        setVerified(true);
        setVerifying(false);
        setError("");
      })
      .catch((error) => {
        setError(error.message);
        logging.error(error);
        setVerified(false);
        setVerifying(false);
      });
  };

  const passwordResetRequest = () => {
    if (password !== confirm) {
      setError("Make sure your passwords are matching");
      return;
    }

    if (error !== "") setError("");

    setChanging(true);

    auth
      .confirmPasswordReset(oobCode, password)
      .then(() => {
        // navigate("/login");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        logging.error(error);

        setError(error.message);
        setChanging(false);
      });
  };

  // console.log(error);
  return (
    // <AuthContainer header="Reset Password">
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          src="/images/logo.png?v=1"
          alt="i3"
          height="100"
          className="mcmclogo"
        />
        {verifying ? (
          <Spinner color="info" />
        ) : (
          <>
            {verified ? (
              <>
                <p>Please enter a strong password.</p>
                <FormGroup>
                  <TextField
                    size="small"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="new-password"
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter Password"
                    onChange={(event) => setPassword(event.target.value)}
                    value={password}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    size="small"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="new-password"
                    type="password"
                    name="confirm"
                    id="confirm"
                    placeholder="Confirm Password"
                    onChange={(event) => setConfirm(event.target.value)}
                    value={confirm}
                  />
                </FormGroup>
                <p style={{ color: "red" }}>
                  <ErrorText error={error} />
                </p>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={changing}
                  // color="success"
                  className={classes.submit}
                  onClick={() => passwordResetRequest()}
                >
                  Reset Password
                </Button>
              </>
            ) : (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <span style={{ color: "red" }}>Error!!</span>
                <p className="text-center">
                  {error !== "" ? error : "Invalid link/code."}
                </p>
                <p className="text-center">
                  <Link color="inherit" to="/login">
                    Login
                  </Link>
                </p>
              </Grid>
            )}
          </>
        )}
      </div>
      <div style={{ padding: "25px" }}>
        <Copyright />
      </div>
    </Container>
  );
};

export default ResetPasswordPage;
