import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import type { RootState } from "../../store/index";
import SideBar from "./SideBar";
import Default from "../../components/Dashboard";

import AgencyManager from "../../components/Agency";
import AgencyForm from "../../components/Agency/Form";
import AgencyInfo from "../../components/Agency/InfoDetail";
import AdvertisementManager from "../../components/Advertisement";
import AdvertisementForm from "../../components/Advertisement/Form";

// import UserList from "../../components/CfgUser/List";
import UserList from "../../components/CfgUser/ListNew";
import UserForm from "../../components/CfgUser/Form";
import AclForm from "../../components/CfgUser/Acl";
// import ModulePage from "../../pages/Module";

import DashboardRefresh from "../../components/Dashboard/Refresh";
import ProductCategoryManager from "../../components/ProductCategory";
import ProductCategoryForm from "../../components/ProductCategory/Form";
import ProductManager from "../../components/Product";
import ProductForm from "../../components/Product/Form";
import ProductInfo from "../../components/Product/Info";
import OrderManager from "../../components/Orders";
import OrderInfo from "../../components/Orders/Info";
import SalesIndex from "../../components/Sales";
import SalesSummary from "../../components/Sales/Info";
import ContributionIndex from "../../components/Contribution";
import ContributionSummary from "../../components/Contribution/Info";
import { AuthDisplay } from "../../config/Acl";
// import { auth } from "../../config/Firebase";

import "./index.css";

const DashboardIndex = () => {
  // const [token, setToken] = React.useState<any>("");
  // const [user, setUser] = React.useState<any>();
  const { profile } = useSelector((state: RootState) => state.userState);
  // const [open, setOpen] = React.useState(true);

  // React.useEffect(() => {
  //   auth.onAuthStateChanged((user: any) => {
  //     if (user) {
  //       if (typeof user !== "undefined") {
  //         user.getIdToken().then((e: any) => {
  //           // setToken(e);
  //           // setUser(user);
  //         });
  //       }
  //     } else {
  //       console.log("No user detected");
  //     }
  //   });
  // }, []);

  if (
    typeof profile.position === "undefined" ||
    (typeof profile.position !== "undefined" && profile.position === "USER")
  ) {
    return <AuthDisplay />;
  } else {
    return (
      <div
        style={{
          display: "flex",
          // flexDirection: "column",
          width: "100%",
          // border: "1px solid #00ff00",
          height: "calc(100vh - 70px)",
        }}
      >
        <div
          style={{
            // flex: 1,
            width: "300px",
            height: "calc(100vh - 70px)",
            overflow: "auto",
            backgroundColor: "#f5f5f6",
            // border: "1px solid #ff0000",
            // color: "#ffffff",
          }}
        >
          <SideBar />
        </div>

        <div
          style={{
            // height: "calc(100vh - 70px)",
            // minHeight: "calc(100vh - 70px)",
            // border: "1px solid #ff0000",
            flexGrow: 1,
            // flex: 1,
            minWidth: 0,
            padding: "10px",
            width: "calc(100vw - 300px)",
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
          }}
        >
          <Routes>
            <Route path="" element={<Default />}></Route>
            <Route path="category" element={<ProductCategoryManager />} />
            <Route path="category/:id" element={<ProductCategoryForm />} />
            <Route path="product" element={<ProductManager />} />
            <Route path="product/:id" element={<ProductInfo />} />
            <Route path="product/category/:id" element={<ProductManager />} />
            <Route path="product/form/:id" element={<ProductForm />} />
            <Route path="agency" element={<AgencyManager />} />
            <Route path="agency/:id" element={<AgencyForm />} />
            <Route path="agency/info/:id" element={<AgencyInfo />} />
            <Route path="user/list" element={<UserList />} />
            <Route path="user/form/:id" element={<UserForm />} />
            <Route path="user/acl" element={<AclForm />} />
            <Route path="orders" element={<OrderManager />} />
            <Route path="orders/info/:id" element={<OrderInfo />} />
            <Route path="refresh" element={<DashboardRefresh />} />
            <Route path="sales" element={<SalesIndex />} />
            <Route path="sales/info/:id" element={<SalesSummary />} />
            <Route path="agency/profile/update" element={<AgencyForm />} />
            <Route path="advertisement" element={<AdvertisementManager />} />
            <Route path="advertisement/:id" element={<AdvertisementForm />} />
            <Route path="contribution" element={<ContributionIndex />} />
            <Route
              path="contribution/info/:id"
              element={<ContributionSummary />}
            />
          </Routes>

          <Outlet />
        </div>
      </div>
    );
  }
};

export default DashboardIndex;
