import * as React from "react";
import { auth } from "../config/Firebase";

interface PropsTotal {
  id?: string;
}

const Test: React.FC<PropsTotal> = ({ id }) => {
  const [token, setToken] = React.useState<any>();
  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setToken(token);
        });
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      // fetch("/api/dashboard/pediuser", {
      fetch("/api/test/firebase", {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            console.log("test: ", data);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (typeof token !== "undefined" && token !== "") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token]);

  return <>my Test</>;
};

export default Test;
