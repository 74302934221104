import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import { auth } from "../../config/Firebase";
import type { RootState } from "../../store/index";
import LoginForm from "../../pages/auth/LoginForm";
import { setCartStatus } from "../../store/form";

import "./UnitButton.css";

const GroupedButtons = ({ callback, product }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [counter, setCounter] = React.useState<number>(0);
  const [amount, setAmount] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [querystatus, setQuerystatus] = React.useState<string>("");
  const [token, setToken] = React.useState<any>("");
  // const [user, setUser] = React.useState<any>();
  const [init, setInit] = React.useState<boolean>(false);
  const { profile, tenant } = useSelector(
    (state: RootState) => state.userState
  );
  const [open, setOpen] = React.useState(false);
  // const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

  // const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    if (typeof product !== "undefined") {
      setInit(false);
    }
  }, [product]);

  React.useEffect(() => {
    if (
      typeof product?.cart_quantity !== "undefined" &&
      product?.cart_quantity > 0 &&
      init === false
    ) {
      setCounter(product?.cart_quantity - 0);
      setInit(true);
    }
  }, [product?.cart_quantity, init]);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
            // setUser(user);
          });
        }
      }
    });
  }, []);

  const handleCounter = (type: string) => {
    if (profile?.id) {
      if (
        typeof profile?.zakat.year === "undefined" ||
        (typeof profile?.zakat.year !== "undefined" &&
          profile?.zakat.year === "")
      ) {
        console.log(profile?.zakat.year);
        window.alert(
          "Maklumat zakat anda untuk tahun ini tiada didalam rekod kami. Sila isi borang zakat untuk tahun ini. dan buat pembayaran zakat anda sebelum meneruskan kem pen yang dipilih."
        );
        navigate("/store/zakat/form/pendapatan");
      } else {
        let newcounter: number = counter;
        if (type === "+") {
          newcounter++;
        } else {
          newcounter--;
        }
        if (product?.quantity < newcounter) {
          window.alert("Out of stock");
          return false;
        }

        setCounter(newcounter);
        addToCart(newcounter);
      }
    } else {
      setOpen(true);
      // console.log("login first");
    }
  };

  // const addProductToCart = (x: any) => {
  //   // if (typeof callback !== "undefined" && init === true) {
  //   if (typeof product !== "undefined") {
  //     if (profile?.id) {
  //       console.log("add to cart");
  //     } else {
  //       console.log("login first");
  //     }
  //   } else if (typeof callback !== "undefined") {
  //     // if (init === true || (init === false && counter > 0)) {
  //     //   setInit(true);
  //     callback(counter);
  //     // }
  //   }
  // };

  const addToCart = (quantity: any) => {
    // fetch("/api/apps/cart/amount", {
    //   headers: {
    //     Authorization: "Bearer " + token,
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log("test: ", data);
    //   })
    //   .catch(function (err) {
    //     console.log(err, "error");
    //   });

    // console.log("asss", profile, product?.id.toString());
    // return false;
    setLoading(true);
    const data = new URLSearchParams();
    data.append("product_id", product?.id.toString());
    data.append("quantity", (quantity ? quantity : 0).toString());
    data.append("amount", (amount ? amount : 0).toString());
    // data.append("quantity", "1");

    // console.log(product, counter.toString());

    if (product.id === "" && quantity >= 0) {
      window.alert("Missing required fields.");

      setLoading(false);
    } else {
      setQuerystatus("");
      fetch("/api/apps/cart/create", {
        method: "post",
        body: data,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          setLoading(false);
          if (data?.metadata?.status === "OK") {
            dispatch(setCartStatus(new Date().getTime().toString()));
            setCounter(data?.cart?.quantity ? data?.cart?.quantity : 0);
            if (typeof callback !== "undefined") {
              callback(
                data?.cart?.quantity ? data?.cart?.quantity : 0,
                data?.cart?.id
              );
            }
          } else {
            setQuerystatus("ERROR");
            setLoading(false);
          }
        })
        .catch((e: any) => {
          setQuerystatus("ERROR");
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (querystatus === "ERROR") {
      if (counter > 0) {
        setCounter(counter - 1);
      }
    }
  }, [querystatus, counter]);

  const displayCounter = counter ? counter > 0 : false;

  // console.log(product);
  return (
    <>
      {product?.quantity <= 0 ? (
        // <Button size="small">Out Of Stock</Button>
        <div className="outofstock">
          <Typography variant="body2">Telah Ditutup</Typography>
        </div>
      ) : (
        <>
          {product?.quantity < counter ? (
            <div className="outofstock">
              <Typography variant="body2">
                {/* Stok Habis ({counter}/{product?.quantity}) */}
                Telah Mencapai Sasaran
              </Typography>
            </div>
          ) : (
            // <div>
            //   {/* <Typography variant="body2">
            //     Lihat Bakul
            //   </Typography> */}
            //   <Button fullWidth variant="contained">
            //     RM 1500 Lihat Bakul
            //   </Button>
            // </div>
            <ButtonGroup
              className="unitbutton"
              size="small"
              variant="contained"
              color="primary"
              fullWidth
              style={{ fontSize: "70% !important" }}
              disabled={loading}
            >
              {displayCounter ? (
                <Button
                  onClick={() => {
                    // store/cart
                    navigate("/store/cart");
                  }}
                >
                  Lihat Bakul
                </Button>
              ) : (
                <Button onClick={() => handleCounter("+")}>
                  {displayCounter ? "+" : "Tambah ke Bakul Zakat"}
                </Button>
              )}

              {/* <Button onClick={() => handleCounter("+")}>
                {displayCounter ? "+" : "Tambah ke Bakul Zakat"}
              </Button>
              {displayCounter && <Button>{counter}</Button>}
              {displayCounter && (
                <Button onClick={() => handleCounter("-")}>-</Button>
              )} */}
            </ButtonGroup>
          )}
        </>
      )}
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        // scroll={scroll}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Log Masuk</DialogTitle>
        <DialogContent dividers={true}>
          <LoginForm
            tenant={tenant}
            callback={(type: any) => {
              setCounter(counter >= 0 ? counter + 1 : 1);
              setOpen(false);
              // console.log("here");
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>TUTUP</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GroupedButtons;
