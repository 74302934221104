import React from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { auth } from "../../config/Firebase";

import Back from "../Back";
import Form from "../Forms/ProductForm";
import UploadForm from "../Forms/Upload";
import Breadcrumbs from "../Breadcrumbs";
// import form from "../../store/form";

import "./Product.css";

interface DialogProps {
  user?: any;
}

const FormPanel = (props: DialogProps) => {
  const { id } = useParams();

  const [activephoto, setActivePhoto] = React.useState<string>(
    "/images/default.png"
  );
  const [formdata, setFormData] = React.useState<any>();
  const [token, setToken] = React.useState<any>("");

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      // setLoading(true);
      fetch("/api/product/find/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setFormData(data.product);
            if (data.product?.photos.length > 0) {
              setActivePhoto(data.product?.photos[0]);
            }
            // setLoading(false);
          }
        });
    };

    if (
      typeof id !== "undefined" &&
      id !== "" &&
      id !== "new" &&
      typeof formdata === "undefined" &&
      token !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, formdata, id]);

  return (
    <>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <Back />
        <Typography variant="subtitle1" component="h6">
          Borang Kempen
        </Typography>
      </div>
      <Breadcrumbs
        current={"Borang Kempen"}
        paths={[
          { name: "Pengurusan", url: "/administration" },
          {
            name: "Kempen",
            url: "/dashboard/product",
          },
        ]}
        className="margintop"
      />
      <Paper elevation={0} className={"papercontainer margintop"}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={7} lg={8}>
            <Form
              id={formdata?.id}
              data={formdata}
              // callback={dataList}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            {formdata?.photos && formdata?.photos.length > 0 && (
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} sm={12}>
                  <div
                    style={{ backgroundImage: `${"url(" + activephoto + ")"}` }}
                    className="productthumbnail"
                  >
                    &nbsp;
                  </div>
                </Grid>
                {formdata?.photos.map((photo: any, idx: number) => (
                  <Grid
                    item
                    // xs={12}
                    sm={3}
                    md={formdata?.photos.length <= 1 ? 4 : 3}
                    key={idx}
                  >
                    <div
                      style={{ backgroundImage: `${"url(" + photo + ")"}` }}
                      className="productthumbnail"
                      onClick={() => setActivePhoto(photo)}
                    >
                      &nbsp;
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}
            {token && formdata?.id && (
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "10px" }}
              >
                <Grid item xs={12} sm={12}>
                  <Paper
                    style={{
                      width: "100%",
                      height: "10vh",
                      minHeight: "200px",
                      border: "1px dashed #000000",
                      textAlign: "center",
                      verticalAlign: "middle",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <UploadForm
                      // id={formdata?.id}
                      callback={(status?: string, file?: string) => {
                        if (status === "COMPLETED" && file) {
                          let newphotos: any[] = JSON.parse(
                            JSON.stringify(formdata?.photos)
                          );
                          newphotos.push(file);
                          setFormData({
                            ...formdata,
                            photos: newphotos,
                          });
                          setActivePhoto(file);
                        }
                      }}
                      id={formdata?.id}
                      type={"product"}
                      token={token}
                      folder={true}
                      name={"gallery"} //for multiple uploads
                      maxsize={2} //mb
                    />
                  </Paper>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default FormPanel;
