import React, { Fragment } from "react";

import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import FormLabel from "@mui/material/FormLabel";
import MuiAlert from "@material-ui/lab/Alert";

import CircularProgress from "@mui/material/CircularProgress";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

import AgencySelect from "./Libs/Agency";
import ProductCategory from "./Libs/ProductCategory";
import { auth } from "../../config/Firebase";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Form {
  state?: string;
  district?: string;
  refid?: string;
  [key: string]: number | string | object | any;
}

interface PropsTotal {
  id?: string;
  data?: any;
  callback?: (data: any) => void;
}

const ProductForm: React.FC<PropsTotal> = ({ id, data, callback }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [querystatus, setQuerystatus] = React.useState<string>("");
  const [token, setToken] = React.useState<any>("");
  const [form, setForm] = React.useState<Form>({
    state: "",
    district: "",
    refid: "",
    CheckedTick: true,
  });

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof data !== "undefined") {
      if (typeof data !== "undefined") {
        setForm(data);
      }
    }
  }, [data]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event?.target?.id ? event?.target?.id : event?.target?.name]:
        event.target.value,
    });
  };

  const saveForm = () => {
    setLoading(true);
    const data = new URLSearchParams();
    console.log(form);
    for (let f in form) {
      if (form[f] !== "" && form[f] !== null) {
        data.append(f, form[f]);
      }
    }

    if (form.name === "" || form.description === "" || form.code === "") {
      window.alert("Missing required fields.");

      setLoading(false);
      return false;
    }

    let catapi: string = "";
    if (
      typeof form?.id !== "undefined" &&
      form?.id !== "new" &&
      form?.id !== "undefined"
    ) {
      catapi = "/api/product/update/" + form?.id;
    } else {
      catapi = "/api/product/create";
    }

    fetch(catapi, {
      method: "post",
      body: data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);

        setQuerystatus(data.metadata.status);
        if (data?.metadata?.status === "ERROR") {
        } else {
          if (data?.product) {
            // console.log(data?.product, form);
            let newform: any = { ...form };
            for (let n in form) {
              if (typeof data.product[n] !== "undefined") {
                newform[n] = data.product[n];
              }
            }
            if (typeof newform.id === "undefined") {
              newform.id = data.product.id;
            }
            if (
              typeof data?.product?.photourl !== "undefined" &&
              data?.product?.photourl !== ""
            ) {
              newform.photourl = data?.product?.photourl;
            }
            setForm(newform);
          }
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setQuerystatus("ERROR");
        setLoading(false);
      });
  };

  const gotoList = () => {
    if (callback) {
      callback(form);
    } else {
      navigate(`/dashboard/product/form/`);
    }
  };

  const setValue = (id?: any, value?: any) => {
    console.log(id, value);
    setForm({ ...form, [id]: value });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.id]: event.target.checked ? 1 : 0 });
  };

  // console.log(data, form);
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {querystatus !== "" && (
        <Grid item xs={12} style={{ paddingBottom: "20px" }}>
          <Fragment>
            {querystatus === "NEW_DUPLICATE" && (
              <Alert severity="error">
                Query failed - Agency already registered.
              </Alert>
            )}
            {querystatus === "UPDATED" && (
              <Alert severity="success">Agency has been updated.</Alert>
            )}
            {querystatus === "SAVED" && (
              <Alert severity="success">Agency has been saved.</Alert>
            )}
            {querystatus === "ERROR" && (
              <Alert severity="error">Query failed - Unknown Reason.</Alert>
            )}
          </Fragment>
        </Grid>
      )}
      {/* <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            size="small"
            name="sku"
            id="sku"
            label="SKU"
            value={form?.sku ? form?.sku : ""}
            onChange={handleInputChange}
            variant="outlined"
          />
        </FormControl>
      </Grid> */}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            size="small"
            name="name"
            id="name"
            label="Nama Kempen"
            value={form?.name ? form?.name : ""}
            onChange={handleInputChange}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            size="small"
            name="description"
            id="description"
            label="Keterangan"
            value={form?.description ? form?.description : ""}
            onChange={handleInputChange}
            variant="outlined"
            style={{ width: "100%" }}
            multiline
            minRows={5}
          />
        </FormControl>
      </Grid>

      {/* {token && (
        <Grid item xs={12} md={6}>
          <FormControl size="small" fullWidth>
            <AgencySelect
              id={"agency_id"}
              label="Agency/Vendor"
              token={token}
              setValue={setValue}
              defaultValue={form?.agency_id ? form?.agency_id : ""}
            />
          </FormControl>
        </Grid>
      )} */}
      {token && (
        <Grid item xs={12} md={6}>
          <FormControl size="small" fullWidth>
            <ProductCategory
              id={"categories_id"}
              label="Produk Kategori"
              token={token}
              setValue={setValue}
              defaultValue={form?.categories_id ? form?.categories_id : ""}
            />
          </FormControl>
        </Grid>
      )}
      {/* <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            size="small"
            name="size"
            id="size"
            label="Size"
            value={form?.size ? form?.size : ""}
            onChange={handleInputChange}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            size="small"
            name="weight"
            id="weight"
            label="Berat"
            value={form?.weight ? form?.weight : ""}
            onChange={handleInputChange}
            variant="outlined"
          />
        </FormControl>
      </Grid> */}
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            size="small"
            name="price"
            id="price"
            label="Sasaran Sumbangan"
            value={form?.price ? form?.price : ""}
            onChange={handleInputChange}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            size="small"
            name="sale_price"
            id="sale_price"
            label="Harga Jualan"
            value={form?.sale_price ? form?.sale_price : ""}
            onChange={handleInputChange}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            size="small"
            name="taxable"
            id="taxable"
            label="Cukai Jualan"
            value={form?.taxable ? form?.taxable : 0}
            onChange={handleInputChange}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            size="small"
            name="status"
            id="status"
            label="Status Produk"
            value={form?.status ? form?.status : ""}
            onChange={handleInputChange}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            size="small"
            name="quantity"
            id="quantity"
            label="Stok Produk"
            value={form?.quantity ? form?.quantity : 0}
            onChange={handleInputChange}
            variant="outlined"
          />
        </FormControl>
      </Grid> */}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                id="featured"
                checked={form?.featured ? true : false}
                onChange={handleChange}
              />
            }
            label="Featured Product?"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <label className="label">
          <i>
            Note: Fields marked with asterik (<i style={{ color: "red" }}>*</i>{" "}
            ) are compulsory fields.
          </i>
        </label>
      </Grid>
      <Grid item xs={12} sm={5} md={3}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => saveForm()}
          startIcon={<SaveIcon />}
          fullWidth
          disabled={loading ? true : false}
        >
          Save
        </Button>
      </Grid>
      {loading ? (
        <>
          <Grid item xs={12} sm={1} md={1} style={{ textAlign: "center" }}>
            <CircularProgress size={20} />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            {form?.id ? "Updating..." : "Saving..."}
          </Grid>
        </>
      ) : (
        <Fragment>
          <Grid item xs={12} sm={2} md={6}></Grid>
          <Grid item xs={12} sm={5} md={3}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => gotoList()}
              startIcon={<ListAltIcon />}
              fullWidth
              disabled={loading ? true : false}
            >
              Cancel
            </Button>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default ProductForm;
