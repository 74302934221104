import React, { useState } from "react";
import { Link } from "react-router-dom";
import ErrorText from "../../components/ErrorText/Index";
import { auth } from "../../config/Firebase";
import logging from "../../config/Logging";
import IPageProps from "../../interfaces/Page";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://www.akatselangor.com.my/">
        Lembaga Zakat Selangor
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "100px",
    height: "70px",
    borderRadius: "unset",
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  googlesignin: {
    margin: theme.spacing(1, 0, 2),
  },
}));

// export default function SignIn() {
const RegisterPage: React.FunctionComponent<IPageProps> = (props) => {
  const classes = useStyles();
  const [registering, setRegistering] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [confirm] = useState<string>("");
  const [password] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [statusregister, setStatusRegister] = useState<boolean>(false);

  const signUpWithEmailAndPassword = (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (password !== confirm) {
      setError("Please make sure your passwords match.");
      return;
    }

    if (error !== "") setError("");

    setRegistering(true);
    auth
      .sendSignInLinkToEmail(email, {
        url:
          window.location.protocol +
          "//" +
          window.location.host +
          "/register/confirmation?email=" +
          email,
        handleCodeInApp: true,
      })
      .then((result: any) => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        // ...
        console.log(result);
        setStatusRegister(true);
        //   navigate("/login");
      })
      .catch((error) => {
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // ...
        logging.error(error);

        if (error.code.includes("auth/email-already-in-use")) {
          setError("Email already in use.");
        } else if (error.code.includes("auth/invalid-email")) {
          setError("Invalid e-mail address.");
        } else {
          setError(error.message);
        }

        // setStatusRegister(true);
        setRegistering(false);
        //
      });
    // .createUserWithEmailAndPassword(email, password)
    // .then((result) => {
    //   logging.info(result);
    //   navigate("/login");
    // })
    // .catch((error) => {
    //   logging.error(error);

    //   if (error.code.includes("auth/weak-password")) {
    //     setError("Please enter a stronger password.");
    //   } else if (error.code.includes("auth/email-already-in-use")) {
    //     setError("Email already in use.");
    //   } else {
    //     setError("Unable to register.  Please try again later.");
    //   }

    //   setRegistering(false);
    // });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src="/images/logo.png?v=1">
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        {/* <ErrorText error={error} /> */}
        <form
          className={classes.form}
          noValidate
          onSubmit={(e: any) => signUpWithEmailAndPassword(e)}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => setEmail(event.target.value)}
            disabled={registering}
          />
          {statusregister ? (
            <div style={{ textAlign: "center" }}>
              {error === "" ? (
                <Typography component="p" variant="caption">
                  The Confirmation link was successfully sent to your email (
                  {email})
                </Typography>
              ) : (
                <>{error}</>
              )}
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              {error && <ErrorText error={error} />}
              <Button
                disabled={registering}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign Up
              </Button>
              <Link to="/login" variant="body2">
                Already have an account?
              </Link>
            </div>
          )}
        </form>
      </div>
      <div style={{ padding: "25px" }}>
        <Copyright />
      </div>
    </Container>
  );
};

export default RegisterPage;
