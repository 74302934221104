import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

// import Checkbox from "@mui/material/Checkbox";

import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// import { useSelector } from "react-redux";

import { auth } from "../../config/Firebase";
// import type { RootState } from "../../store/index";
import UnitButton from "./UnitButton";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { localeCurrency } from "./Store";

interface DrawerType {
  callback?: (status?: boolean) => void;
}

const ShoppingCart = ({ callback }: DrawerType) => {
  const navigate = useNavigate();
  const [loading] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<any>();
  const [carts, setCarts] = React.useState<any[]>();
  const [total, setTotal] = React.useState<number>(0);
  // const { profile, tenant } = useSelector(
  //   (state: RootState) => state.userState
  // );
  // const { cartstatus } = useSelector((state: RootState) => state.formState);

  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setToken(token);
        });
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/apps/cart/list", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            console.log("test: ", data);
            setCarts(data?.list);

            let ctotal: number = 0;
            for (let c = 0; c < data?.list.length; c++) {
              ctotal = ctotal + data?.list[c].quantity_buy;
            }
            setTotal(ctotal);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof carts === "undefined" &&
      typeof token !== "undefined" &&
      token !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, carts]);

  const updateCart = (idx: any, value: any) => {
    let newcarts = JSON.parse(JSON.stringify(carts));
    newcarts[idx].quantity_buy = value;
    setCarts(newcarts);
  };

  const removeFromCart = (idx: any) => {
    let newcarts = JSON.parse(JSON.stringify(carts));
    newcarts.splice(idx, 1);
    setCarts(newcarts);
  };

  return (
    <Box
      sx={{ width: "30vw", maxWidth: "400px", padding: "20px" }}
      role="presentation"
      //   onClick={callback(false)}
      //   onKeyDown={callback(false)}
    >
      <div style={{ display: "flex", margin: "15px" }}>
        <div style={{ paddingRight: "5px" }}>
          <ShoppingCartIcon />
        </div>
        <div>
          <Typography
            variant="subtitle1"
            style={{ paddingLeft: "20px", fontWeight: "bold" }}
          >
            {total} Barang
            {/* {total > 1 ? "s" : ""} */}
          </Typography>
        </div>
        <div style={{ textAlign: "right", flexGrow: 1 }}>
          {loading && <CircularProgress size={30} />}

          <IconButton
            onClick={() => {
              if (typeof callback !== "undefined") {
                callback(false);
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {carts &&
          carts.map((cart: any, idx: any) => (
            <React.Fragment key={idx}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={2} md={3} lg={2}>
                <div
                  className="cart_thumbnail"
                  style={{
                    maxWidth: "50px",
                    backgroundImage: `${
                      "url(" +
                      (typeof cart?.photos[0] !== "undefined" &&
                      cart?.photos[0] !== ""
                        ? cart?.photos[0]
                        : "/images/default.png") +
                      ")"
                    }`,
                  }}
                >
                  &nbsp;
                </div>
              </Grid>
              <Grid item xs={12} sm={10} md={9} lg={10}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} lg={7}>
                    <Typography variant="body2">
                      {cart?.product_name}
                    </Typography>
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    lg={4}
                    style={{ textAlign: "right", paddingRight: "5px" }}
                  >
                    <UnitButton
                      product={{
                        cart_quantity: cart?.quantity_buy,
                        id: cart?.product_id,
                        quantity: cart?.product_quantity_remaining
                          ? cart?.product_quantity_remaining
                          : 0,
                      }}
                      callback={(newvalue: any) => {
                        updateCart(idx, newvalue);
                        if (newvalue <= 0) {
                          removeFromCart(idx);
                        }
                      }}
                    />
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    lg={5}
                    style={{ textAlign: "right", paddingRight: "5px" }}
                  >
                    <Typography variant="body2">
                      {/* {localeCurrency(cart?.quantity_buy * cart?.price_each)} */}
                      {localeCurrency(cart?.amount)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          {typeof carts !== "undefined" && carts.length > 0 && !loading && (
            <Button
              variant="outlined"
              startIcon={<ShoppingCartCheckoutIcon />}
              onClick={() => {
                if (typeof callback !== "undefined") {
                  callback(false);
                }
                navigate("/store/cart");
              }}
            >
              Lihat Bakul
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShoppingCart;
