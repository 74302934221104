import React, { Fragment } from "react";

import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import MuiAlert from "@material-ui/lab/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import MenuItem from "@mui/material/MenuItem";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

import { auth } from "../../config/Firebase";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Form {
  state?: string;
  district?: string;
  refid?: string;
  [key: string]: number | string | object | any;
}

interface PropsTotal {
  id?: string;
  data?: any;
  callback?: (data: any) => void;
}

const MenarikForm: React.FC<PropsTotal> = ({ id, data, callback }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [querystatus, setQuerystatus] = React.useState<string>("");
  const [token, setToken] = React.useState<any>("");

  const [form, setForm] = React.useState<Form>({});

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof data !== "undefined") {
      if (typeof data !== "undefined") {
        setForm(data);
      }
    }
  }, [data]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event?.target?.id ? event?.target?.id : event?.target?.name]:
        event.target.value,
    });
  };

  const saveForm = () => {
    setLoading(true);
    const data = new URLSearchParams();
    // console.log(form);
    for (let f in form) {
      if (form[f] !== "" && form[f] !== null) {
        data.append(f, form[f]);
      }
    }

    if (form.name === "" || form.description === "" || form.code === "") {
      window.alert("Missing required fields.");

      setLoading(false);
      return false;
    }

    fetch("/api/advertisement/save", {
      method: "post",
      body: data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);

        setQuerystatus(data.metadata.status);
        if (data?.metadata?.status === "ERROR") {
        } else {
          if (data?.advertisement) {
            // console.log(data?.advertisement, form);
            let newform: any = { ...form };
            for (let n in form) {
              if (typeof data.advertisement[n] !== "undefined") {
                newform[n] = data.advertisement[n];
              }
            }
            if (typeof newform.id === "undefined") {
              newform.id = data.advertisement.id;
            }
            if (
              typeof data?.advertisement?.photourl !== "undefined" &&
              data?.advertisement?.photourl !== ""
            ) {
              newform.photourl = data?.advertisement?.photourl;
            }
            setForm(newform);
            if (typeof callback !== "undefined") {
              callback(form);
            }
          }
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setQuerystatus("ERROR");
        setLoading(false);
      });
  };

  const gotoList = () => {
    if (callback) {
      callback(form);
    } else {
      navigate(`/dashboard/advertisement/`);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "pupuk") {
      setForm({ ...form, [event.target.name]: event.target.checked ? 1 : 0 });
    } else {
      setForm({ ...form, [event.target.name]: event.target.checked });
    }
  };

  // console.log(data, form);
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {querystatus !== "" && (
        <Grid item xs={12} style={{ paddingBottom: "20px" }}>
          <Fragment>
            {querystatus === "NEW_DUPLICATE" && (
              <Alert severity="error">
                Query failed - Advertisement already registered.
              </Alert>
            )}
            {querystatus === "UPDATED" && (
              <Alert severity="success">Advertisement has been updated.</Alert>
            )}
            {querystatus === "SAVED" && (
              <Alert severity="success">Advertisement has been saved.</Alert>
            )}
            {querystatus === "ERROR" && (
              <Alert severity="error">Query failed - Unknown Reason.</Alert>
            )}
          </Fragment>
        </Grid>
      )}

      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>
            Judul<i style={{ color: "red" }}>*</i>
          </FormLabel>
          <br></br>
          <TextField
            size="small"
            name="title"
            id="title"
            label=""
            value={form?.title ? form?.title : ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>
            Isi kandungan<i style={{ color: "red" }}>*</i>
          </FormLabel>
          <br></br>
          <TextField
            size="small"
            name="content"
            id="content"
            label=""
            multiline
            rows={10}
            value={form?.content ? form?.content : ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
          <i>HTML Tag enabled</i>
          <br />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <FormLabel>
            Bermula Dari<i style={{ color: "red" }}>*</i>
          </FormLabel>
          <br></br>
          <TextField
            size="small"
            name="start_at"
            id="start_at"
            label=""
            type={"datetime-local"}
            value={form?.start_at ? form?.start_at : ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <FormLabel>
            Sehingga<i style={{ color: "red" }}>*</i>
          </FormLabel>
          <br></br>
          <TextField
            size="small"
            name="end_at"
            id="end_at"
            label=""
            type={"datetime-local"}
            value={form?.end_at ? form?.end_at : ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <FormLabel>
            Weightage<i style={{ color: "red" }}>*</i>
          </FormLabel>
          <br></br>
          <TextField
            size="small"
            name="weightage"
            id="weightage"
            label=""
            type={"number"}
            value={form?.weightage ? form?.weightage : ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl>
          {/* <FormLabel id="form-is-active">Status Advertisement</FormLabel> */}

          <FormControlLabel
            control={
              <Checkbox
                name="status"
                onChange={handleChange}
                checked={form?.status ? true : false}
              />
            }
            label={`Status Advertisement (${
              form?.is_active ? "ACTIVE" : "INACTIVE"
            })`}
          />
        </FormControl>
      </Grid>
      {/* 
      {form?.photourl && form?.photourl !== "" && (
        <Grid item xs={12} sm={12}>
          <img src={form?.photourl} alt="logo" style={{ width: "100%" }} />
        </Grid>
      )} */}
      <Grid item xs={12}>
        <label className="label">
          <i>
            Note: Fields marked with asterik (<i style={{ color: "red" }}>*</i>{" "}
            ) are compulsory fields.
          </i>
        </label>
      </Grid>
      <Grid item xs={12} sm={5} md={3}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => saveForm()}
          startIcon={<SaveIcon />}
          fullWidth
          disabled={loading ? true : false}
        >
          Save
        </Button>
      </Grid>
      {loading ? (
        <>
          <Grid item xs={12} sm={1} md={1} style={{ textAlign: "center" }}>
            <CircularProgress size={20} />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            {form?.id ? "Updating..." : "Saving..."}
          </Grid>
        </>
      ) : (
        <Fragment>
          <Grid item xs={12} sm={2} md={6}></Grid>
          <Grid item xs={12} sm={5} md={3}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => gotoList()}
              startIcon={<ListAltIcon />}
              fullWidth
              disabled={loading ? true : false}
            >
              Cancel
            </Button>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default MenarikForm;
