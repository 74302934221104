import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";

import Grid from "@material-ui/core/Grid";
import { useParams, useNavigate } from "react-router-dom";
import { auth } from "../../config/Firebase";
import Back from "../Back";
import Breadcrumbs from "../Breadcrumbs";

import Info from "./Info";
import Users from "./Users";
import Products from "./Products";

import "./agency.css";

interface Type {
  [key: string]: number | string | object | any;
}

interface DialogProps {
  index?: any;
  open?: boolean;
  callback?: (data: Type) => void;
  user?: any;
}

const DialogPanel = (props: DialogProps) => {
  const navigate = useNavigate();
  // const { open } = props;
  const { id } = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);

  const [token, setToken] = React.useState<any>("");
  const [data, setData] = React.useState<any>();

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      setLoading(true);

      fetch("/api/agency/detail/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setData(data.agency);
            setLoading(false);
          }
        });
    };

    if (
      typeof data === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof id !== "undefined" &&
      id !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [data, token, id]);

  console.log(data);
  return (
    <>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <Back />
        <Typography variant="subtitle1" component="h6">
          Agency/Vendor Information
        </Typography>
      </div>
      <Breadcrumbs
        current={
          "Agency/Vendor Information - " + (data?.name ? data?.name : data?.id)
        }
        paths={[
          { name: "Pengurusan", url: "/dashboard" },
          { name: "Agency", url: "/dashboard/agency" },
        ]}
        className="margintop"
      />
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {loading ? (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12} style={{ textAlign: "right" }}>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  navigate(`${"/dashboard/agency/" + id}`);
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Info data={data} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <div
                style={{
                  backgroundImage: `${
                    "url(" +
                    (data?.photourl ? data?.photourl : "/images/default.png") +
                    ")"
                  }`,
                }}
                className="agencythumbnail"
              >
                &nbsp;
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="h6">
                    Users
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper style={{ height: "100%" }}>
                    {token && id && <Users id={id} token={token} />}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="h6">
                    Products
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper style={{ height: "100%" }}>
                    {token && id && <Products id={id} token={token} />}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default DialogPanel;
