import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const MyAccount = () => {
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
          Default
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MyAccount;
