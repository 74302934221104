import * as React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import MLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import _ from "lodash";

interface PropsTotal {
  paths?: any[];
  current?: any;
  className?: string;
}

const CustomSeparator: React.FC<PropsTotal> = ({
  paths,
  current,
  className,
}) => {
  const [data, setData] = React.useState<any[]>([]);
  const [breadcrumbs, setBreadcrumbs] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (
      //   data.length <= 0 &&
      typeof paths !== "undefined" ||
      typeof current !== "undefined"
    ) {
      //   setDefaultId(fieldid);
      let newpaths: any[] = [
        {
          name: "Utama",
          url: "/",
        },
      ];
      if (typeof paths !== "undefined" && paths.length > 0) {
        for (let a = 0; a < paths.length; a++) {
          newpaths.push({
            name: paths[a].name,
            url: paths[a].url,
          });
        }
      }
      if (typeof current !== "undefined" && current !== "") {
        newpaths.push({
          name: current,
          url: null,
        });
      }

      if (!_.isEqual(data, newpaths)) {
        setData(newpaths);
      }
    }
  }, [data, paths, current]);

  React.useEffect(() => {
    if (data.length > 0) {
      let newbreadcrumbs: any[] = [];
      for (let a = 0; a < data.length; a++) {
        if (data[a].url !== null && data[a].url !== "") {
          newbreadcrumbs.push(
            <MLink
              underline="hover"
              key={a}
              color="inherit"
              component={Link}
              to={data[a].url}
              //   onClick={handleClick}
              variant="body2"
            >
              {data[a].name}
            </MLink>
          );
        } else {
          newbreadcrumbs.push(
            <Typography variant="body2" key={a} color="text.primary">
              {data[a].name}
            </Typography>
          );
        }
      }
      setBreadcrumbs(newbreadcrumbs);
    }
  }, [data]);

  console.log();

  return (
    <Stack spacing={2} className={className ? className : ""}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default CustomSeparator;
