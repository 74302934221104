import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import PaidIcon from "@mui/icons-material/Paid";
import { useSelector } from "react-redux";
import type { RootState } from "../../../store/index";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import HomeIcon from "@mui/icons-material/Home";
export default function BasicList() {
  const location = useLocation();
  const { profile } = useSelector((state: RootState) => state.userState);

  return (
    <Box sx={{ width: "100%", maxWidth: 360 }}>
      <nav aria-label="main mailbox folders">
        <List className="myaccount_listitem">
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to={"./cart"}
              selected={location.pathname === "/cart"}
            >
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Bakul" />
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to={"./orders"}
              selected={location.pathname === "/orders"}
            >
              <ListItemIcon>
                <ShoppingBagIcon />
              </ListItemIcon>
              <ListItemText primary="Pesanan" />
            </ListItemButton>
          </ListItem> */}
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to={"./zakat"}
              selected={location.pathname === "/zakat"}
            >
              <ListItemIcon>
                <PaidIcon />
              </ListItemIcon>
              <ListItemText primary="Zakat" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to={"./contribution"}
              selected={location.pathname === "/contribution"}
            >
              <ListItemIcon>
                <AltRouteIcon />
              </ListItemIcon>
              <ListItemText primary="Agihan" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to={"./rating"}
              selected={location.pathname === "/rating"}
            >
              <ListItemIcon>
                <ThumbsUpDownIcon />
              </ListItemIcon>
              <ListItemText primary="Rating Kempen" />
            </ListItemButton>
          </ListItem>
          <Divider />
          {/ADMIN/i.test(profile?.position) && (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to={"/dashboard"}
                  selected={location.pathname === "/dashboard"}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </ListItem>
            </>
          )}
          {/* <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to={"./address"}
              selected={location.pathname === "/address"}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Alamat" />
            </ListItemButton>
          </ListItem> */}
        </List>
      </nav>
      {/* <Divider />
      <nav aria-label="secondary mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Trash" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#simple-list">
              <ListItemText primary="Spam" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav> */}
    </Box>
  );
}
