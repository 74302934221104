import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// import _ from "lodash";

const AddressBookForm = ({ token, form, callback }: any) => {
  const [provinsi, setProvinsi] = React.useState<any[]>();
  const [kota, setKota] = React.useState<any[]>();
  const [kecamatan, setKecamatan] = React.useState<any[]>();
  const [keluruhan, setKeluruhan] = React.useState<any[]>();
  const [postcode, setPostcode] = React.useState<any[]>();

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/ref/provinsi", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setProvinsi(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof provinsi === "undefined" &&
      typeof token !== "undefined" &&
      token !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, provinsi]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/ref/kota/" + form?.provinsi_id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setKota(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof kota === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof form?.provinsi_id !== "undefined" &&
      form?.provinsi_id !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, kota, form]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/ref/keluruhan/" + form?.kecamatan_id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setKeluruhan(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof keluruhan === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof form?.kecamatan_id !== "undefined" &&
      form?.kecamatan_id !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, keluruhan, form]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/ref/postcode/" + form?.kecamatan_id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setPostcode(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof postcode === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof form?.kecamatan_id !== "undefined" &&
      form?.kecamatan_id !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, postcode, form]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/ref/kecamatan/" + form?.kota_id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setKecamatan(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof kecamatan === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof form?.kota_id !== "undefined" &&
      form?.kota_id !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, kecamatan, form]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setAge(event.target.value);
    if (typeof callback !== "undefined") {
      //   const newform: any = { ...form, [event.target.id]: event.target.value };
      //   console.log(form, newform);
      //   if (!_.isEqual(form, newform)) {
      //   callback(newform);
      //   }
      callback({ ...form, [event.target.id]: event.target.value });
    }
  };

  const handleOptionChange = (event: SelectChangeEvent) => {
    // setAge(event.target.value);
    if (typeof callback !== "undefined") {
      if (event.target.name === "provinsi_id") {
        setKota(undefined);
        setKecamatan(undefined);
        callback({
          ...form,
          kota_id: "",
          kecamatan_id: "",
          keluruhan_id: "",
          postcode: "",
          [event.target.name]: event.target.value,
        });
      } else if (event.target.name === "kota_id") {
        setKecamatan(undefined);
        setKeluruhan(undefined);
        setPostcode(undefined);
        callback({
          ...form,
          kecamatan_id: "",
          keluruhan_id: "",
          postcode: "",
          [event.target.name]: event.target.value,
        });
      } else if (event.target.name === "kecamatan_id") {
        setPostcode(undefined);
        setKeluruhan(undefined);
        callback({
          ...form,
          keluruhan_id: "",
          postcode: "",
          [event.target.name]: event.target.value,
        });
      } else {
        callback({ ...form, [event.target.name]: event.target.value });
      }
    }
  };

  //   console.log(formx);

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid item xs={12} sm={6}>
        Nama Depan
      </Grid>
      <Grid item xs={12} sm={6}>
        Nama Belakang
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="first_name"
          value={form?.first_name ? form?.first_name : ""}
          onChange={handleValueChange}
          // label="Name Depan"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="last_name"
          value={form?.last_name ? form?.last_name : ""}
          onChange={handleValueChange}
          // label="Name Belakang"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        No. Telp
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="phone_no"
          value={form?.phone_no ? form?.phone_no : ""}
          onChange={handleValueChange}
          // label="Name Belakang"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        e-mail Akaun
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="email"
          value={form?.email ? form?.email : ""}
          onChange={handleValueChange}
          // label="Name Belakang"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        Alamat:
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="address"
          value={form?.address ? form?.address : ""}
          onChange={handleValueChange}
          // label="Name Belakang"
          size="small"
          multiline
          rows={4}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        Provinsi:
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <Select
            name="provinsi_id"
            value={form?.provinsi_id ? form?.provinsi_id : ""}
            onChange={handleOptionChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {provinsi &&
              provinsi.map((item: any, idx: any) => (
                <MenuItem value={item?.code} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        Kota/Kabupaten:
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <Select
            name="kota_id"
            value={form?.kota_id ? form?.kota_id : ""}
            onChange={handleOptionChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {kota &&
              kota.map((item: any, idx: any) => (
                <MenuItem value={item?.code} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        Kecamatan:
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <Select
            name="kecamatan_id"
            value={form?.kecamatan_id ? form?.kecamatan_id : ""}
            onChange={handleOptionChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {kecamatan &&
              kecamatan.map((item: any, idx: any) => (
                <MenuItem value={item?.code} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        Postcode:
      </Grid>
      <Grid item xs={7}>
        Keluruhan:
      </Grid>
      <Grid item xs={5}>
        <FormControl fullWidth size="small">
          <Select
            name="postcode"
            value={form?.postcode ? form?.postcode : ""}
            onChange={handleOptionChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {postcode &&
              postcode.map((item: any, idx: any) => (
                <MenuItem value={item?.code} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <FormControl fullWidth size="small">
          <Select
            name="keluruhan_id"
            value={form?.keluruhan_id ? form?.keluruhan_id : ""}
            onChange={handleOptionChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {keluruhan &&
              keluruhan.map((item: any, idx: any) => (
                <MenuItem value={item?.code} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default AddressBookForm;
