import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
// import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import {
  formatDuration,
  setDefaultOptions,
  intervalToDuration,
  format,
} from "date-fns";
import { ms } from "date-fns/locale";

import Typography from "@mui/material/Typography";
import { auth } from "../../config/Firebase";
// import type { RootState } from "../../store/index";
import "./Index.css";
import Product from "../../components/Store/Product";
import { localeCurrency } from "../../components/Store/Store";

const StoreSelectedProduct = ({ main, id }: any) => {
  const navigate = useNavigate();
  const [token, setToken] = React.useState<any>();
  const [contributors, setContributors] = React.useState<any[]>();
  // const { tenant } = useSelector((state: RootState) => state.userState);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        } else {
          setToken("");
        }
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;

    const fetchData = () => {
      let fetchurl: string = "/api/public/contributor/list?featured=true";
      if (typeof token !== "undefined" && token !== "") {
        fetchurl = "/api/contributor/list?featured=true";
      }
      if (typeof id !== "undefined" && id !== "") {
        fetchurl += "&product=" + id;
      }
      fetch(fetchurl, {
        headers:
          typeof token !== "undefined"
            ? {
                Authorization: "Bearer " + token,
              }
            : {},
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            if (data?.list) {
              setContributors(data?.list);
            }
          }
        });
    };

    if (typeof contributors === "undefined") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, contributors, id]);

  const callback = (product?: any) => {
    // console.log(product);
    navigate("/store/product/" + product?.id);
  };

  const getDuration = (date?: any) => {
    const duration = intervalToDuration({
      start: new Date(date),
      end: new Date(),
    });
    console.log(duration);
    delete duration.seconds;
    setDefaultOptions({ locale: ms });
    return formatDuration(duration);
  };

  return (
    <Grid
      container
      spacing={main ? 3 : 1}
      padding={main ? 2 : 1}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {/* <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography
          variant="h2"
          sx={{
            color: "#FFD800",
            fontFamily: "Poppins",
          }}
        >
          <strong>Terima Kasih</strong>
        </Typography>
        <Typography
          variant="h5"
          className="productcategory_label"
          sx={{ color: "#ffffff" }}
        >
          Menyumbang Crowd Zakat
        </Typography>
      </Grid> */}
      {contributors &&
        contributors.map((contributor: any, idx: any) => (
          <Grid item xs={12} lg={main ? 6 : 12} key={idx}>
            <Paper elevation={main ? 1 : 0}>
              <Grid
                container
                spacing={main ? 1 : 0}
                padding={main ? 2 : 1}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={2} md={1} lg={2}>
                  <Avatar
                    alt={contributor?.name}
                    src={contributor?.picture}
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={main ? 10 : 7}
                  md={main ? 11 : 9}
                  lg={main ? 10 : 7}
                >
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <strong>
                        {contributor?.anonymous <= 0
                          ? contributor?.name
                          : "Hamba Allah"}
                      </strong>
                    </Grid>
                    <Grid item xs={12}>
                      Memberi zakat sebanyak{" "}
                      <strong>{localeCurrency(contributor?.allocation)}</strong>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="caption"
                        onClick={() => {
                          navigate(
                            "/store/product/" +
                              contributor?.product_id +
                              "#product"
                          );
                        }}
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          color: "#1F5AAB",
                        }}
                      >
                        {contributor?.product_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={main ? 12 : 3} md={main ? 12 : 2}>
                  {main ? (
                    <Typography variant="caption">
                      {getDuration(contributor?.created_at)} yang lalu
                    </Typography>
                  ) : (
                    <Typography variant="caption">
                      {contributor?.created_at
                        ? format(
                            new Date(contributor?.created_at),
                            "yyyy-MM-dd HH:mm:00 aa"
                          )
                        : ""}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Paper>
            {/* <Product product={product} callback={callback} /> */}
          </Grid>
        ))}
    </Grid>
  );
};

export default StoreSelectedProduct;
