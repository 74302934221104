import React from "react";
// import {
//   Edit as EditIcon,
//   Article as ArticleIcon,
//   AddCircle as AddIcon,
// } from "@mui/icons-material";
import { format, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Table from "../Tables/DynamicTables";
import { auth } from "../../config/Firebase";
import { localeCurrency } from "../Store/Store";

interface DialogProps {
  data?: any;
  id?: any;
  callback?: (data: any, type?: any) => void;
}

const IndexPanel = (props: DialogProps) => {
  const { callback } = props;
  const navigate = useNavigate();
  const [token, setToken] = React.useState<any>("");
  const [columns] = React.useState<any[]>([
    // 'payment.id',/
    // 'orders.refno',/
    // 'payment.created_at as payment_date',/
    // 'profile.staff_name as customer_name',/
    // 'payment.total_payment',/
    // 'payment.payment_type as payment_method',/
    // 'payment.payment_status'/
    { accessorKey: "id", header: "ID", sortable: true, size: 50 },
    {
      accessorKey: "picture",
      size: 40,
      header: "Avatar",
      sortable: true,
      Cell: (item?: any) => (
        <div>
          <Avatar
            alt={item?.row.original.name ? item?.row.original.name : ""}
            src={
              item?.row.original.picture
                ? item?.row.original.picture
                : "/static/images/avatar/1.jpg"
            }
            sx={{
              width: 40,
              height: 40,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </div>
        // <div
        //   className="productthumbnail"
        //   style={{
        //     width: "100%",
        //     maxWidth: "40px",
        //     backgroundImage:
        //       "url(" +
        //       (typeof item?.row.original.picture !== "undefined" &&
        //       item?.row.original.picture !== ""
        //         ? item?.row.original.picture
        //         : "/images/default.png") +
        //       ")",
        //   }}
        //   title={item?.row.original.name ? item?.row.original.name : ""}
        // />
      ),
    },
    { accessorKey: "name", header: "Peserta", sortable: true },
    {
      accessorKey: "product_name",
      header: "Kempen",
      sortable: true,
    },
    {
      accessorKey: "allocation",
      header: "Agihan",
      sortable: true,
      Cell: (item?: any) => (
        <>
          {localeCurrency(
            item?.row.original.allocation
              ? parseFloat(item?.row.original.allocation)
              : 0
          )}
        </>
      ),
    },
    // {
    //   accessorKey: "payment_status",
    //   header: "Status Pembayaran",
    //   sortable: true,
    // },
    {
      accessorKey: "created_at",
      header: "Tarikh Agihan",
      sortable: true,
      Cell: (item?: any) => (
        <>
          {isValid(new Date(item?.row.original.created_at)) ? (
            <>
              {format(
                new Date(item?.row.original.created_at),
                "yyyy-MM-dd HH:mm:ss"
              )}
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
  ]);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  const selectedCallback = (selecteddata?: any, type?: any) => {
    if (callback) {
      callback(selecteddata, type);
    }
  };

  return (
    <>
      {token ? (
        <Table
          enableRowNumbers={false}
          disabledPaper={true}
          api={"/api/contributor/list"}
          // apiDownload={"/api/order/download"}
          token={token}
          fieldid={"id"}
          arrayRef={"list"}
          dataCallback={(e: any) => {
            // console.log(e);
            // setCategory(e?.metadata?.category);
          }}
          onRowClick={(e: any) => {
            // console.log("here", e); //remove this if dont use
            // navigate(`/dbpedi/member/form/${e?.id}`);
            if (typeof selectedCallback !== "undefined") {
              // console.log(e);
              navigate(`/dashboard/contributor/info/${e?.id}`);
              // selectedCallback(e);
            }
          }}
          onFilterModelChange={(e: any) => {
            console.log(e); //remove this if dont use
          }}
          prefixfilename={"-selling"}
          pagesize={20}
          autoheight={true}
          columns={columns}
          columnhides={["id"]}
          // rowactions={{
          //   name: "Actions",
          //   position: "last", //first|last
          //   buttons: [
          //     {
          //       icon: <ArticleIcon />,
          //       action: (row?: any) => {
          //         // console.log("here", row);
          //         selectedCallback(row, "info");
          //       },
          //     },
          //     {
          //       icon: <EditIcon />,
          //       action: (row?: any) => {
          //         // console.log("here", row, 'edit');
          //         selectedCallback(row, "edit");
          //       },
          //     },
          //   ],
          // }}
        />
      ) : (
        <>Loading...</>
      )}
    </>
  );
};

export default IndexPanel;
