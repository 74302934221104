import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import OutlinedInput from "@mui/material/OutlinedInput";
import clsx from "clsx";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MuiAlert from "@material-ui/lab/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { setPendingStatus } from "./../../store/form";
import Back from "../Back";
// import SelectAgency from "../Forms/Libs/Agency";
import AgencyAutocomplete from "../Forms/Libs/AgencyAutocomplete";
// import SelectPupukAgency from "../Forms/PupukAgency";

import Breadcrumbs from "../Breadcrumbs";
import { auth } from "../../config/Firebase";
// import { Headers, AuthorizationHeader, fetchPlus } from "./../../store/headers";
import { setLabel } from "./../../store/form";
import type { RootState } from "../../store/index";

import "./Form.css";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  margin: {
    // margin: theme.spacing(1),
  },
  textField: {
    width: "100%",
  },
  rootheader: {
    flexGrow: 1,
    // height: "100%",
    width: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.5) !important",
    border: "0x solid #ff0000",
    margin: "0px !important",
    padding: "0px !important",
    marginBottom: "30px !important;",
    marginTop: "20px !important",
  },
  center: {
    textAlign: "center",
  },
  color: {
    textAlign: "center",
  },
  label: {
    backgroundColor: "#ffffff",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

interface PropsTotal {
  id?: string;
}
interface UserProfile {
  id?: string;
  user_id?: string;
  access?: string[];
  name?: string;
  email?: string;
  picture?: string;
  token?: string;
  agency?: string;
  [key: string]: number | string | object | any;
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UserForm: React.FC<PropsTotal> = (props) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.userState);

  const [user, setUser] = React.useState<string>();
  const [userprofile, setUserProfile] = React.useState<UserProfile>({
    id: "",
    user_id: "",
    access: [],
    name: "",
    email: "",
    picture: "",
    agency: "",
    position: "",
  });
  const [querystatus, setQuerystatus] = React.useState<string>("");
  // const [piks, setPiks] = React.useState<PusatInternetProps[]>();
  // const [provider, setProvider] = React.useState<string>();
  const [agencylist, setAgencyList] = React.useState<any[]>();
  const [pupuklist, setPupukList] = React.useState<any[]>();
  const [usersGroup, setUsersGroup] = React.useState<any[]>();

  const [token, setToken] = React.useState<any>("");

  const classes = useStyles();
  // <{ id?: string }>
  let { id } = useParams();
  if (!id) {
    id = props.id;
  }

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (id) {
      setUser(id);
    }
  }, [id, setUser]);

  React.useEffect(() => {
    let isMounted = true;
    if (token !== "") {
      if (typeof agencylist === "undefined") {
        fetch("/api/agency/list?compact=true", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (isMounted) {
              // console.log("---");
              // setAgencyList(data.agencies);
              let newdata: any[] = [];
              for (let a = 0; a < data.agencies.length; a++) {
                newdata.push({
                  id: data.agencies[a].id,
                  label:
                    "#" + data.agencies[a].id + " - " + data.agencies[a].name,
                });
              }
              setAgencyList(newdata);
            }

            if (data.pupuk_agencies) {
              setPupukList(data.pupuk_agencies);
            }
          });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [agencylist, pupuklist, token]);

  // console.log(agencylist, "gg");

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      if (user === "new") {
        let newuserprofile: UserProfile = JSON.parse(
          JSON.stringify(userprofile)
        );
        newuserprofile.user_id = user;
        setUserProfile(newuserprofile);
      } else {
        fetch("/api/user/profile/" + user, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (isMounted) {
              setUserProfile(data.profile);
              // setProvider(data.profile.agency);
              if (data?.profile?.name) {
                dispatch(setLabel(data.profile.name));
              }
              if (data?.metadata?.usersgroup) {
                setUsersGroup(data?.metadata?.usersgroup);
              }
            }
          });
      }
    };

    if (user && userprofile?.user_id === "" && token !== "") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [user, dispatch, userprofile, token]);

  // React.useEffect(() => {
  //   let isMounted = true;
  //   const fetchData = () => {
  //     fetch("/api/pik/list/" + provider, {
  //       headers: {
  //         Authorization: "Bearer " + token,
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (isMounted) {
  //           // console.log(data);
  //           setPiks(data.pusatinternets);
  //         }
  //       });
  //   };
  //   if (
  //     typeof provider !== "undefined" &&
  //     provider !== "" &&
  //     provider !== null &&
  //     provider !== "null" &&
  //     token !== ""
  //   ) {
  //     fetchData();
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [provider, token]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event.target.id);
    if (typeof userprofile !== "undefined") {
      console.log(event.target.id, event.target.value);
      let newuserprofile: UserProfile = JSON.parse(JSON.stringify(userprofile));
      newuserprofile[event.target.id] = event.target.value;
      setUserProfile(newuserprofile);
    }
  };

  const handlePositionChange = (event?: SelectChangeEvent) => {
    // console.log(event?.target, userprofile[event?.target?.id]);
    setUserProfile({
      ...userprofile,
      [event?.target?.name ? event?.target?.name : "position"]:
        event?.target?.value,
    });
  };

  const handleSelectChange = (
    event?: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    if (event && event.target && event.target.name) {
      console.log(event.target.name, event.target.value);
      let newuserprofile: UserProfile = JSON.parse(JSON.stringify(userprofile));
      newuserprofile[event.target.name] = parseInt(event.target.value);
      setUserProfile(newuserprofile);
    }
  };

  const updateUserProfile = () => {
    setQuerystatus("");
    const fields: string[] = [
      "id",
      "user_id",
      "pi1m_refid",
      "pi1m_refids",
      "name",
      "email",
      "access",
      "status",
      "agency",
      "pupuk_agencies",
      "position",
      "state",
    ];
    const data = new URLSearchParams();
    for (let f in fields) {
      if (userprofile && userprofile[fields[f]] !== "") {
        console.log(f, fields[f], userprofile[fields[f]]);
        data.append(fields[f], userprofile[fields[f]]);
      }
    }
    console.log(userprofile);
    if (userprofile.name === "" || userprofile.email === "") {
      window.alert("Missing required fields.");
      return false;
    }

    // data.forEach((d,i) => {
    //   console.log(d);
    // });
    fetch("/api/user/profile", {
      method: "post",
      body: data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.profile);
        setQuerystatus(data.profile.status);
        dispatch(setPendingStatus(new Date().getTime().toString()));
      });
  };

  const setValue = (id: string, value: string | any) => {
    // console.log(id, value);
    if (typeof userprofile !== "undefined") {
      let newuserprofile: UserProfile = JSON.parse(JSON.stringify(userprofile));
      newuserprofile[id] = value;

      if (id === "agency") {
        // setProvider(value);
        newuserprofile.pi1m_refid = "";
      }
      setUserProfile(newuserprofile);
    }
  };

  const UserStatus: string[] = ["Pending", "Approved", "Revoke"];
  // const positions: any[] = [
  //   { id: "ADMIN", label: "ADMIN" },
  //   { id: "ADMIN STATE OFFICE", label: "ADMIN State Office" },
  //   { id: "ADMIN STAFF HEADQUATERS", label: "ADMIN Staff Headquaters" },
  //   { id: "PROVIDER", label: "Service Provider" },
  //   { id: "MANAGER", label: "PEDi Manager" },
  //   { id: "ASSISTANT MANAGER", label: "PEDi Assistant Manager" },
  //   { id: "AGENCY", label: "PUPUK Agency" },
  //   { id: "RAKANDIGITAL", label: "Rakan Digital Pupuk" },
  // ];

  // const filterPediCallback = (id: any) => {
  //   if (typeof id !== "undefined") {
  //     setUserProfile({
  //       ...userprofile,
  //       pi1m_refid: id,
  //     });
  //   }
  // };

  // if(userprofile && typeof userprofile.user_id !== 'undefined'){
  // console.log(userprofile?.position);
  return (
    <>
      <div style={{ display: "flex" }}>
        <Back />
        {/* <Typography variant="h5">Edit User Profile</Typography> */}
        <Typography variant="subtitle1">User Profile Manager</Typography>
      </div>

      <Breadcrumbs
        current={"User Profile Manager"}
        paths={[
          { name: "Pengurusan", url: "/dashboard" },
          { name: "Pengguna", url: "/dashboard/user/list" },
        ]}
        className="margintop"
      />
      <Paper elevation={1} style={{ padding: "20px" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {userprofile?.picture && (
            <Grid item xs={4} sm={2}>
              <img src={userprofile?.picture} alt="" className="userprofile" />
            </Grid>
          )}
          <Grid
            item
            xs={id === "new" || !userprofile?.picture ? 12 : 8}
            sm={id === "new" || !userprofile?.picture ? 12 : 10}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {querystatus !== "" && (
                <Grid item xs={12} style={{ paddingBottom: "20px" }}>
                  {querystatus === "NEW_DUPLICATE" && (
                    <Alert severity="error">
                      Query failed - Profile already registered.
                    </Alert>
                  )}
                  {querystatus === "NEW_REGISTERED" && (
                    <Alert severity="success">
                      Profile has been registered.
                    </Alert>
                  )}
                  {querystatus === "UPDATED" && (
                    <Alert severity="success">Profile has been updated.</Alert>
                  )}
                  {querystatus === "SAVED" && (
                    <Alert severity="success">Profile has been saved.</Alert>
                  )}
                  {querystatus === "ERROR" && (
                    <Alert severity="error">
                      Query failed - Unknown Reason.
                    </Alert>
                  )}
                  {querystatus === "PERMISSION_DENIED" && (
                    <Alert severity="error">
                      You doesn't have enough permission to do this update.
                    </Alert>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  className={clsx(classes.margin, classes.textField)}
                >
                  <InputLabel htmlFor="name" className={classes.label}>
                    Name
                  </InputLabel>
                  <OutlinedInput
                    id="name"
                    type="name"
                    label="Name"
                    value={userprofile?.name ? userprofile?.name : ""}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={
                  profile?.position === "ADMIN" &&
                  usersGroup &&
                  usersGroup.length > 0
                    ? 6
                    : 12
                }
              >
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel htmlFor="email">e-mail</InputLabel>
                  <OutlinedInput
                    id="email"
                    type="email"
                    label="e-mail"
                    value={userprofile?.email ? userprofile?.email : ""}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>

              {profile?.position === "ADMIN" &&
                usersGroup &&
                usersGroup.length > 0 && (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel htmlFor="position" className={classes.label}>
                        User Role
                      </InputLabel>
                      <Select
                        labelId="position"
                        label="User Role"
                        id="position"
                        name="position"
                        value={
                          userprofile?.position ? userprofile?.position : ""
                        }
                        onChange={handlePositionChange}
                        style={{ width: "100%" }}
                      >
                        <MenuItem value=""></MenuItem>
                        {usersGroup &&
                          usersGroup.map((post: any, idx) => (
                            <MenuItem key={idx} value={post.id ? post.id : ""}>
                              {post.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

              {userprofile?.position === "ADMIN" && (
                <Grid item xs={12}>
                  <AgencyAutocomplete
                    id="agency"
                    token={token}
                    data={agencylist ? agencylist : []}
                    setValue={setValue}
                    defaultValue={
                      userprofile?.agency ? userprofile?.agency : ""
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b>User Status</b>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="Status"
                    name="status"
                    // value
                    value={userprofile?.status ? userprofile.status : 0}
                    // value={
                    //   userprofile?.status === "0"
                    //     ? "0"
                    //     : userprofile.status === "1"
                    //     ? "1"
                    //     : "2"
                    // }
                    onChange={handleSelectChange}
                  >
                    {UserStatus &&
                      UserStatus.map((status, idx) => (
                        <FormControlLabel
                          key={idx}
                          value={idx}
                          control={<Radio />}
                          label={UserStatus[idx]}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => updateUserProfile()}
                >
                  {id === "new" ? "Create" : "Update"} User Profile
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
  // }else{
  //   return (
  //     <></>
  //   );
  // }
};
export default UserForm;
