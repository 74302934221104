import StoreIcon from "@mui/icons-material/Store";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import parse from "html-react-parser";
import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import InputBase from "@mui/material/InputBase";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { Link, useNavigate, useParams } from "react-router-dom";
import { localeCurrency } from "../../components/Store/Store";
// import AlertTitle from "@mui/material/AlertTitle";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Rating,
  Stack,
  Tooltip,
  Snackbar,
} from "@mui/material";

import { format, isValid } from "date-fns";
import UnitButton from "../../components/Store/UnitButton";
import AllocateButton from "../../components/Store/AllocateButton";
import "./ProductInfo.css";
import ProductMostViewed from "./ProductMostViewed";

interface DialogProps {
  user?: any;
  data?: any;
}

const InfoPanel = (props: DialogProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activephoto, setActivePhoto] = React.useState<string>(
    "/images/default.png"
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [formdata, setFormData] = React.useState<any>();
  const [rating, setRating] = React.useState<any[]>();
  const [curid, setCurid] = React.useState<string>("");
  const [seeMore, setSeeMore] = React.useState<number>();

  React.useEffect(() => {
    let isMounted = true;

    // console.log(id);
    if (
      typeof id !== "undefined" &&
      id !== "" &&
      id !== "new" &&
      id !== curid
    ) {
      if (isMounted) {
        setCurid(id);
        setFormData(undefined);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [id, curid]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      // setLoading(true);
      fetch("/api/public/product/find/" + curid)
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setFormData(data.product);
            if (data.product?.photos.length > 0) {
              setActivePhoto(data.product?.photos[0]);
            }
            // setLoading(false);
          }
        });
    };

    const fetchRatingData = () => {
      fetch("/api/public/product/rating/" + curid)
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setRating(data.list);
          }
        });
    };

    if (
      typeof curid !== "undefined" &&
      curid !== "" &&
      curid !== "new" &&
      typeof formdata === "undefined"
    ) {
      // console.log(curid);
      fetchData();
      fetchRatingData();
    }
    return () => {
      isMounted = false;
    };
  }, [formdata, curid]);
  //   console.log("formdata", formdata);

  const randomIntFromInterval = (min: number, max: number) => {
    // min and max included
    min = min * 10;
    max = max * 10;
    const rand = Math.floor(Math.random() * (max - min + 1) + min) / 10;
    console.log(rand);
    return rand;
  };

  if (
    typeof formdata !== "undefined" &&
    typeof formdata?.current_collection === "undefined"
  ) {
    const rand = randomIntFromInterval(0, 1);
    formdata.current_collection = rand * (formdata?.price - 0);
    formdata.current_percentage = rand * 100;
    console.log(formdata);
  } else if (
    typeof formdata !== "undefined" &&
    typeof formdata?.current_collection !== "undefined"
  ) {
    formdata.current_percentage =
      (formdata.current_collection / formdata?.price) * 100;
  }

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      // href="/"
      // onClick={handleClick}
    >
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
      Utama
    </Link>,
    // <Link
    //   underline="hover"
    //   key="2"
    //   color="inherit"
    //   href="/material-ui/getting-started/installation/"
    //   // onClick={handleClick}
    // >
    //   Core
    // </Link>,
    <Typography key="3" color="text.primary">
      {formdata?.name ? formdata?.name : "Produk"}
    </Typography>,
  ];

  return (
    <>
      <Box style={{ paddingBottom: "20px" }}>
        <Breadcrumbs
          // separator={<NavigateNextIcon fontSize="small" />}
          separator="›"
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          // style={{ border: "1px solid #ff0000" }}
        >
          {formdata?.photos && formdata?.photos.length > 0 && (
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              style={{ height: "100%" }}
            >
              <Grid item xs={12} sm={12}>
                <div
                  style={{ backgroundImage: `${"url(" + activephoto + ")"}` }}
                  className="productthumbnailviewer"
                >
                  &nbsp;
                </div>
              </Grid>
              {formdata?.photos.length > 1 &&
                formdata?.photos.map((photo: any, idx: number) => (
                  <Grid item xs={3} sm={3} md={2} key={idx}>
                    <div
                      style={{ backgroundImage: `${"url(" + photo + ")"}` }}
                      className="productthumbnail"
                      onClick={() => setActivePhoto(photo)}
                    >
                      &nbsp;
                    </div>
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6} style={{ paddingBottom: "20px" }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {/* <Grid item xs={12}>
              <Alert severity="info">
                CATATAN: Produk akan dikirim dari{" "}
                <Link to={"/store/page/" + formdata?.agency_id}>
                  <strong>{formdata?.agency_name}</strong>
                </Link>
              </Alert>
            </Grid> */}
            {/* <Grid item xs={12}>
              <Paper
                elevation={0}
                className={"papercontainer"}
                style={{ display: "flex" }}
              >
                <div>SKU: {formdata?.sku ? formdata?.sku : "*"}</div>
                <div style={{ textAlign: "right", flex: 1, flexGrow: 1 }}>
                  Stok: {formdata?.quantity ? formdata?.quantity : 0}
                </div>
              </Paper>
            </Grid> */}
            <Grid item xs={12}>
              <strong>
                <Typography variant="h4">{formdata?.name}</Typography>
              </strong>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "left" }}>
              <Typography variant="subtitle1">
                {/* {localeCurrency(product?.price.toFixed(0))} */}
                {formdata?.current_collection > 0 ? (
                  <>
                    {" "}
                    <strong>
                      {localeCurrency(
                        formdata?.current_collection
                          ? formdata?.current_collection
                          : 0
                      )}
                    </strong>{" "}
                    telah terkumpul
                  </>
                ) : (
                  <>Belum ada agihan</>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  variant="determinate"
                  value={
                    formdata?.current_percentage
                      ? formdata?.current_percentage
                      : 0
                  }
                  color={
                    formdata?.current_percentage <= 0 ? "error" : "success"
                  }
                  sx={{
                    height: "10px",
                    strokeLinecap: "round",
                    borderRadius: "5px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <Typography variant="caption">
                Perlukan Agihan Sebanyak{" "}
                {localeCurrency(formdata?.price ? formdata?.price - 0 : 0)}
              </Typography>
            </Grid>

            {/* <Grid item xs={12} sm={7} md={8} lg={10}>
              <strong>
                {formdata?.price ? localeCurrency(formdata?.price) : "FREE"}
              </strong>
            </Grid> */}
            <Grid item xs={12}>
              Jom kongsikan kempen ini!
            </Grid>
            <Grid item xs={12}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Media Sosial"
                  inputProps={{ "aria-label": "Media Sosial" }}
                  value={
                    "http://crowdzakat.lzs.com.my/store/product/" + formdata?.id
                  }
                  fullWidth
                />
                <IconButton
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="Media Sosial"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <IconButton aria-label="Facebook" color="primary">
                    <FacebookIcon fontSize="inherit" />
                  </IconButton>

                  <IconButton aria-label="Instagram" color="primary">
                    <InstagramIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton aria-label="Twitter" color="primary">
                    <TwitterIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton aria-label="Telegram" color="primary">
                    <TelegramIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton aria-label="WhatsApp" color="primary">
                    <WhatsAppIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <AllocateButton product={formdata} />
            </Grid>
          </Grid>
          <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={2000}
            message="Salinan ke klipboard"
          />
          {/* <Paper elevation={1} style={{ padding: "20px", marginTop: "30px" }}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                SKU:
              </Grid>
              <Grid item xs={12} sm={6}>
                Produk Kategori:
              </Grid>
              <Grid item xs={12} sm={6}>
                <strong>{formdata?.sku ? formdata?.sku : ""}</strong>
              </Grid>
              <Grid item xs={12} sm={6}>
                <strong>
                  {formdata?.category_name ? formdata?.category_name : ""}
                </strong>
              </Grid>
              <Grid item xs={12} sm={6}>
                Size:
              </Grid>
              <Grid item xs={12} sm={6}>
                Berat {"(gram)"}:
              </Grid>
              <Grid item xs={12} sm={6}>
                <strong>{formdata?.size ? formdata?.size : "-"}</strong>
              </Grid>
              <Grid item xs={12} sm={6}>
                <strong>{formdata?.weight ? formdata?.weight : ""}</strong>
              </Grid>
              <Grid item xs={12} sm={6}>
                Harga Asas:
              </Grid>
              <Grid item xs={12} sm={6}>
                Cukai Jualan :
              </Grid>
              <Grid item xs={12} sm={6}>
                <strong>
                  {formdata?.price ? localeCurrency(formdata?.price) : ""}
                </strong>
              </Grid>
              <Grid item xs={12} sm={6}>
                <strong>{formdata?.taxable ? formdata?.taxable : "-"}</strong>
              </Grid>
              <Grid item xs={12}>
                Status Produk:
              </Grid>
              <Grid item xs={12}>
                <strong>{formdata?.status ? formdata?.status : "NEW"}</strong>
              </Grid>
            </Grid>
          </Paper> */}
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={0}
            // onChange={handleChange}
            aria-label="butiran"
          >
            <Tab label="Butiran" />
          </Tabs>

          <Box sx={{ paddingTop: "20px" }}>
            {parse(formdata?.description ? formdata?.description : "")}
          </Box>
        </Grid>
        {/* <Grid item xs={12}>
          <Paper
            elevation={3}
            className={"productagencycontainer"}
            style={{ display: "flex" }}
          >
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6} sm={2} md={1}>
                <div
                  style={{
                    backgroundImage: `${
                      "url(" +
                      (formdata?.agency?.photourl
                        ? formdata?.agency?.photourl
                        : "/images/default.png") +
                      ")"
                    }`,
                  }}
                  className="agencythumbnail"
                >
                  &nbsp;
                </div>
              </Grid>
              <Grid item xs={12} sm={8} md={10}>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Typography variant="h4" style={{ fontWeight: "bold" }}>
                      {formdata?.agency?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      {formdata?.agency?.kota_name},{" "}
                      {formdata?.agency?.provinsi_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      {formdata?.agency?.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} md={1}>
                <Tooltip
                  title={
                    // formdata.agency.name
                    //   ? formdata.agency.name
                    //   : "Pergi ke Toko"
                    "Pergi ke Toko"
                  }
                  placement="top"
                >
                  <IconButton
                    aria-label="back"
                    size="large"
                    onClick={() =>
                      navigate("/store/page/" + formdata?.agency_id)
                    }
                  >
                    <StoreIcon style={{ color: "#ed6c02", fontSize: "40" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <Paper sx={{ padding: "30px" }}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography fontWeight="bolder" fontSize={20}>
                  Penilaian Kempen
                </Typography>
                <Stack
                  direction="row"
                  display="flex"
                  alignItems="center"
                  spacing={2}
                >
                  <Rating
                    value={formdata ? formdata.rating : 0}
                    precision={0.1}
                    readOnly
                    size="large"
                  />
                  <Typography>
                    {formdata ? formdata.rating : 0} daripada 5
                  </Typography>
                </Stack>
              </Grid>
              <Divider sx={{ width: "100%", margin: "15px 0px 0px 0px" }} />
              {rating && rating.length > 0 ? (
                rating.map((key, index) => (
                  <Grid item xs={12} sm={12} md={6} key={index}>
                    <Card sx={{ padding: "10px", width: "100%" }} elevation={0}>
                      <Grid container direction="column" spacing={1}>
                        <Grid item xs={4} display="flex" flexDirection="column">
                          <Typography fontSize={15}>{key.name}</Typography>
                          <Typography fontSize={9}>
                            {isValid(new Date(key.created_at)) ? (
                              <>
                                {format(
                                  new Date(key.created_at),
                                  "yyyy-MM-dd HH:mm:ss"
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Rating value={key.star} precision={0.1} readOnly />
                        </Grid>
                        <Grid item xs={5}>
                          {seeMore && seeMore === key.rating_id ? (
                            <Box
                              sx={{
                                // display: "-webkit-box",
                                // WebkitBoxOrient: "vertical",
                                // WebkitLineClamp: 20,
                                overflow: "hidden",
                              }}
                            >
                              <Typography>
                                {key.message
                                  ? key.message.slice(0, 1000)
                                  : "Tidak ada pesan"}
                                <Button
                                  size="small"
                                  variant="text"
                                  onClick={(e: any) => {
                                    setSeeMore(0);
                                  }}
                                  sx={{
                                    margin: 0,
                                    padding: 0,
                                    bottom: "1px",
                                    textTransform: "none",
                                  }}
                                >
                                  Tutup
                                </Button>
                              </Typography>
                            </Box>
                          ) : (
                            <>
                              <Box
                                sx={{
                                  //   display: "-webkit-box",
                                  //   WebkitBoxOrient: "vertical",
                                  //   WebkitLineClamp: 5,
                                  overflow: "hidden",
                                }}
                              >
                                <Typography>
                                  {key.message
                                    ? key.message.slice(0, 300)
                                    : "Tidak ada pesan"}
                                  {key.message && key.message.length > 300 ? (
                                    <>
                                      ...
                                      <Button
                                        size="small"
                                        variant="text"
                                        onClick={(e: any) => {
                                          setSeeMore(key.rating_id);
                                        }}
                                        sx={{
                                          margin: 0,
                                          padding: 0,
                                          bottom: "1px",
                                          textTransform: "none",
                                        }}
                                      >
                                        Lihat
                                      </Button>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography textAlign="center">
                    Tidak ada penilaian untuk ketika ini
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <ProductMostViewed curid={curid} />
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPanel;
