import React from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import { auth } from "../../config/Firebase";
import { localeCurrency } from "../../components/Store/Store";

interface DialogProps {
  user?: any;
  data?: any;
}

const InfoPanel = (props: DialogProps) => {
  const { id } = useParams();
  const [token, setToken] = React.useState<any>();

  const [orders, setOrders] = React.useState<any>();
  const [ordererror, setOrderError] = React.useState<string>("");

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      // setLoading(true);
      fetch("/api/orders/success/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            // console.log(data);
            if (typeof data.overview !== "undefined") {
              setOrders(data.overview);
            } else {
              setOrderError(data?.metadata?.message);
            }
            // if (data.product?.photos.length > 0) {
            //   setActivePhoto(data.product?.photos[0]);
            // }
            // setLoading(false);
          }
        });
    };

    if (
      typeof orders === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof id !== "undefined" &&
      id !== "" &&
      id !== "new"
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [id, token, orders]);

  // React.useEffect(() => {
  //   let isMounted = true;
  //   const fetchData = () => {
  //     // setLoading(true);
  //     fetch("/api/apps/orders/list", {
  //       headers: {
  //         Authorization: "Bearer " + token,
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (isMounted) {
  //           console.log(data);
  //         }
  //       });
  //   };

  //   if (typeof token !== "undefined" && token !== "") {
  //     fetchData();
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [token]);

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="h5">Order Successful</Typography>
        </Grid>
        {ordererror !== "" ? (
          <Grid item xs={12}>
            <Alert severity={"error"}>{ordererror}</Alert>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Alert severity={"success"}>
                Order has been placed, please make payment using the following
                information
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={2} style={{ padding: "20px" }}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Bank:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.bank?.bank_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Bank Code:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.bank?.bank_code}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Acc No.:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.bank?.accno}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Vacc No.:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.bank?.vaccno}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Currency:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.bank?.currency}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Total Payment:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {localeCurrency(
                        orders?.bank?.total_payment
                          ? orders?.bank?.total_payment - 0
                          : 0
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={2} style={{ padding: "20px" }}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">First Name:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.address?.first_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Last Name:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.address?.last_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">e-mail:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.address?.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Address:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.address?.address}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Postcode:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.address?.postcode}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Kelurahan:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.address?.keluruhan_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Kecamatan:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.address?.kecamatan_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Kota/Kabupaten:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.address?.kabupaten_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Provinsi:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {orders?.address?.provinsi_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default InfoPanel;
