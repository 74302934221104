import React from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { auth } from "../../config/Firebase";

import Back from "../Back";
import Breadcrumbs from "../Breadcrumbs";
import PieNeedle from "../Charts/PieNeedle";
import { localeCurrency } from "../Store/Store";
import LatestContributor from "../../pages/Store/LatestContributor";
// import form from "../../store/form";

import "./Product.css";

interface DialogProps {
  user?: any;
  data?: any;
}

const InfoPanel = (props: DialogProps) => {
  const { id } = useParams();

  const [activephoto, setActivePhoto] = React.useState<string>(
    "/images/default.png"
  );
  const [formdata, setFormData] = React.useState<any>();
  const [token, setToken] = React.useState<any>("");

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      // setLoading(true);
      fetch("/api/product/find/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setFormData(data.product);
            if (data.product?.photos.length > 0) {
              setActivePhoto(data.product?.photos[0]);
            }
            // setLoading(false);
          }
        });
    };

    if (
      typeof id !== "undefined" &&
      id !== "" &&
      id !== "new" &&
      typeof formdata === "undefined" &&
      token !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, formdata, id]);

  return (
    <>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <Back />
        <Typography variant="subtitle1" component="h6">
          Maklumat Kempen
        </Typography>
      </div>
      <Breadcrumbs
        current={formdata?.name ? formdata?.name : "Form"}
        paths={[
          { name: "Pengurusan", url: "/administration" },
          {
            name: "Kempen",
            url: "/dashboard/product",
          },
        ]}
        className="margintop"
      />
      <Paper elevation={0} className={"papercontainer margintop"}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={7} lg={8}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12}>
                <div
                  style={{
                    height: "120px",
                    minHeight: "50px",
                    maxHeight: "200px",
                    // width: "100%",
                    // border: "1px solid #ff0000",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <PieNeedle />
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={5} lg={3}>
                SKU:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.sku ? formdata?.sku : ""}
              </Grid> */}
              <Grid item xs={12} sm={5} lg={3}>
                Nama:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.name ? formdata?.name : ""}
              </Grid>
              <Grid item xs={12} sm={5} lg={3}>
                Keterangan:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.description ? formdata?.description : ""}
              </Grid>
              {/* <Grid item xs={12} sm={5} lg={3}>
                Agency/Toko:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.agency_name ? formdata?.agency_name : ""}
              </Grid> */}
              <Grid item xs={12} sm={5} lg={3}>
                Produk Kategori:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.category_name ? formdata?.category_name : ""}
              </Grid>
              {/* <Grid item xs={12} sm={5} lg={3}>
                Ukuran:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.size ? formdata?.size : ""}
              </Grid>
              <Grid item xs={12} sm={5} lg={3}>
                Berat:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.weight ? formdata?.weight : ""}
              </Grid> */}
              <Grid item xs={12} sm={5} lg={3}>
                Sasaran:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.price ? localeCurrency(formdata?.price) : ""}
              </Grid>
              <Grid item xs={12} sm={5} lg={3}>
                Agihan Semasa:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.current_collection
                  ? localeCurrency(formdata?.current_collection)
                  : 0}
              </Grid>
              {/* <Grid item xs={12} sm={5} lg={3}>
                Harga Jualan:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.sale_price ? formdata?.sale_price : ""}
              </Grid>
              <Grid item xs={12} sm={5} lg={3}>
                Cukai Jualan :
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.taxable ? formdata?.taxable : ""}
              </Grid> */}
              {/* <Grid item xs={12} sm={5} lg={3}>
                Status Produk:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.status ? formdata?.status : ""}
              </Grid>
              <Grid item xs={12} sm={5} lg={3}>
                Stok Produk:
              </Grid>
              <Grid item xs={12} sm={7} lg={9}>
                {formdata?.quantity ? formdata?.quantity : ""}
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            {formdata?.photos && formdata?.photos.length > 0 && (
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} sm={12}>
                  <div
                    style={{ backgroundImage: `${"url(" + activephoto + ")"}` }}
                    className="productthumbnail"
                  >
                    &nbsp;
                  </div>
                </Grid>
                {formdata?.photos.length > 1 &&
                  formdata?.photos.map((photo: any, idx: number) => (
                    <Grid
                      item
                      // xs={12}
                      sm={3}
                      md={formdata?.photos.length <= 1 ? 4 : 3}
                      key={idx}
                    >
                      <div
                        style={{ backgroundImage: `${"url(" + photo + ")"}` }}
                        className="productthumbnail"
                        onClick={() => setActivePhoto(photo)}
                      >
                        &nbsp;
                      </div>
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
      {formdata?.id && (
        <>
          <div
            style={{ display: "flex", marginTop: "20px", marginBottom: "10px" }}
          >
            <Typography variant="h6" component="h6">
              Penyumbang Terkini
            </Typography>
          </div>
          <Paper elevation={0} className={"papercontainer margintop"}>
            <LatestContributor id={formdata?.id} />
          </Paper>
        </>
      )}
    </>
  );
};

export default InfoPanel;
