import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export default function Tulus() {
  let { amount } = useParams();
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
      }}
      onClick={() => {
        navigate("/zakat/pendapatan/callback");
      }}
    >
      <img alt="tulus" src="/images/tulus.png" style={{ width: "100%" }} />
    </div>
  );
}

// function useStyles() {
//   throw new Error("Function not implemented.");
// }
