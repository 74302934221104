// import Grid from "@mui/material/Grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useJsApiLoader } from "@react-google-maps/api";
import { makeStyles } from "@material-ui/core/styles";
import { setTenant } from "./store/user";
import { setGmapIsLoaded } from "./store/gmap";
import AuthRoute from "./components/AuthRoute/Index";
import PublicRoute from "./components/PublicRoute/Index";
import { auth } from "./config/Firebase";
import Logging from "./config/Logging";
import CfgRoutes from "./config/Routes";
import Header from "./pages/Header";
// import NavItem from "./pages/Sidebar/NavItem/Index";
import TopNav from "./pages/Sidebar/NavItem/TopNav";
// import Dashboard from "./pages/Dashboard";
// import { Avatar, Typography } from "@mui/material";

// import Home from "./pages/Home";
import type { RootState } from "./store/index";
import "./App.css";

const curtheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: ["Nunito", "Roboto", "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    // marginTop: theme.spacing(8),
    // marginBottom: theme.spacing(4),
    // marginTop: "70px",
  },
  maincontainer: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 70px)",
    width: "100vw",
  },
  appbar: {
    width: "300px",
    height: "calc(100vh - 70px)",
    overflow: "auto",
    backgroundColor: "#f5f5f6",
    // color: "#ffffff",
  },
  maincontent: {
    backgroundColor: "##ffffff",
    height: "calc(100vh - 70px)",
    // overflow: "auto",
    width: "100%",
  },
  pad: {
    width: "calc(100vw - 300px)",
  },
  withpad: {
    width: "100vw",
  },
  container: {
    // padding: "20px",
    // overflow: "auto",
  },
  footer: {
    // position: "fixed",
    bottom: "0",
    minHeight: "100px",
    // width: "calc(100% -  20px)",
    padding: "10px",
    paddingTop: "20px",
    marginTop: "auto",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}));

export interface IApplicationProps {}

const libraries = ["marker"];

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<any>();
  const [open, setOpen] = React.useState(true);
  // const [tenant, setTenant] = React.useState<string>();
  const { profile, tenant } = useSelector(
    (state: RootState) => state.userState
  );
  const dispatch = useDispatch();

  const gmapkey = process.env["REACT_APP_GMAPKEY"];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: gmapkey ? gmapkey : "test",
    version: "beta",
    libraries,
  });

  React.useEffect(() => {
    if (typeof tenant?.id === "undefined") {
      // setTenant();
      fetch("/api/tenant")
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          dispatch(setTenant(data?.tenant));
          if (data?.tenant?.logo_small) {
            let link: any = document.querySelector("link[rel~='icon']");
            if (!link) {
              link = document.createElement("link");
              link.rel = "icon";
              document.getElementsByTagName("head")[0].appendChild(link);
            }
            link.href = data?.tenant?.favicon;
          }
          // setTenant();
        });
    }
  }, [tenant, dispatch]);

  React.useEffect(() => {
    if (isLoaded) {
      dispatch(setGmapIsLoaded(isLoaded));
    }
  }, [isLoaded, dispatch]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        Logging.info("User detected.");
      } else {
        Logging.info("No user detected");
      }

      setLoading(false);
    });
  }, []);

  const openCallback = (curopen?: any) => {
    setOpen(curopen);
  };

  if (loading) return <Spinner color="info" />;
  // const loginPath = "/login";
  let extra = " " + classes.withpad;
  if (typeof profile?.id !== "undefined") {
    extra = " " + classes.pad;
  }
  if (!open) {
    extra = " " + classes.withpad;
  }

  return (
    <ThemeProvider theme={curtheme}>
      <div style={{ height: "100vh", width: "100vw" }}>
        {!/\/login$/i.test(location.pathname) && tenant?.id && (
          <div
            style={{
              height: "70px",
              display: "flex",
              borderBottom: "1px solid #ffffff",
            }}
          >
            {open && (
              <div style={{ width: "300px" }}>
                <TopNav
                  logo={tenant?.logo_small ? tenant?.logo_small : ""}
                  themecolor={tenant?.theme_color ? tenant?.theme_color : ""}
                  fontcolor={
                    tenant?.theme_font_color ? tenant?.theme_font_color : ""
                  }
                  title={
                    tenant?.app_name
                      ? tenant?.app_name
                      : tenant?.name
                      ? tenant?.name
                      : ""
                  }
                  hidetitle={tenant?.logo_small_long > 0 ? true : false}
                />
              </div>
            )}
            <div className={!open ? extra : ""}>
              <Header openCallback={openCallback} />
            </div>
          </div>
        )}
        <div className={classes.maincontainer}>
          <div className={classes.maincontent}>
            <Routes>
              {CfgRoutes.map((route: any, index: any) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    // exact={route.exact}
                    element={
                      <>
                        {route.protected === "both" ? (
                          <PublicRoute>
                            {/* <div className={classes.container}> */}

                            <route.element user={user} />
                            {/* </div> */}
                          </PublicRoute>
                        ) : (
                          <>
                            {route.protected ? (
                              <AuthRoute>
                                <div className={classes.container}>
                                  <route.element user={user} />
                                </div>
                              </AuthRoute>
                            ) : (
                              <route.element user={user} />
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                );
              })}
            </Routes>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Application;
