import { Route, Routes, Outlet } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ShoppingCart from "../ShoppingCart";
import Orders from "../Orders";
import Default from "./Default";
import SideBar from "./SideBar";
import ListOfOrders from "./Orders";
import type { RootState } from "../../../store/index";

import "./index.css";
import RatingProduct from "./RatingProduct";
import Address from "./Address/Address";
import OrderInfo from "./OrderInfo";
import Contribution from "./Contribution";
import Zakat from "./Zakat";
// import { min } from "date-fns";
const MyAccount = () => {
  const { profile } = useSelector((state: RootState) => state.userState);

  console.log(profile);
  return (
    <div className="myaccount_container">
      <div className="myaccount_sidebar">
        <img
          src="/images/default.png"
          width={250}
          height={0}
          alt={profile?.staff_name}
          // style={{ width: "250px" }}
        />
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <div className="myaccount_profile">
              <Avatar
                alt={profile?.staff_name}
                src={
                  profile?.photo_url
                    ? profile?.photo_url
                    : "/static/images/avatar/1.jpg"
                }
                sx={{
                  width: 56,
                  height: 56,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />

              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {profile?.staff_name}
              </Typography>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                {profile?.contact_email}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <SideBar />
          </Grid>
        </Grid>
      </div>
      <div className="myaccount_content">
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {/* <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Hasil Carian
            </Typography>
          </Grid> */}
          <Grid item xs={12}>
            <Routes>
              <Route path="*" element={<Default />}></Route>
              <Route path="cart" element={<ShoppingCart />} />
              <Route path="orders" element={<ListOfOrders />} />
              <Route path="order/:id" element={<Orders />} />
              <Route path="orders/info/:id" element={<OrderInfo />} />
              <Route path="rating" element={<RatingProduct />} />
              <Route path="address" element={<Address />} />
              <Route path="contribution" element={<Contribution />} />
              <Route path="zakat" element={<Zakat />} />
            </Routes>
            <Outlet />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MyAccount;
