import React, { Fragment } from "react";

import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import MuiAlert from "@material-ui/lab/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

import { auth } from "../../config/Firebase";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Form {
  state?: string;
  district?: string;
  refid?: string;
  [key: string]: number | string | object | any;
}

interface PropsTotal {
  id?: string;
  data?: any;
  callback?: (data: any) => void;
}

const MenarikForm: React.FC<PropsTotal> = ({ id, data, callback }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [querystatus, setQuerystatus] = React.useState<string>("");
  const [token, setToken] = React.useState<any>("");

  const [provinsi, setProvinsi] = React.useState<any[]>();
  const [kota, setKota] = React.useState<any[]>();
  const [kecamatan, setKecamatan] = React.useState<any[]>();
  const [keluruhan, setKeluruhan] = React.useState<any[]>();
  const [postcode, setPostcode] = React.useState<any[]>();

  const [form, setForm] = React.useState<Form>({});

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof data !== "undefined") {
      if (typeof data !== "undefined") {
        setForm(data);
      }
    }
  }, [data]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/ref/provinsi", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setProvinsi(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof provinsi === "undefined" &&
      typeof token !== "undefined" &&
      token !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, provinsi]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/ref/kota/" + form?.provinsi_id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setKota(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof kota === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof form?.provinsi_id !== "undefined" &&
      form?.provinsi_id !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, kota, form]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/ref/keluruhan/" + form?.kecamatan_id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setKeluruhan(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof keluruhan === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof form?.kecamatan_id !== "undefined" &&
      form?.kecamatan_id !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, keluruhan, form]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/ref/postcode/" + form?.kecamatan_id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setPostcode(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof postcode === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof form?.kecamatan_id !== "undefined" &&
      form?.kecamatan_id !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, postcode, form]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/ref/kecamatan/" + form?.kota_id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setKecamatan(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof kecamatan === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof form?.kota_id !== "undefined" &&
      form?.kota_id !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, kecamatan, form]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event?.target?.id ? event?.target?.id : event?.target?.name]:
        event.target.value,
    });
  };

  const saveForm = () => {
    setLoading(true);
    const data = new URLSearchParams();
    // console.log(form);
    for (let f in form) {
      if (form[f] !== "" && form[f] !== null) {
        data.append(f, form[f]);
      }
    }

    if (form.name === "" || form.description === "" || form.code === "") {
      window.alert("Missing required fields.");

      setLoading(false);
      return false;
    }

    fetch("/api/agency/save", {
      method: "post",
      body: data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);

        setQuerystatus(data.metadata.status);
        if (data?.metadata?.status === "ERROR") {
        } else {
          if (data?.agency) {
            // console.log(data?.agency, form);
            let newform: any = { ...form };
            for (let n in form) {
              if (typeof data.agency[n] !== "undefined") {
                newform[n] = data.agency[n];
              }
            }
            if (typeof newform.id === "undefined") {
              newform.id = data.agency.id;
            }
            if (
              typeof data?.agency?.photourl !== "undefined" &&
              data?.agency?.photourl !== ""
            ) {
              newform.photourl = data?.agency?.photourl;
            }
            setForm(newform);
          }
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setQuerystatus("ERROR");
        setLoading(false);
      });
  };

  const gotoList = () => {
    if (callback) {
      callback(form);
    } else {
      navigate(`/dashboard/agency/`);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "pupuk") {
      setForm({ ...form, [event.target.name]: event.target.checked ? 1 : 0 });
    } else {
      setForm({ ...form, [event.target.name]: event.target.checked });
    }
  };

  const handleOptionChange = (event: SelectChangeEvent) => {
    // setAge(event.target.value);

    if (event.target.name === "provinsi_id") {
      setKota(undefined);
      setKecamatan(undefined);
      setForm({
        ...form,
        kota_id: "",
        kecamatan_id: "",
        keluruhan_id: "",
        postcode: "",
        [event.target.name]: event.target.value,
      });
    } else if (event.target.name === "kota_id") {
      setKecamatan(undefined);
      setKeluruhan(undefined);
      setPostcode(undefined);
      setForm({
        ...form,
        kecamatan_id: "",
        keluruhan_id: "",
        postcode: "",
        [event.target.name]: event.target.value,
      });
    } else if (event.target.name === "kecamatan_id") {
      setPostcode(undefined);
      setKeluruhan(undefined);
      setForm({
        ...form,
        keluruhan_id: "",
        postcode: "",
        [event.target.name]: event.target.value,
      });
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
    }
  };

  // console.log(data, form);
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {querystatus !== "" && (
        <Grid item xs={12} style={{ paddingBottom: "20px" }}>
          <Fragment>
            {querystatus === "NEW_DUPLICATE" && (
              <Alert severity="error">
                Query failed - Agency already registered.
              </Alert>
            )}
            {querystatus === "UPDATED" && (
              <Alert severity="success">Agency has been updated.</Alert>
            )}
            {querystatus === "SAVED" && (
              <Alert severity="success">Agency has been saved.</Alert>
            )}
            {querystatus === "ERROR" && (
              <Alert severity="error">Query failed - Unknown Reason.</Alert>
            )}
          </Fragment>
        </Grid>
      )}

      <Grid item xs={12} sm={8}>
        <FormControl fullWidth>
          <FormLabel>
            Name<i style={{ color: "red" }}>*</i>
          </FormLabel>
          <br></br>
          <TextField
            size="small"
            name="name"
            id="name"
            label=""
            value={form?.name ? form?.name : ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <FormLabel>Page ID</FormLabel>
          <br></br>
          <TextField
            size="small"
            name="pageid"
            id="pageid"
            label=""
            value={form?.pageid ? form?.pageid : ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <FormLabel>email</FormLabel>
          <br></br>
          <TextField
            size="small"
            name="email"
            id="email"
            label=""
            value={form?.email ? form?.email : ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <FormLabel>URL</FormLabel>
          <br></br>
          <TextField
            size="small"
            name="url"
            id="url"
            label=""
            value={form?.url ? form?.url : ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>
            Keterangan<i style={{ color: "red" }}>*</i>
          </FormLabel>
          <br></br>
          <TextField
            size="small"
            name="description"
            id="description"
            label=""
            multiline
            rows={4}
            value={form?.description ? form?.description : ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>Address</FormLabel>
          <br></br>
          <TextField
            size="small"
            name="address"
            id="address"
            label=""
            multiline
            rows={3}
            value={form?.address ? form?.address : ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        Provinsi:
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <Select
            name="provinsi_id"
            value={provinsi && form?.provinsi_id ? form?.provinsi_id : ""}
            onChange={handleOptionChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {provinsi &&
              provinsi.map((item: any, idx: any) => (
                <MenuItem value={item?.code} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        Kota/Kabupaten:
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <Select
            name="kota_id"
            value={kota && form?.kota_id ? form?.kota_id : ""}
            onChange={handleOptionChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {kota &&
              kota.map((item: any, idx: any) => (
                <MenuItem value={item?.code} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        Kecamatan:
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <Select
            name="kecamatan_id"
            value={kecamatan && form?.kecamatan_id ? form?.kecamatan_id : ""}
            onChange={handleOptionChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {kecamatan &&
              kecamatan.map((item: any, idx: any) => (
                <MenuItem value={item?.code} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        Postcode:
      </Grid>
      <Grid item xs={7}>
        Keluruhan:
      </Grid>
      <Grid item xs={5}>
        <FormControl fullWidth size="small">
          <Select
            name="postcode"
            value={postcode && form?.postcode ? form?.postcode : ""}
            onChange={handleOptionChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {postcode &&
              postcode.map((item: any, idx: any) => (
                <MenuItem value={item?.code} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <FormControl fullWidth size="small">
          <Select
            name="keluruhan_id"
            value={keluruhan && form?.keluruhan_id ? form?.keluruhan_id : ""}
            onChange={handleOptionChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {keluruhan &&
              keluruhan.map((item: any, idx: any) => (
                <MenuItem value={item?.code} key={idx}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl>
          {/* <FormLabel id="form-is-active">Status Agency</FormLabel> */}

          <FormControlLabel
            control={
              <Checkbox
                name="is_active"
                onChange={handleChange}
                checked={form?.is_active ? true : false}
              />
            }
            label={`Status Agency (${form?.is_active ? "ACTIVE" : "INACTIVE"})`}
          />
        </FormControl>
      </Grid>
      {/* 
      {form?.photourl && form?.photourl !== "" && (
        <Grid item xs={12} sm={12}>
          <img src={form?.photourl} alt="logo" style={{ width: "100%" }} />
        </Grid>
      )} */}
      <Grid item xs={12}>
        <label className="label">
          <i>
            Note: Fields marked with asterik (<i style={{ color: "red" }}>*</i>{" "}
            ) are compulsory fields.
          </i>
        </label>
      </Grid>
      <Grid item xs={12} sm={5} md={3}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => saveForm()}
          startIcon={<SaveIcon />}
          fullWidth
          disabled={loading ? true : false}
        >
          Save
        </Button>
      </Grid>
      {loading ? (
        <>
          <Grid item xs={12} sm={1} md={1} style={{ textAlign: "center" }}>
            <CircularProgress size={20} />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            {form?.id ? "Updating..." : "Saving..."}
          </Grid>
        </>
      ) : (
        <Fragment>
          <Grid item xs={12} sm={2} md={6}></Grid>
          <Grid item xs={12} sm={5} md={3}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => gotoList()}
              startIcon={<ListAltIcon />}
              fullWidth
              disabled={loading ? true : false}
            >
              Cancel
            </Button>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default MenarikForm;
