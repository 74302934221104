import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Logout from "./Logout";

interface DialogProps {
  open?: boolean;
  callback?: (data: any) => void;
}

const DialogPanel = (props: DialogProps) => {
  const { open, callback } = props;

  const handleClose = (status: boolean) => {
    //callback to calendar... placeholder for feuture

    if (status) {
      if (callback) {
        callback({ status: status });
      }
    } else {
      if (callback) {
        callback({ status: status });
      }
    }
  };

  const callbackLogout = (status: boolean) => {
    console.log(status);
    if (props?.callback) {
      props?.callback({ status: status });
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open || false}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogContent dividers>
        <Logout name="logout" callback={callbackLogout} />
      </DialogContent>
    </Dialog>
  );
};

export default DialogPanel;
