import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { auth } from "../../config/Firebase";

import AuthContainer from "../../components/AuthContainer/Index";

import IPageProps from "../../interfaces/Page";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "100px",
    height: "70px",
    borderRadius: "unset",
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  googlesignin: {
    margin: theme.spacing(1, 0, 2),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://www.akatselangor.com.my/">
        Lembaga Zakat Selangor
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const EmailVerificationPage: React.FunctionComponent<IPageProps> = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<any>("");

  const path: any = new URLSearchParams(location.search);
  const mode: string = path.get("mode");
  const oobCode: string = path.get("oobCode");
  const apiKey: string = path.get("apiKey");
  // const lang: string = path.get("lang");

  console.log(mode);

  React.useEffect(() => {
    if (oobCode !== "" && apiKey !== "") {
      setLoading(true);
      // auth.onAuthStateChanged((user) => {
      //   // if (user) {
      //   //   setUser(user);
      //   //   Logging.info("User detected.");
      //   // } else {
      //   //   Logging.info("No user detected");
      //   // }
      //   // setLoading(false);
      // });
      auth
        .applyActionCode(oobCode)
        .then((resp) => {
          console.log(resp);
          setLoading(false);
          setStatus("success");
          // auth.updateUser(currentUser.uid, {
          //   emailVerified: true,
          // });
          // Email address has been verified.
          // TODO: Display a confirmation message to the user.
          // You could also provide the user with a link back to the app.
          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          if (/been used/i.test(error?.message)) {
            setStatus("error");
          } else {
            setStatus("unknown error");
          }
        });
    }
  }, [oobCode, apiKey]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <AuthContainer header="">
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img src="/images/logo.png?v=1" alt="i3" height="100" />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img src="/images/pik-logo-small.png" alt="iHalal" width="120" />
            </Grid>
            {loading ? (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <CircularProgress /> Loading....
              </Grid>
            ) : (
              <>
                {status === "success" ? (
                  <>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      Your email has been verified
                      <p className="text-center">
                        You are now can sign in with your new account
                      </p>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          navigate(`/login`);
                        }}
                        fullWidth
                      >
                        Log In
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <span style={{ color: "red" }}>Unknown Error.</span>
                      <p className="text-center">
                        We are facing unknown error when processing your
                        request.
                      </p>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>

          <div className="text-center"></div>
        </AuthContainer>
      </div>
      <div style={{ padding: "25px" }}>
        <Copyright />
      </div>
    </Container>
  );
};

export default EmailVerificationPage;
