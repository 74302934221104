import React, { useState, useEffect } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import { auth } from "../../config/Firebase";
import type { RootState } from "../../store/index";

// import { useLocation } from "react-router-dom";

// import "./Index.module.css";

const StoreCart = ({ fontColor }: any) => {
  const [cartcount, setCartCount] = useState<number>(0);
  const [token, setToken] = useState<any>();
  // const { profile, tenant } = useSelector(
  //   (state: RootState) => state.userState
  // );
  const { cartstatus } = useSelector((state: RootState) => state.formState);

  // const location = useLocation();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setToken(token);
        });
      }
    });
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/apps/cart/count", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            // console.log("test: ", data);
            setCartCount(data?.total ? data?.total : 0);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (typeof token !== "undefined" && token !== "") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, cartstatus]);

  return (
    <>
      <Badge badgeContent={cartcount} color="error">
        <ShoppingCartIcon
          fontSize="inherit"
          style={{
            color: fontColor,
          }}
        />
      </Badge>
    </>
  );
};

export default StoreCart;
