import { Divider, Typography } from "@mui/material";

function RatingProduct() {
  return (
    <div>
      <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
        Rating Kempen
      </Typography>
      <Divider />
    </div>
  );
}

export default RatingProduct;
