import * as React from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
// import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import parse from "html-react-parser";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const mainads: any = {
  id: 9999,
  title:
    "<center><h1 style='font-size:500%; margin-block-start: 0em; margin-block-end: 0.0em;'><strong><font color='#FBDE01'>Crowd</font> Zakat</strong></h1></center>",
  content:
    "<center><h2>Crowd Zakat adalah satu cara yang inovatif untuk memudahkan penyumbang untuk memberi Zakat dengan lebih mudah dan berkesan</h2></center>",
  start_at: "2023-12-23 22:41:00",
  end_at: "2090-12-31 22:41:00",
  status: 1,
  created_at: "2023-12-28 04:11:05",
  updated_at: "2023-12-28 04:11:05",
  deleted_at: null,
  weightage: 10,
  tenant_id: "crowdzakat",
  photourl:
    "https://uoa-s3.tuxgeo.com/crowdzakat/assets/crowdzakat/public/images/3.png",
  input: true,
};

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [carousel, setCarousel] = React.useState<any[]>();
  const [find, setFind] = React.useState<any>("");

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      // setLoading(true);
      fetch("/api/public/advertisement/list")
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            data.advertisement.unshift(mainads);
            setCarousel(data.advertisement);
            // setCarousel([mainads]);
          }
        });
    };

    // console.log(id);
    if (typeof carousel === "undefined") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [carousel]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  // store/browse?q=van

  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        position: "relative",
        margin: "auto",
      }}
    >
      {typeof carousel !== "undefined" ? (
        <>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {carousel.map((step, index) => (
              <div key={index}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <div
                    className="storeheader"
                    style={{
                      backgroundImage: `url(${step.photourl})`,
                      height: 500,
                      display: "flex",
                      //   maxWidth: 1280,
                      overflow: "hidden",
                      width: "100vw",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      // border: "1px solid #ff0000",
                    }}
                  >
                    <div className="storeheadercontent">
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Grid item xs={12}>
                          {/\</i.test(step.title) && /\>/i.test(step.title) ? (
                            parse(step.title)
                          ) : (
                            <Typography
                              variant="h4"
                              style={{ fontWeight: "bold" }}
                            >
                              {parse(step.title)}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {/\</i.test(step.content) &&
                          /\>/i.test(step.content) ? (
                            parse(step.content)
                          ) : (
                            <Typography variant="body1">
                              {typeof step.content !== "undefined" &&
                              step.content !== "" &&
                              step.content !== null
                                ? parse(step.content)
                                : ""}
                            </Typography>
                          )}
                        </Grid>
                        {step?.input && (
                          <>
                            <Grid item xs={12} sm={5} md={4}>
                              <FormControl fullWidth size="small">
                                {/* <InputLabel htmlFor="outlined-adornment-amount">
                                Amount
                              </InputLabel> */}
                                <OutlinedInput
                                  id="outlined-adornment-amount"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  }
                                  sx={{
                                    backgroundColor: "#ffffff",
                                  }}
                                  placeholder="Kempen Zakat"
                                  value={find ? find : ""}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFind(event.target.value);
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2}>
                              <FormControl fullWidth size="small">
                                <Button
                                  variant="contained"
                                  sx={{ padding: "8px" }}
                                  onClick={() => {
                                    navigate(`/store/browse?q=${find}`);
                                  }}
                                >
                                  Cari
                                </Button>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <MobileStepper
              sx={{
                backgroundColor: "rgba(255,255,255,0.5)",
              }}
              steps={carousel ? carousel.length : 0}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === (carousel ? carousel.length : 0) - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default SwipeableTextMobileStepper;
