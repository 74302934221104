import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import type { RootState } from "../store/index";

// import Dashboard from "./Dashboard";
// import Store from "../pages/Store";
// import PusatInternet from "../components/PusatInternet";
// import PusatInternetByAgency from "../components/PusatInternet/ByAgency";
// import PusatInternetByProvider from "../components/PusatInternet/ByProvider";
const Home = (props: any) => {
  const navigate = useNavigate();
  const { profile } = useSelector((state: RootState) => state.userState);

  // console.log(profile);
  React.useEffect(() => {
    if (profile) {
      // history("/dashboard");
      if (/(administrator|admin)/i.test(profile?.position)) {
        navigate("/dashboard");
        // return <Dashboard />;
      } else if (/(manager|agency)/i.test(profile?.position)) {
        navigate("/dashboard");
        // return <Dashboard />;
      } else if (/(user)/i.test(profile?.position)) {
        navigate("/store");
        // return <Store />;
      } else {
        navigate("/store");
      }
    }
  }, [profile, navigate]);

  return (
    <>
      We cannot determine your access control level, Please contact
      administrator if you think this is an error.
    </>
  );
};

export default Home;
