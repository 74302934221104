import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";

import ProductCategory from "./ProductCategory";
import SelectedProduct from "./ProductSelected";
// import SvgMap from "./SvgMap";
import LatestContributor from "./LatestContributor";
import "./Index.css";
import { useNavigate } from "react-router-dom";

const StoreIndex = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        {/* <div style={{ display: "flex" }}>
          <div>
            <Typography
              variant="h5"
              style={{ fontWeight: "bold", marginTop: "30px" }}
            >
              Carian Kempen
            </Typography>
          </div>
          <div
            style={{
              alignItems: "flex-start",
              textAlign: "right",
              marginTop: "30px",
            }}
          >
            <IconButton
              style={{
                backgroundColor: "#15552F",
                color: "#FFFFFF",
                marginLeft: "20px",
              }}
              color="primary"
              aria-label="Browse"
              onClick={() => navigate("/store/browse")}
            >
              <SearchIcon />
            </IconButton>
          </div>
        </div> */}
        <ProductCategory />
        <SelectedProduct />
      </div>
      <div
        style={{
          marginTop: "20px",
          // padding: "20px",
          // backgroundColor: "#ffcc33",
          // position: "absolute",
          // left: 0,
          // right: 0,
        }}
      >
        <div
          style={{
            backgroundImage: `url(https://uoa-s3.tuxgeo.com/crowdzakat/assets/crowdzakat/public/images/bg.jpg)`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            padding: "50px",
            marginBottom: "-50px",
            marginLeft: "-10vw",
            paddingLeft: "12vw",
            marginRight: "-10vw",
            paddingRight: "12vw",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Typography
              variant="h2"
              sx={{
                color: "#FFD800",
                fontFamily: "Poppins",
              }}
            >
              <strong>Terima Kasih</strong>
            </Typography>
            <Typography
              variant="h5"
              className="productcategory_label"
              sx={{ color: "#ffffff" }}
            >
              Menyumbang Crowd Zakat
            </Typography>
          </div>
          <LatestContributor main={true} />
        </div>
      </div>
    </>
  );
};

export default StoreIndex;
