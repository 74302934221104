import React from "react";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

interface Type {
  [key: string]: number | string | object | any;
}

interface DialogProps {
  index?: any;
  open?: boolean;
  data?: Type;
  callback?: (data: Type) => void;
  user?: any;
}

const DialogPanel = (props: DialogProps) => {
  const { data } = props;

  let propinfo = [];
  for (let p in data) {
    if (
      !/^_/i.test(p) &&
      !/_id$/i.test(p) &&
      p !== "id" &&
      p !== "site_name" &&
      p !== "photourl" &&
      data[p] !== "" &&
      data[p] !== null
    ) {
      let label: string = p.replace(/_/g, " ");
      const check = p.split("");
      if (!check[1] || (check[1] && check[1] === check[1].toLowerCase())) {
        // label = label.charAt(0).toUpperCase() + label.slice(1);
        label = label
          // .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
      }
      propinfo.push({
        label: label,
        value: data[p],
      });
    }
  }
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <List dense={true}>
          {data &&
            propinfo.map((prop: any, idx: any) => (
              <ListItem key={idx}>
                <ListItemText primary={prop.label} secondary={prop.value} />
              </ListItem>
            ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default DialogPanel;
