import React from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";

import { auth } from "../../config/Firebase";

import Back from "../Back";
import Form from "../Forms/AgencyForm";
import UploadForm from "../Forms/Upload";
import Breadcrumbs from "../Breadcrumbs";
import type { RootState } from "../../store/index";

interface DialogProps {
  user?: any;
}

const AgencyForm = (props: DialogProps) => {
  const { id } = useParams();
  const { profile } = useSelector((state: RootState) => state.userState);

  const [newid, setNewId] = React.useState<string>();
  const [formdata, setFormData] = React.useState<any>();
  const [token, setToken] = React.useState<any>("");

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (
      typeof id === "undefined" &&
      typeof newid === "undefined" &&
      typeof profile !== "undefined" &&
      profile?.position === "MANAGER" &&
      profile?.agency_id !== ""
    ) {
      setNewId(profile?.agency_id);
    }
  }, [profile, id, newid]);

  React.useEffect(() => {
    if (typeof id !== "undefined" && typeof newid === "undefined") {
      setNewId(id);
    }
  }, [id, newid]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      // setLoading(true);
      fetch("/api/agency/detail/" + newid, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setFormData(data.agency);
            // setLoading(false);
          }
        });
    };

    if (
      typeof newid !== "undefined" &&
      newid !== "" &&
      newid !== "new" &&
      typeof formdata === "undefined" &&
      token !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, formdata, newid]);

  return (
    <>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <Back />
        <Typography variant="subtitle1" component="h6">
          Agency/Vendor Form
        </Typography>
      </div>
      <Breadcrumbs
        current={"Agency/Vendor Form"}
        paths={
          typeof id !== "undefined"
            ? [
                { name: "Pengurusan", url: "/dashboard" },
                {
                  name: "Agency/Vendor Manager",
                  url: "/dashboard/agency",
                },
              ]
            : [{ name: "Pengurusan", url: "/dashboard" }]
        }
        className="margintop"
      />
      <Paper elevation={0} className={"papercontainer margintop"}>
        <Grid
          container
          spacing={4}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={7}>
            <Form
              id={formdata?.id}
              data={formdata}
              // callback={dataList}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {formdata?.photourl && formdata?.photourl !== "" && (
                <Grid item xs={12} sm={12}>
                  <img
                    src={formdata?.photourl}
                    alt="logo"
                    style={{ width: "100%" }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                {token && (
                  <Paper
                    style={{
                      width: "100%",
                      height: "10vh",
                      minHeight: "200px",
                      border: "1px dashed #000000",
                      textAlign: "center",
                      verticalAlign: "middle",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <UploadForm
                      // id={formdata?.id}
                      callback={(status?: string, file?: string) => {
                        console.log(status, file);
                        if (status === "COMPLETED") {
                          setFormData({
                            ...formdata,
                            photourl: file,
                          });
                        }
                      }}
                      id={formdata?.id}
                      type={"agency"}
                      token={token}
                      name={formdata?.id}
                      maxsize={2} //mb
                    />
                  </Paper>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AgencyForm;
