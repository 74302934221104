import React from "react";
import { Link, useParams } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { green } from "@mui/material/colors";
// import { Headers, fetchPlus } from "./../../store/headers";
import Grid from "@mui/material/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@mui/icons-material/Add";
import LockPersonIcon from "@mui/icons-material/LockPerson";

import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "./../../store/index";
import { setSearch } from "./../../store/form";
import { IsGrantedAccess, AuthDisplay } from "../../config/Acl";
import { auth } from "../../config/Firebase";
import Back from "../Back";

import "./List.css";
// import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { Avatar } from "@mui/material";
// import Fade from "@mui/material/Fade";
// import Zoom from "@mui/material/Zoom";
// import SearchIcon from "@mui/icons-material/Search";
// import FilterListIcon from "@mui/icons-material/FilterList";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import Setting from "../NMS/Pengurusan/UserSetting";
// import { ExportCSV } from "./Export";
// import Stack from "@mui/material/Stack";
import Breadcrumbs from "../Breadcrumbs";

const useTableCellStyles = makeStyles((theme) => ({
  head: {
    // backgroundColor: "#e5e5e5",
    // color: theme.palette.common.white,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}));

const StyledTableCell = (props: any) => {
  const newclasses = useTableCellStyles();
  return <TableCell {...props} classes={newclasses} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      // minWidth: 500,
    },
    category: {
      marginTop: theme.spacing(1),
    },
    textField: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    badge: {
      marginLeft: theme.spacing(1),
    },
    badges: {
      position: "absolute",
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  user?: CfgUser;
}

interface CfgUser {
  id: number;
  agency: string;
  agency_name: string;
  contact_number: string;
  created_by: string;
  email: string;
  name: string;
  pi1m_refid: string;
  picture: string;
  position: string;
  site_name: string;
  staff_ic: string;
  state: string;
  status: number;
  user_id: string;
  [key: string]: number | string | object | any;
}

const CfgUserList = ({ user }: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { search } = useSelector((state: RootState) => state.formState);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  // const [filterList, setFilterList] = React.useState<string>();
  // const [data, setData] = React.useState([]);
  const [user_list, setCfgUserList] = React.useState<CfgUser[]>([]);
  const [token, setToken] = React.useState<any>("");
  const [pending, setPending] = React.useState<any>(false);
  // <{ group?: any }>
  let { group } = useParams();
  // console.log(file, uuid);
  // if (file && uuid) {
  //   removelabel = true;
  // }

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);
  // React.useEffect(() => {
  //   const fetchData = () => {
  //     axios.get("/api/user/list").then((r) => setData(r.data));
  //   };
  //   fetchData();
  // }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(event.currentTarget.value));
    setPage(0);
  };
  // const filterListCallback = (filter: any) => {
  //   if (filter !== filterList) {
  //     setFilterList(filter);
  //   }
  // };

  const UserStatus: any = {
    "0": "Pending",
    "1": "Approved",
    "2": "Revoked",
  };

  // fetch("/api/coverage/3.086356,101.586326/?apikey=testapikeyserver")
  //   .then((response) => response.json())
  //   .then((data) => {
  //     console.log(data);
  //   });

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch(
        "/api/user/list" +
          (typeof group !== "undefined" && group !== "" ? "/" + group : ""),
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            //console.log(data);
            if (data.users) {
              setCfgUserList(data.users);
            }
          }
        });
    };
    if (
      (typeof user_list === "undefined" || user_list.length <= 0) &&
      token !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [user_list, group, token]);

  let recordLength = user_list.length;
  let copy = user_list;
  if (search) {
    const keyword = search.toLowerCase();

    copy = user_list.filter((row: CfgUser) => {
      for (let r in row) {
        if (
          typeof row[r] !== "object" &&
          typeof row[r] !== "boolean" &&
          row[r] &&
          row[r].toString().toLowerCase().indexOf(keyword) >= 0
        ) {
          return row;
        }
      }

      return null;
    });
    recordLength = copy.length;
  }
  if (pending) {
    copy = copy.filter((row: CfgUser) => {
      if (
        typeof row?.status !== "undefined" &&
        (row?.status.toString() === "0" || row?.status.toString() === "Pending")
      ) {
        return true;
      } else {
        return false;
      }
    });
    recordLength = copy.length;
  }

  console.log(copy);

  const rows = copy.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const body = rows.map((row: any, i: number) => (
    <TableRow key={i.toString()} className="userlist">
      <StyledTableCell>
        <Typography variant="body2">{i + 1}.</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Avatar
          sx={{ bgcolor: "#1e3799" }}
          alt={row?.name.toUpperCase()}
          src={row?.picture}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2">
          <Link to={`/dashboard/user/form/${row.id}`}>
            {row.name.toUpperCase()}
          </Link>
          <br />
          {row.email}
        </Typography>
      </StyledTableCell>
      {/* <StyledTableCell>
        <Typography variant="body2">{row?.agency_name}</Typography>
      </StyledTableCell> */}
      <StyledTableCell>
        <Typography variant="body2">
          {row?.created_at
            ? format(new Date(row?.created_at), "yyyy-MM-dd HH:mm:00")
            : ""}
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="center" title={UserStatus[row.status]}>
        {row.status > 0 ? (
          <>
            {row.status > 1 ? (
              <LockPersonIcon style={{ color: "#ff0000" }} />
            ) : (
              <VerifiedUserIcon style={{ color: green[500] }} />
            )}
          </>
        ) : (
          <HourglassEmptyIcon color="secondary" />
        )}
      </StyledTableCell>
    </TableRow>
  ));

  if (!IsGrantedAccess("pikmembers")) {
    return <AuthDisplay />;
  } else {
    return (
      <>
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <Back />
          <Typography variant="subtitle1">Pengurusan Pengguna</Typography>
        </div>

        <Breadcrumbs
          current={"Pengurusan Pengguna"}
          paths={[
            { name: "Pengurusan", url: "/dashboard" },
            // { name: "Applications", url: "/documents/application" },
          ]}
          className="margintop"
        />

        <Paper elevation={0} className={"papercontainer margintop"}>
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} sm={7} md={8}>
              <FormControlLabel
                value={pending}
                control={
                  <Checkbox
                    onChange={() => {
                      setPending(!pending);
                    }}
                  />
                }
                label="Pending For Approval"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Tooltip title="">
                <TextField
                  fullWidth
                  label="Search"
                  // className={classes.textField}
                  placeholder="Find"
                  value={search}
                  onChange={onSearchChange}
                  variant="outlined"
                  margin="dense"
                  size="small"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={2} md={1} style={{ textAlign: "center" }}>
              <Fab
                // item
                xs={6}
                sm={7}
                style={{ textAlign: "right" }}
                size="small"
                color="secondary"
                // aria-label="Add New User"
                // title="Add New User"
                component={Link}
                to={`/user/form/new`}
                className={classes.margin}
              >
                <Tooltip title="Add New User">
                  <AddIcon />
                </Tooltip>
              </Fab>
            </Grid>
          </Grid>

          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow className="tableheader">
                <StyledTableCell colSpan={2}>User ID</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                {/* <StyledTableCell>Agency/Vendor</StyledTableCell> */}
                <StyledTableCell>Created</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{body}</TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  colSpan={8}
                  count={recordLength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </>
    );
  }
};

export default CfgUserList;
