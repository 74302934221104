import React from "react";
// import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import ErrorText from "../../components/ErrorText/Index";
// import { auth } from "../../config/Firebase";
// import logging from "../../config/Logging";
import IPageProps from "../../interfaces/Page";
// import firebase from "firebase";
// import firebase from "firebase/app";
// import "firebase/auth";

// import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import Typography from "@material-ui/core/Typography";
// import { makeStyles } from "@material-ui/core/styles";
// import Icon from "@material-ui/core/Icon";
import type { RootState } from "../../store/index";
import LoginForm from "./LoginForm";

// import SaveIcon from "@mui/icons-material/Save";

// import Container from "@material-ui/core/Container";
// import PediLoginImg from "../../Asset/Images/PEDi.png";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"Copyright © "}
//       <a href="https://www.akatselangor.com.my/" target="_blank" rel="noreferrer">
//         Lembaga Zakat Selangor
//       </a>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     width: "120px",
//     height: "100px",
//     borderRadius: "unset",
//     // backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 0),
//   },
//   adlogin: {
//     margin: theme.spacing(1, 0, 0),
//     backgroundColor: "#EF4F91",
//   },
//   googlesignin: {
//     margin: theme.spacing(1, 0, 2),
//     // backgroundColor: "#e5e5e5",
//     // borderRadius: "15px",
//     color: "#ffffff",
//     textTransform: "unset",
//     lineHeight: "unset",
//   },
// }));

// export default function SignIn() {
const LoginPage: React.FunctionComponent<IPageProps> = (props) => {
  // const classes = useStyles();
  // const navigate = useNavigate();
  // const [authenticating, setAuthenticating] = useState<boolean>(false);
  // const [email, setEmail] = useState<string>("");
  // const [password, setPassword] = useState<string>("");
  // const [error, setError] = useState<string>("");
  const { tenant } = useSelector((state: RootState) => state.userState);

  // const signInWithEmailAndPassword = (
  //   event: React.ChangeEvent<HTMLFormElement>
  // ) => {
  //   event.preventDefault();
  //   if (typeof email !== "undefined" && password !== "") {
  //     if (error !== "") setError("");

  //     setAuthenticating(true);

  //     auth
  //       .signInWithEmailAndPassword(email, password)
  //       .then((result) => {
  //         // logging.info(result);
  //         logging.error("Authentication Success!");
  //         navigate("/");
  //       })
  //       .catch((error) => {
  //         // logging.error(error);
  //         logging.error(email + "Authentication fail!");
  //         setAuthenticating(false);
  //         setError(error.message);
  //       });
  //   } else if (typeof email !== "undefined" && password === "") {
  //     if (auth.isSignInWithEmailLink(window.location.href)) {
  //       auth
  //         .signInWithEmailLink(email, window.location.href)
  //         .then((result) => {
  //           logging.info(result);
  //           navigate("/");
  //         })
  //         .catch((error) => {
  //           logging.error(error);
  //           setAuthenticating(false);
  //           setError(error.message);
  //         });
  //     } else {
  //     }
  //   }
  // };

  // const signInWithProvider = (provider: any) => {
  //   setAuthenticating(true);
  //   // console.log(auth);
  //   if (typeof auth.signInWithPopup !== "undefined") {
  //     auth
  //       .signInWithPopup(provider)
  //       .then((result) => {
  //         logging.info(result);
  //         navigate("/");
  //       })
  //       .catch((error) => {
  //         logging.error(error);
  //         setAuthenticating(false);
  //         setError(error.message);
  //       });
  //   }
  // };

  // const adLogin = () => {
  //   let url = "";
  //   // console.log(url);
  //   if (/(localhost|127.0.0.1|172.16.1.62)/i.test(window.location.host)) {
  //     url = "https://pik.tuxgeo.dev";
  //   }
  //   // console.log(url);
  //   window.open(
  //     url + "/login/adfs/signin",
  //     "geocommlogin",
  //     "height=600,width=500"
  //   );
  // };

  // const handleMessage = (e: any) => {
  //   if (e?.data?.token) {
  //     auth
  //       .signInWithCustomToken(e.data.token)
  //       .then((result) => {
  //         logging.info(result);
  //         navigate("/");
  //       })
  //       .catch((error) => {
  //         logging.error(error);
  //         setAuthenticating(false);
  //         setError(error.message);
  //       });
  //   }
  // };

  // window.addEventListener("message", handleMessage);

  // console.log(tenant);
  return (
    <div
      style={{
        // border: "1px solid #ff0000",
        height: "100vh",
        width: "100vw",
        position: "absolute",
        padding: "0",
        margin: "0",
        top: "0",
        left: "0",
        // overflow: "hidden",
      }}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        style={{
          // border: "1px solid #ff0000",
          height: "100vh",
          width: "100vw",
          margin: "0",
          backgroundColor: "#F8F8F8",
        }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          style={{
            backgroundImage: `url("${
              tenant?.login_bg ? tenant?.login_bg : "/images/si-login-bg.png"
            }")`,
            backgroundRepeat: "no-repeat",
            // backgroundAttachment: "fixed",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          &nbsp;
        </Grid>
        <Grid item xs={12} sm={4}>
          <div
            style={{
              width: "80%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            {/* <Container component="main" maxWidth="xs"> */}

            <div
              // className={classes.paper}
              style={{
                maxWidth: "500px",
              }}
            >
              <LoginForm tenant={tenant} />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
