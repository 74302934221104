import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Button from "@material-ui/core/Button";

export default function Tulus() {
  // let { amount } = useParams();
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        backgroundColor: "#ffffff",
      }}
      onClick={() => {
        navigate("/zakat/pendapatan/callback");
      }}
    >
      <Paper
        style={{
          maxWidth: "450px",
          marginTop: "10vh",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "20px",
        }}
        elevation={5}
      >
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Alert severity="success">
              <strong>SUCCESSFUL</strong>
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <List>
              <ListItem>
                <ListItemText
                  primary="Tarikh/Masa"
                  secondary={new Date().toLocaleString()}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Trans Id"
                  secondary="2201023012348782164"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Seller Order No"
                  secondary="LZS834928742"
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Bank Name" secondary="MAYBANK2U" />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Agency Name"
                  secondary="Lembaga Zakat Selangor (LZS)"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Description"
                  secondary="Lembaga Zakat Selangor (LZS) : Zakat Pedapatan"
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Amount <strong>RM 2,350</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              // component={Link}
              // to={"/store/myaccount"}
              onClick={() => {
                console.log("sssss");
                // return navigate("/store/myaccount");
                window.location.href = "/store/myaccount/zakat";
              }}
              fullWidth
            >
              Kembali
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

// function useStyles() {
//   throw new Error("Function not implemented.");
// }
