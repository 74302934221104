import IRoute from "../interfaces/Route";

import ChangePasswordPage from "../pages/auth/Change";
import ForgotPasswordPage from "../pages/auth/Forgot";
import LoginPage from "../pages/auth/Login";
import LogoutPage from "../pages/auth/Logout";
import RegisterPage from "../pages/auth/Register";
import RegisterPageEmail from "../pages/auth/RegisterEmail";
import RegisterPageEmailConfirmation from "../pages/auth/RegisterEmailConfirmation";
import EmailVerification from "../pages/auth/EmailVerification";
import ResetPasswordPage from "../pages/auth/Reset";
import FormPage from "../pages/Form";
import HomePage from "../pages/Home";

// import Incident from "../components/Incident";
// import Feedback from "../components/Feedback";
// import Dashboard from "../components/Dashboard";
import Dashboard from "../pages/Dashboard";

// import AgencyManager from "../components/Agency";
// import AgencyForm from "../components/Agency/Form";

import UserList from "../components/CfgUser/List";
import UserForm from "../components/CfgUser/Form";
import AclForm from "../components/CfgUser/Acl";
import ModulePage from "../pages/Module";

// import ProductCategoryManager from "../components/ProductCategory";
// import ProductCategoryForm from "../components/ProductCategory/Form";
// import ProductManager from "../components/Product";
// import AdvertisementManager from "../components/Advertisement";
// import ProductForm from "../components/Product/Form";
// import ProductInfo from "../components/Product/Info";

// import AgencyManager from "../components/CfgAgency";
// import AgencyForm from "../components/CfgAgency/Form";
// import DashboardRefresh from "../components/Dashboard/Refresh";
// import DocumentTraining from "../components/Document/Training";
// import DocumentTrainingInfo from "../components/Document/Training/Info";
// import DocumentTrainingForm from "../components/Document/Training/Form";
// import DocumentParticipant from "../components/Document/Participant";
// import DocumentApplication from "../components/Document/Application";
// import DocumentApplicationInfo from "../components/Document/Application/Info";
// import DocumentTransaction from "../components/Document/Transaction";
// import DocumentTransactionForm from "../components/Document/Transaction/Form";
// import DocumentTransactionQuery from "../components/Document/Transaction/Query";

import Test from "../components/Test";

import StoreIndex from "../pages/Store";
import Tulus from "../components/ZakatForm/Pendapatan/Tulus";
import Callback from "../components/ZakatForm/Pendapatan/Callback";
{
  /* <Route path="tulus" element={<Tulus />} /> */
}
// const ActivityIcon = require("../Asset/icons/activity_programmes.svg");

const Routes: IRoute[] = [
  {
    path: "/",
    exact: true,
    element: HomePage,

    name: "Laman Utama",
    module: true,
    protected: "both",
  },
  {
    path: "/tulus",
    exact: true,
    element: Tulus,

    name: "Laman Tulus",
    module: true,
    protected: "false",
  },
  {
    path: "/zakat/pendapatan/callback",
    exact: true,
    element: Callback,

    name: "Laman Tulus Callback",
    module: true,
    protected: "false",
  },
  {
    path: "/form",
    exact: false,
    element: FormPage,

    name: "Form Page",
    module: true,
    protected: true,
  },
  {
    path: "/register",
    exact: true,
    element: RegisterPage,

    name: "Register Page",
    module: true,
    protected: false,
  },
  {
    path: "/register/email",
    exact: true,
    element: RegisterPageEmail,

    name: "Register e-mail Page",
    module: true,
    protected: false,
  },
  {
    path: "/register/confirmation",
    exact: true,
    element: RegisterPageEmailConfirmation,

    name: "Register e-mail Page Confirmation",
    module: true,
    protected: false,
  },
  {
    path: "/register/verify",
    exact: false,
    element: () => {
      const location = window.location;
      const path: any = new URLSearchParams(location.search);
      const mode: string = path.get("mode");
      // console.log(mode);
      if (/resetPassword/i.test(mode)) {
        return <ResetPasswordPage name="reset" />;
      } else {
        return <EmailVerification name="emailverify" />;
      }
    },
    name: "Verification e-mail Page",
    module: true,
    protected: false,
  },
  {
    path: "/login",
    exact: true,
    element: LoginPage,

    name: "Login Page",
    module: true,
    protected: false,
  },
  {
    path: "/change",
    exact: true,
    element: ChangePasswordPage,

    name: "Change Password Page",
    module: true,
    protected: true,
  },
  {
    path: "/logout",
    exact: true,
    element: LogoutPage,

    name: "Logout Page",
    module: true,
    protected: true,
  },
  {
    path: "/forget",
    exact: true,
    element: ForgotPasswordPage,

    name: "Forgot Password Page",
    module: true,
    protected: false,
  },
  {
    path: "/reset",
    exact: true,
    element: ResetPasswordPage,

    name: "Reset Password Page",
    module: true,
    protected: false,
  },
  {
    path: "/user/list",
    exact: true,
    element: UserList,

    name: "User List",
    module: true,
    protected: true,
  },
  {
    path: "/user/acl",
    exact: true,
    element: AclForm,

    name: "User ACL",
    module: true,
    protected: true,
  },
  {
    path: "/user/form/:id",
    exact: true,
    element: UserForm,

    name: "User Form",
    module: true,
    protected: true,
  },
  {
    path: "/module",
    exact: true,
    element: ModulePage,

    name: "PEDi Modules",
    module: true,
    protected: true,
  },
  {
    path: "/dashboard/*",
    exact: true,
    element: Dashboard,
    name: "Dashboard",
    module: true,
    protected: true,
  },
  {
    path: "/dev/mbek/test",
    exact: true,
    element: Test,

    name: "Test",
    module: false,
    protected: true,
  },
  // {
  //   path: "/dashboard/refresh",
  //   exact: true,
  //   element: DashboardRefresh,
  //
  //   name: "Test",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/documents/participant",
  //   exact: true,
  //   element: DocumentParticipant,

  //   name: "Test",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/documents/application",
  //   exact: true,
  //   element: DocumentApplication,

  //   name: "Test",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/documents/application/:id",
  //   exact: true,
  //   element: DocumentApplicationInfo,

  //   name: "Test",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/documents/training",
  //   exact: true,
  //   element: DocumentTraining,

  //   name: "Test",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/documents/training/new",
  //   exact: true,
  //   element: DocumentTrainingForm,

  //   name: "Training Form",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/documents/training/:id",
  //   exact: true,
  //   element: DocumentTrainingInfo,

  //   name: "Training Info",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/documents/training/:id/edit",
  //   exact: true,
  //   element: DocumentTrainingForm,

  //   name: "Training Form",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/documents/training/:id/participant",
  //   exact: true,
  //   element: DocumentParticipant,

  //   name: "Training Participant",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/documents/transaction",
  //   exact: true,
  //   element: DocumentTransaction,

  //   name: "Test",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/transaction/invoice/form",
  //   exact: true,
  //   element: DocumentTransactionForm,

  //   name: "Test",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/transaction/invoice/query",
  //   exact: true,
  //   element: DocumentTransactionQuery,

  //   name: "Test",
  //   module: false,
  //   protected: true,
  // },
  // {
  //   path: "/dashboard/agency",
  //   exact: true,
  //   element: AgencyManager,

  //   name: "Agency Management",
  //   module: true,
  //   protected: true,
  // },
  // {
  //   path: "/dashboard/agency/:id",
  //   exact: true,
  //   element: AgencyForm,

  //   name: "Agency Form",
  //   module: true,
  //   protected: true,
  // },
  // {
  //   path: "/dashboard/category",
  //   exact: true,
  //   element: ProductCategoryManager,

  //   name: "Product Category Management",
  //   module: true,
  //   protected: true,
  // },
  // {
  //   path: "/dashboard/category/:id",
  //   exact: true,
  //   element: ProductCategoryForm,

  //   name: "Product Category Form",
  //   module: true,
  //   protected: true,
  // },
  // {
  //   path: "/dashboard/product",
  //   exact: true,
  //   element: ProductManager,

  //   name: "Products Management",
  //   module: true,
  //   protected: true,
  // },
  // {
  //   path: "/dashboard/product/category/:id",
  //   exact: true,
  //   element: ProductManager,

  //   name: "Products Management",
  //   module: true,
  //   protected: true,
  // },
  // {
  //   path: "/dashboard/product/:id",
  //   exact: true,
  //   element: ProductInfo,

  //   name: "Products Info",
  //   module: true,
  //   protected: true,
  // },
  // {
  //   path: "/dashboard/product/form/:id",
  //   exact: true,
  //   element: ProductForm,

  //   name: "Products Form",
  //   module: true,
  //   protected: true,
  // },
  {
    path: "/store/*",
    exact: false,
    element: StoreIndex,

    name: "Products Store",
    module: true,
    protected: "both",
  },
  // {
  //   path: "/dashboard/advertisement/*",
  //   exact: false,
  //   element: AdvertisementManager,

  //   name: "Advertisement",
  //   module: true,
  //   protected: true,
  // },
];

export default Routes;
