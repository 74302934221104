import React from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { Link } from "react-router-dom";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import UnitButton from "./UnitButton";
// import { auth } from "../../config/Firebase";
import type { RootState } from "../../store/index";
import { localeCurrency } from "./Store";
import { Rating } from "@mui/material";
import Button from "@mui/material/Button";

const StoreProduct = ({ product, callback, rating }: any) => {
  const { profile } = useSelector((state: RootState) => state.userState);

  // React.useEffect(() => {
  //   auth.onAuthStateChanged((user: any) => {
  //     if (user) {
  //       if (typeof user !== "undefined") {
  //         user.getIdToken().then((e: any) => {
  //           setToken(e);
  //         });
  //       }
  //     } else {
  //       console.log("No user detected");
  //     }
  //   });
  // }, []);

  const addToCart = (x: any) => {
    if (x > 0) {
      if (profile?.id) {
        console.log(x);
      } else {
        console.log("login");
      }
      console.log(profile);
      // addProductToCart(x);
    }
  };

  const randomIntFromInterval = (min: number, max: number) => {
    // min and max included
    min = min * 10;
    max = max * 10;
    const rand = Math.floor(Math.random() * (max - min + 1) + min) / 10;
    console.log(rand);
    return rand;
  };

  // const addProductToCart = (x: any) => {
  //   console.log(x);
  // };

  if (typeof product?.current_collection === "undefined") {
    const rand = randomIntFromInterval(0, 1);
    product.current_collection = rand * (product?.price - 0);
    product.current_percentage = rand * 100;
    console.log(product);
  } else {
    product.current_percentage =
      (product.current_collection / product?.price) * 100;
  }

  return (
    <div className="product_container">
      <Grid
        container
        spacing={1}
        padding={1}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        sx={{ height: "100%" }}
      >
        <Grid item xs={12}>
          <div
            className="product_thumbnail"
            style={{
              backgroundImage: `${
                "url(" +
                (typeof product?.photos !== "undefined" &&
                product?.photos.length > 0
                  ? product?.photos[0]
                  : "/images/default.png") +
                ")"
              }`,
            }}
            onClick={() => {
              if (typeof callback !== "undefined") {
                callback(product);
              }
            }}
          >
            &nbsp;
          </div>
          <div className="product_info_container">
            <div className="product_info_category_container">
              <Typography
                variant="caption"
                component={Link}
                to={"/store/browse/category/" + product?.category_id}
                style={{ textDecoration: "none" }}
              >
                {product?.category_name}
              </Typography>
            </div>
            <div style={{ minHeight: "40px" }}>
              <Typography variant="body2">
                <strong>{product?.name}</strong>
              </Typography>
            </div>
            <div style={{ minHeight: "60px" }}>
              <Typography variant="body2">
                {product?.short_description}
              </Typography>
            </div>
            {/* <div>
          <Rating
            defaultValue={product.rating ? product.rating : 0}
            precision={0.1}
            readOnly
          />
        </div> */}
            {/* <div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={1}>
              <StorefrontIcon fontSize="inherit" />
            </Grid>
            <Grid item xs={11}>
              <Typography
                variant="body2"
                component={Link}
                to={"/store/page/" + product?.agency_id}
                style={{ textDecoration: "none" }}
              >
                {product?.category_name}
              </Typography>
            </Grid>
          </Grid>
        </div> */}
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Typography
                    variant="subtitle1"
                    className="productcategory_label"
                  >
                    {/* {localeCurrency(product?.price.toFixed(0))} */}
                    {localeCurrency(
                      product?.current_collection
                        ? product?.current_collection
                        : 0
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress
                      variant="determinate"
                      value={
                        product?.current_percentage
                          ? product?.current_percentage
                          : 0
                      }
                      color={
                        product?.current_percentage <= 0 ? "error" : "success"
                      }
                      sx={{
                        height: "10px",
                        strokeLinecap: "round",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Typography variant="caption">
                    Perlukan Agihan{" "}
                    {localeCurrency(product?.price ? product?.price - 0 : 0)}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12}>

                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    component={Link}
                    to={"/store/product/" + product?.id}
                  >
                    Butiran Kempen
                  </Button>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sx={{}}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            component={Link}
            to={"/store/product/" + product?.id}
          >
            Butiran Kempen
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default StoreProduct;
