import {
  Box,
  Collapse,
  // Divider,
  List,
  // ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
// import { forwardRef, , useState } from "react";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import CachedIcon from "@mui/icons-material/Cached";
import CampaignIcon from "@mui/icons-material/Campaign";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
// import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
// import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
// import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
// import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
// import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
// import PeopleIcon from "@mui/icons-material/People";
// import SettingsInputAntennaRoundedIcon from "@mui/icons-material/SettingsInputAntennaRounded";
// // import ViewModuleRoundedIcon from "@mui/icons-material/ViewModuleRounded";
// import StatisticsIcon from "@mui/icons-material/BarChartRounded";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import MonitorIcon from "@mui/icons-material/Monitor";
// import PersonIcon from "@mui/icons-material/Person";
import Badge from "@mui/material/Badge";
import { auth } from "../../../config/Firebase";
import type { RootState } from "../../../store/index";
// import Grid from "@mui/material/Grid";
// import { Grid, Typography } from "@mui/material";

// import InboxIcon from "@mui/icons-material/Inbox";
// import DraftsIcon from "@mui/icons-material/Drafts";

import { Link, useLocation } from "react-router-dom";
// import LogoutIcon from "@mui/icons-material/Logout";
// import TopNav from "./TopNav";
// import Classes from "./Index.module.css";
// import MuiListItem from "@material-ui/core/ListItem";
// import withStyles from "@material-ui/core/styles/withStyles";
import { styled } from "@mui/material/styles";
// import { GroupAdd } from "@mui/icons-material";

// import { GroupAdd } from "@mui/icons-material";

import "./Index.module.css";

const NavItem = () => {
  // const [open, setOpen] = useState(true);
  const [pendingapproval, setPendingApproval] = useState<any>(0);
  const [token, setToken] = useState<any>();
  // const [opentransaction, setOpenTransaction] = useState(true);
  const [openadmin, setOpenAdmin] = useState(false);
  // const [opensp, setOpenSp] = useState(false);
  // const classes = useStyles();
  // const [selectedIndex, setSelectedIndex] = useState(true);
  // const handleListItemClick = () => setSelectedIndex(!selectedIndex);
  // const handleListItemClick = (index: number) => {
  //   setSelectedIndex(index);
  // };
  const { profile, tenant } = useSelector(
    (state: RootState) => state.userState
  );
  const { pendingstatus } = useSelector((state: RootState) => state.formState);

  const location = useLocation();

  const colorShade = (col: any, amt: any) => {
    col = col.replace(/^#/, "");
    if (col.length === 3)
      col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

    let [r, g, b] = col.match(/.{2}/g);
    [r, g, b] = [
      parseInt(r, 16) + amt,
      parseInt(g, 16) + amt,
      parseInt(b, 16) + amt,
    ];

    r = Math.max(Math.min(255, r), 0).toString(16);
    g = Math.max(Math.min(255, g), 0).toString(16);
    b = Math.max(Math.min(255, b), 0).toString(16);

    const rr = (r.length < 2 ? "0" : "") + r;
    const gg = (g.length < 2 ? "0" : "") + g;
    const bb = (b.length < 2 ? "0" : "") + b;

    return `#${rr}${gg}${bb}`;
  };

  const StyledList = styled(List)({
    // hover states
    "& .MuiListItemButton-root": {
      // backgroundColor: "#ffffff",
      "&, & .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    // selected and (selected + hover) states
    "&& .Mui-selected, && .Mui-selected:hover": {
      borderRadius: "4px",
      backgroundColor: tenant?.theme_color ? tenant?.theme_color : "#F5F7F9",
      "&, & .MuiListItemIcon-root": {
        color: tenant?.theme_font_color ? tenant?.theme_font_color : "#e5e5e5",
      },
    },
    // hover states
    "& .MuiListItemButton-root:hover": {
      borderRadius: "4px",
      backgroundColor: tenant?.theme_color
        ? colorShade(tenant?.theme_color, -20)
        : "#eeeeee",
      "&, & .MuiListItemIcon-root": {
        color: tenant?.theme_font_color ? tenant?.theme_font_color : "#333333",
      },
    },
  });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setToken(token);
        });
      }
    });
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/user/approval/status", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            // console.log("test: ", data);
            setPendingApproval(data?.total ? data?.total : 0);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (typeof token !== "undefined" && token !== "") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, pendingstatus]);

  const checkPermission = (mod: string) => {
    // console.log(profile);
    if (mod === "administration") {
      if (/mcmc|admin|provider/i.test(profile?.position)) {
        return true;
      } else {
        return false;
      }
    }
    if (mod === "admin") {
      if (/mcmc|admin/i.test(profile?.position)) {
        return true;
      } else {
        return false;
      }
    }
    if (mod === "admin_manager") {
      if (/mcmc|admin|provider/i.test(profile?.position)) {
        return true;
      } else {
        return false;
      }
    }

    if (mod === "manager") {
      if (/manager$/i.test(profile?.position)) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          // border: "1px solid #ff0000",
          width: "100%",
          maxWidth: 360,
          overflowY: "auto",
          fontWeight: "bolder",
          position: "relative",
          heifght: "100%",
          maxHeight: "calc(100vh - 200px)",
        }}
      >
        <StyledList subheader={<ListSubheader component="div"></ListSubheader>}>
          <ListItemButton
            component={Link}
            to={"/dashboard"}
            selected={location.pathname === "/dashboard"}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          {checkPermission("administration") && (
            <>
              {/* <ListItemButton
                component={Link}
                to={"/dashboard/agency"}
                selected={location.pathname.includes("/dashboard/agency")}
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Agen/Penjual" />
              </ListItemButton> */}
              <ListItemButton
                component={Link}
                to={"/dashboard/category"}
                selected={location.pathname.includes("/dashboard/category")}
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Kategori" />
              </ListItemButton>
            </>
          )}
          <ListItemButton
            component={Link}
            to={"/dashboard/product"}
            selected={location.pathname.includes("/dashboard/product")}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Kempen" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to={"/dashboard/contribution"}
            selected={location.pathname.includes("/dashboard/contribution")}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Agihan" />
          </ListItemButton>
          {/* <ListItemButton
            component={Link}
            to={"/dashboard/orders"}
            selected={location.pathname.includes("/dashboard/orders")}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Pesanan" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to={"/dashboard/sales"}
            selected={location.pathname.includes("/dashboard/sales")}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Jualan" />
          </ListItemButton> */}

          {checkPermission("manager") && (
            <>
              <ListItemButton
                // sx={{ pl: 5 }}
                component={Link}
                to={"/dashboard/user/list"}
                selected={location.pathname.includes("/dashboard/user/list")}
              >
                <ListItemIcon>
                  <PersonRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Badge badgeContent={pendingapproval} color="error">
                      Manager &nbsp;
                    </Badge>
                  }
                />
              </ListItemButton>
              <ListItemButton
                component={Link}
                to={"/dashboard/agency/profile/update"}
                selected={location.pathname.includes(
                  "/dashboard/agency/profile/update"
                )}
              >
                <ListItemIcon>
                  <StoreIcon />
                </ListItemIcon>
                <ListItemText primary="Profil Toko/Agency" />
              </ListItemButton>
            </>
          )}
          {/* <>
            <ListItemButton onClick={() => setOpenSp(!opensp)}>
              <ListItemIcon>
                <SettingsInputAntennaRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Membership" />
              {opensp ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={opensp} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 5 }}
                  component={Link}
                  to={"/documents/application"}
                  selected={location.pathname.includes(
                    "/documents/application"
                  )}
                >
                  <ListItemIcon>
                    <PersonRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Applications" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 5 }}
                  component={Link}
                  to={"/documents/training"}
                  selected={location.pathname.includes("/documents/training")}
                >
                  <ListItemIcon>
                    <StatisticsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Trainings" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 5 }}
                  component={Link}
                  to={"/documents/participant"}
                  selected={location.pathname.includes(
                    "/documents/participant"
                  )}
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Participants" />
                </ListItemButton>
              </List>
            </Collapse>
          </> */}

          {checkPermission("administration") && (
            <>
              <ListItemButton onClick={() => setOpenAdmin(!openadmin)}>
                <ListItemIcon>
                  <ManageAccountsRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Pengurusan" />
                {openadmin ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openadmin} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {checkPermission("admin_manager") && (
                    <>
                      <ListItemButton
                        sx={{ pl: 5 }}
                        component={Link}
                        to={"/dashboard/user/list"}
                        selected={location.pathname.includes(
                          "/dashboard/user/list"
                        )}
                      >
                        <ListItemIcon>
                          <PersonRoundedIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Badge badgeContent={pendingapproval} color="error">
                              Pengguna &nbsp;
                            </Badge>
                          }
                        />
                      </ListItemButton>

                      {/* <ListItemButton
                        sx={{ pl: 5 }}
                        component={Link}
                        to={"/dashboard/agency-setting"}
                        selected={location.pathname.includes(
                          "/dashboard/agency-setting"
                        )}
                      >
                        <ListItemIcon>
                          <GroupAdd />
                        </ListItemIcon>
                        <ListItemText primary="Agencies" />
                      </ListItemButton> */}
                    </>
                  )}
                  {checkPermission("admin") && (
                    <>
                      <ListItemButton
                        sx={{ pl: 5 }}
                        component={Link}
                        to={"/dashboard/user/acl"}
                        selected={location.pathname.includes(
                          "/dashboard/user/acl"
                        )}
                      >
                        <ListItemIcon>
                          <AdminPanelSettingsRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tahap Capaian" />
                      </ListItemButton>
                    </>
                  )}
                  {checkPermission("admin") && (
                    <>
                      <ListItemButton
                        sx={{ pl: 5 }}
                        component={Link}
                        to={"/dashboard/advertisement"}
                        selected={location.pathname.includes(
                          "/dashboard/advertisement"
                        )}
                      >
                        <ListItemIcon>
                          <CampaignIcon />
                        </ListItemIcon>
                        <ListItemText primary="Iklan" />
                      </ListItemButton>
                    </>
                  )}
                  {/* {checkPermission("admin") && (
                    <>
                      <ListItemButton
                        sx={{ pl: 5 }}
                        component={Link}
                        to={"/dashboard/refresh"}
                        selected={location.pathname.includes(
                          "/dashboard/refresh"
                        )}
                      >
                        <ListItemIcon>
                          <CachedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Data Refresher" />
                      </ListItemButton>
                    </>
                  )} */}
                </List>
              </Collapse>
            </>
          )}
        </StyledList>
      </Box>
    </>
  );
};

export default NavItem;
