import React, { useState } from "react";
import { Link } from "react-router-dom";
import ErrorText from "../../components/ErrorText/Index";
import { auth } from "../../config/Firebase";
import logging from "../../config/Logging";
import IPageProps from "../../interfaces/Page";

// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://www.akatselangor.com.my/">
        Lembaga Zakat Selangor
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "100px",
    height: "70px",
    borderRadius: "unset",
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  googlesignin: {
    margin: theme.spacing(1, 0, 2),
  },
}));

const ForgotPasswordPage: React.FunctionComponent<IPageProps> = (props) => {
  const classes = useStyles();
  const [sending, setSending] = useState<boolean>(false);
  // const [sent, setSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const resetPasswordRequest = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (error !== "") setError("");

    setSending(true);

    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        logging.info("Email sent.");
        // setSent(true);
        setError(
          "Information/link to update your password has been sent to your email '" +
            email +
            "'"
        );
        setEmail("");
        setSending(false);
      })
      .catch((error) => {
        logging.error(error);
        setError(error.message);
        setSending(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar} src="/images/logo.png?v=1">
          <LockOutlinedIcon />
        </Avatar> */}
        <img
          src="/images/logo.png?v=1"
          alt="i3"
          height="100"
          className="mcmclogo"
        />
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <p style={{ textAlign: "center" }}>
          <ErrorText error={error} />
        </p>
        <form onSubmit={(e: any) => resetPasswordRequest(e)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            type="email"
            name="email"
            label="Email Address"
            placeholder="Please enter your email"
            autoFocus
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />

          <Button
            disabled={sending}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send Reset Link
          </Button>
          <Grid container justifyContent="center">
            <br />
            <Grid>
              <Link to="/login" variant="body2">
                Back
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ padding: "25px" }}>
        <Copyright />
      </div>
    </Container>
  );
};

export default ForgotPasswordPage;
