import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@mui/icons-material/Add";
import FlagIcon from "@mui/icons-material/Flag";
// import UnpublishedIcon from "@mui/icons-material/Unpublished";
// import UnpublishedIcon from "@mui/icons-material/Cancel";
// import PublishedIcon from "@mui/icons-material/CheckCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import Button from "@mui/material/Button";
import CsvDownloader from "react-csv-downloader";
import type { RootState } from "../../store/index";
import { auth } from "../../config/Firebase";

interface PropsTotal {
  data?: any;
  callback?: (data: any) => void;
  selected?: (data: any) => void;
}
const List: React.FC<PropsTotal> = ({ data, callback, selected }) => {
  const navigate = useNavigate();
  const { profile } = useSelector((state: RootState) => state.userState);
  const [searchresult, setSearchResult] = React.useState<any[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [downladdata, setDownloadData] = React.useState<any[]>([]);
  const [token, setToken] = React.useState<any>("");

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      setLoading(true);
      fetch("/api/category/list", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setSearchResult(data.list);
            getDownloadData(data.list);
            setLoading(false);
          }
        });
    };

    if (typeof searchresult === "undefined" && token !== "") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [searchresult, token]);

  const getDownloadData = (data: any[]) => {
    if (typeof data !== "undefined" && data.length > 0) {
      let newdatas: any[] = [];
      for (let a = 0; a < data.length; a++) {
        data[a].bil = a + 1;
        newdatas.push(data[a]);
      }
      setDownloadData(newdatas);
    } else {
      setDownloadData([]);
    }
  };

  const setNew = () => {
    if (callback) {
      if (
        typeof searchresult !== "undefined" &&
        /MANAGER/i.test(profile?.position)
      ) {
        if (searchresult.length <= 0) {
          console.log(profile);
          callback({
            state: profile?.state ? profile?.state : "",
            district: profile?.district ? profile?.district : "",
            refid: profile?.pi1mid ? profile?.pi1mid : "",
          });
        } else {
          callback({
            state: searchresult[0]?.state ? searchresult[0]?.state : "",
            district: searchresult[0]?.district
              ? searchresult[0]?.district
              : "",
            refid: searchresult[0]?.pi1mname ? searchresult[0]?.pi1mname : "",
          });
        }
      } else {
        callback({ dig_lit_id: "", state: "", district: "", refid: "" });
      }
    } else {
      navigate(`/dashboard/category/new`);
    }
  };

  const setEdit = (info: any) => {
    if (callback) {
      callback(info);
    } else {
      navigate(`/dashboard/category/${info?.category_id}`);
    }
  };

  const itemClicked = (info: any) => {
    if (selected) {
      selected(info);
    }
  };

  let recordLength =
    typeof searchresult !== "undefined" ? searchresult.length : 0;
  let copy = typeof searchresult !== "undefined" ? searchresult : [];
  if (search !== "" && typeof searchresult !== "undefined") {
    const keyword = search.toLowerCase();

    copy = searchresult.filter((row: any) => {
      for (let r in row) {
        if (
          typeof row[r] !== "object" &&
          row[r] &&
          row[r].toString().toLowerCase().indexOf(keyword) >= 0
        ) {
          return row;
        }
      }
      return false;
    });
    recordLength = copy.length;
  }

  const rows = copy.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={3} md={2}>
          <div style={{ width: "50px", height: "50px" }}>
            <CircularProgress />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <>
        {typeof searchresult !== "undefined" && (
          <>
            {searchresult.length > 0 ? (
              <>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={5}>
                    <TextField
                      // label="Find"
                      placeholder="Find"
                      value={search}
                      onChange={onSearchChange}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={7}>
                    <div style={{ textAlign: "right" }}>
                      <Fab
                        size="small"
                        color="secondary"
                        aria-label="Add New"
                        title="Add New"
                        // component={Link}
                        // to={`user/form/new`}
                        onClick={() => setNew()}
                      >
                        <AddIcon />
                      </Fab>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Grid item xs={12} sm={1} md={1}></Grid>
                  <Grid item xs={12} sm={2} md={1}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Foto
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} md={3}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Nama
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={4}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Keterangan
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Produk
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} md={1}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Aksi
                    </Typography>
                  </Grid>
                </Grid>

                {rows.length > 0 &&
                  rows.map((item: any, idx: number) => (
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        marginTop: "10px",
                        borderBottom: "1px solid #eeeeee",
                        cursor: "pointer",
                      }}
                      key={idx}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        md={1}
                        style={{ textAlign: "right" }}
                      >
                        {idx + 1}.
                      </Grid>
                      <Grid item xs={12} sm={2} md={1}>
                        <Avatar
                          style={{ cursor: "pointer" }}
                          onClick={() => itemClicked(item)}
                          src={
                            item?.photourl
                              ? item?.photourl
                              : `/images/agency/${item?.code}.png`
                          }
                        >
                          <FlagIcon />
                        </Avatar>
                      </Grid>
                      <Grid item xs={12} sm={2} md={3}>
                        {item?.category_name}
                      </Grid>
                      <Grid item xs={12} sm={3} md={4}>
                        {item?.category_description}
                      </Grid>
                      <Grid item xs={12} sm={2} md={2}>
                        {item?.total_of_product}
                      </Grid>
                      <Grid item xs={12} sm={2} md={1}>
                        <IconButton
                          edge="end"
                          aria-label="Edit"
                          onClick={() => setEdit(item)}
                          // component={Link}
                          // to={`/serviceprovider/calendar/${item.refid}`}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          edge="end"
                          aria-label="Products"
                          onClick={() => {
                            navigate(
                              `/administration/product/category/${item?.category_id}`
                            );
                          }}
                        >
                          <ShoppingCartIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}

                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Grid item xs={12} sm={4}>
                    {downladdata?.length > 0 && (
                      <CsvDownloader
                        datas={downladdata}
                        filename={"agencies.csv"}
                        wrapColumnChar={'"'}
                        columns={[
                          "id",
                          "code",
                          "name",
                          "description",
                          "is_active",
                          "pupuk",
                          "photourl",
                        ]}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<DownloadIcon />}
                          fullWidth
                          disabled={loading ? true : false}
                        >
                          Download
                        </Button>
                      </CsvDownloader>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {recordLength > rowsPerPage && (
                      <TablePagination
                        component="div"
                        count={recordLength}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <div style={{ textAlign: "right" }}>
                    <Fab
                      size="small"
                      color="secondary"
                      aria-label="Add New"
                      title="Add New"
                      // component={Link}
                      // to={`user/form/new`}
                      onClick={() => setNew()}
                    >
                      <AddIcon />
                    </Fab>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ textAlign: "center" }}>
                    Your query return empty result.
                  </div>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </>
    );
  }
};

export default List;
