import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface SectionAProps {
  onResultChange?: (result: number) => void;
  sectionA_exist: boolean;
}

interface Values {
  B1: number | null;
  B2: number | null;
  B3: number | null;
}

const SectionB2: React.FC<SectionAProps> = ({ onResultChange }) => {
  const [values, setValues] = useState<Values>({
    B1: null,
    B2: null,
    B3: null,
  });

  useEffect(() => {
    let newTotal = 0;
    if (values.B1) {
      const dewasaBekerjaValue = values.B1 || 0;
      const multipliedValue = dewasaBekerjaValue * 2964;

      newTotal += multipliedValue;
    }
    if (values.B2) {
      const dewasaBekerjaValue = values.B2 || 0;
      const multipliedValue = dewasaBekerjaValue * 2916;

      newTotal += multipliedValue;
    }
    if (values.B3) {
      const dewasaBekerjaValue = values.B3 || 0;
      const multipliedValue = dewasaBekerjaValue * 3960;

      newTotal += multipliedValue;
    }

    onResultChange && onResultChange(newTotal);
  }, [onResultChange, values]);

  const handleInputChange = (
    event: { target: { value: string } },
    id: string
  ) => {
    const newValue = event.target.value.replace(/^0+/, "");
    setValues((prevValues) => ({ ...prevValues, [id]: parseFloat(newValue) }));
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#2980B9" }}>
            <TableRow>
              <TableCell sx={{ color: "#ffffff" }}>Bil</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Butiran</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>RM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>B1.1</TableCell>
              <TableCell>Ketua Keluarga</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <select
                    id="B1"
                    value={values.B1 || ""}
                    onChange={(e) => handleInputChange(e, "B1")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  >
                    <option value={0}>Tiada</option>
                    {[...Array(10)].map((a, number) => (
                      <option key={number + 1} value={number + 1}>
                        {number + 1} orang
                      </option>
                    ))}
                  </select>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B1.2</TableCell>
              <TableCell>
                Dewasa Bekerja (18 tahun ke atas) (RM4,944 seorang)
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <select
                    id="B2"
                    value={values.B2 || ""}
                    onChange={(e) => handleInputChange(e, "B2")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  >
                    <option value={0}>Tiada</option>
                    {[...Array(10)].map((a, number) => (
                      <option key={number + 1} value={number + 1}>
                        {number + 1} orang
                      </option>
                    ))}
                  </select>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B1.3</TableCell>
              <TableCell>
                Dewasa Tidak Bekerja (18 tahun ke atas) (RM2,004 seorang)
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <select
                    id="B3"
                    value={values.B3 || ""}
                    onChange={(e) => handleInputChange(e, "B3")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  >
                    <option value={0}>Tiada</option>
                    {[...Array(10)].map((a, number) => (
                      <option key={number + 1} value={number + 1}>
                        {number + 1} orang
                      </option>
                    ))}
                  </select>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SectionB2;
