import React from "react";
// import Grid from "@mui/material/Grid";
// import { useNavigate } from "react-router-dom";
// import Typography from "@mui/material/Typography";
import {
  Edit as EditIcon,
  Article as ArticleIcon,
  AddCircle as AddIcon,
} from "@mui/icons-material";
import UnpublishedIcon from "@mui/icons-material/Cancel";
import PublishedIcon from "@mui/icons-material/CheckCircle";

import { auth } from "../../config/Firebase";
import Table from "../Tables/DynamicTables";
import { localeCurrency } from "../Store/Store";

interface DialogProps {
  user?: any;
  callback?: (data: any, type?: any) => void;
}

const IndexPanel = (props: DialogProps) => {
  const { callback } = props;
  // const history = useNavigate();
  // const [loading, setLoading] = React.useState<boolean>(false);
  // const { profile } = useSelector((state: RootState) => state.userState);
  // const [pik, setPik] = React.useState<any>();
  // const [info, setInfo] = React.useState<any>();
  // const [open, setOpen] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<any>("");
  const [columns] = React.useState<any[]>([
    { accessorKey: "id", header: "Id", sortable: true, size: 50 },
    {
      accessorKey: "photos",
      header: "Foto",
      sortable: false,
      size: 50, //small column
      columnDefType: "display", //turns off data column features like sorting, filtering, etc.
      enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
      Cell: (item?: any) => (
        <div
          className="productthumbnail"
          style={{
            width: "100%",
            maxWidth: "40px",
            backgroundImage:
              "url(" +
              (typeof item?.row.original.photourl !== "undefined" &&
              item?.row.original.photourl !== ""
                ? item?.row.original.photourl
                : "/images/default.png") +
              ")",
          }}
          title={item?.row.original.name ? item?.row.original.name : ""}
        />
      ),
    },
    { accessorKey: "name", header: "Toko/Agency Name", sortable: true },
    {
      accessorKey: "total_product",
      enableColumnFilter: false,
      enableColumnOrdering: false,
      header: "Produk",
      sortable: true,
      size: 50,
      Cell: (item?: any) => (
        <div style={{ textAlign: "right" }}>
          {item?.row.original.total_product
            ? item?.row.original.total_product
            : 0}
        </div>
      ),
    },
    {
      accessorKey: "total_amount",
      enableColumnFilter: false,
      enableColumnOrdering: false,
      header: "Jumlah",
      sortable: true,
      size: 50,
      Cell: (item?: any) => (
        <div style={{ textAlign: "right" }}>
          {localeCurrency(
            item?.row.original.total_amount
              ? item?.row.original.total_amount
              : 0
          )}
        </div>
      ),
    },
    {
      accessorKey: "total_user",
      enableColumnFilter: false,
      enableColumnOrdering: false,
      header: "Pengguna",
      sortable: true,
      size: 50,
      Cell: (item?: any) => (
        <div style={{ textAlign: "right" }}>
          {item?.row.original.total_user ? item?.row.original.total_user : 0}
        </div>
      ),
    },
    {
      accessorKey: "is_active",
      header: "Status",
      enableColumnFilter: false,
      enableColumnOrdering: false,
      sortable: true,
      Cell: (item?: any) => (
        <>
          {item?.row.original?.is_active ? (
            <PublishedIcon style={{ color: "green" }} />
          ) : (
            <UnpublishedIcon style={{ color: "red" }} />
          )}
        </>
      ),
    },
  ]);

  // const moduleCallback = (newdata: any) => {
  //   console.log(newdata);
  //   setInfo(newdata);
  //   setOpen(true);
  // };

  const selectedCallback = (selecteddata?: any, type?: any) => {
    if (callback) {
      callback(selecteddata, type);
    }
  };

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  return (
    <>
      {typeof token !== "undefined" && token !== "" && (
        <Table
          enableRowNumbers={false}
          disabledPaper={true}
          api={"/api/agency/list/all"}
          apiDownload={"/api/agency/download/all"}
          token={token}
          fieldid={"id"}
          arrayRef={"agencies"}
          dataCallback={(e: any) => {
            // console.log(e);
            // setCategory(e?.metadata?.category);
          }}
          onRowClick={(e: any) => {
            // console.log("here", e); //remove this if dont use
            // navigate(`/dbpedi/member/form/${e?.id}`);
            if (typeof selectedCallback !== "undefined") {
              // selectedCallback(e);
            }
          }}
          onFilterModelChange={(e: any) => {
            console.log(e); //remove this if dont use
          }}
          prefixfilename={"-agency"}
          pagesize={20}
          autoheight={true}
          columns={columns}
          columnhides={["id"]}
          toolbarButtons={{
            position: "last", //first|last
            buttons: [
              {
                icon: <AddIcon />,
                action: () => {
                  console.log("button");
                  selectedCallback({}, "new");
                },
              },
            ],
          }}
          rowactions={{
            name: "Actions",
            position: "last", //first|last
            buttons: [
              {
                icon: <ArticleIcon />,
                action: (row?: any) => {
                  // console.log("here", row);
                  selectedCallback(row, "info");
                },
              },
              {
                icon: <EditIcon />,
                action: (row?: any) => {
                  // console.log("here", row, 'edit');
                  selectedCallback(row, "edit");
                },
              },
            ],
          }}
        />
      )}
    </>
  );
};

export default IndexPanel;
