import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AuthContainer from "../../components/AuthContainer/Index";
import { auth } from "../../config/Firebase";
import logging from "../../config/Logging";
import IPageProps from "../../interfaces/Page";
import { setProfile } from "./../../store/user";
import type { RootState } from "./../..//store/index";

const LogoutPage: React.FunctionComponent<IPageProps> = (props) => {
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.userState);

  const Logout = () => {
    if (profile.sign_in_provider) {
    }

    const authserverurl: string = process.env[
      "REACT_APP_AD_AUTH_SERVER_LOGOUT_URL"
    ]
      ? process.env["REACT_APP_AD_AUTH_SERVER_LOGOUT_URL"]
      : "";

    if (profile?.sign_in_provider === "adfs") {
      if (authserverurl !== "") {
        window.open(
          authserverurl,
          "pikadmcmc",
          "height=500,width=500;center=yes;menubar=no;directories=no;location=no;modal=yes"
        );
      } else {
        console.log("Missing AD logout url");
      }
    }
    auth
      .signOut()
      .then(() => {
        const newprofile: any = {};
        dispatch(setProfile(newprofile));
        // setTimeout(() => {
        //   return <Navigate to="/login" />;
        // }, 5000);
        navigate("/");
        if (props?.callback) {
          props?.callback("logout");
        }
      })
      .catch((error) => logging.error(error));
  };

  const cancelLogout = () => {
    if (props?.callback) {
      props?.callback("cancel");
    } else {
      navigate(-1);
    }
  };

  return (
    <AuthContainer header="Log Keluar">
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <p className="text-center">Anda ingin log keluar?</p>
        </Grid>
        <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
          <Button
            // color="primary"
            variant="text"
            className="mr-2"
            onClick={() => cancelLogout()}
          >
            Batal
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
          <Button color="error" variant="contained" onClick={() => Logout()}>
            Log Keluar
          </Button>
        </Grid>
      </Grid>

      <div className="text-center"></div>
    </AuthContainer>
  );
};

export default LogoutPage;
