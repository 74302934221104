import React from "react";
import Grid from "@mui/material/Grid";
import { useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";

// import Alert from "@mui/material/Alert";
// import parse from "html-react-parser";

// import UnitButton from "../../components/Store/UnitButton";
import { auth } from "../../config/Firebase";

// import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import { useNavigate } from "react-router-dom";

import Divider from "@mui/material/Divider";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { localeCurrency } from "../../components/Store/Store";
// import CircularProgress from "@mui/material/CircularProgress";

// import { setCartSelected } from "../../store/form";

// import { useSelector } from "react-redux";
// import type { RootState } from "../../store/index";
import AddressForm from "../../components/Store/AddressBook/Form";

const Checkout = () => {
  const { state } = useParams();
  const navigate = useNavigate();
  // const [loading, setLoading] = React.useState<boolean>(false);

  // const { cartselected } = useSelector((state: RootState) => state.formState);
  const [ids, setIds] = React.useState<any[]>();
  const [error, setError] = React.useState<string>("");
  const [carts, setCarts] = React.useState<any[]>();
  const [token, setToken] = React.useState<any>();
  const [form, setForm] = React.useState<any>({
    payment_method: "BANK_TRANSFER",
    bank_code: "BSI",
    bank_name: "Bank Syariah Indonesia",
  });
  const [cartbyagency, setCartByAgency] = React.useState<any[]>();
  const [addresssame, setAddressSame] = React.useState<boolean>(false);

  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setToken(token);
        });
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof state !== "undefined" && state !== "") {
      // console.log(atob(state));
      setIds(JSON.parse(atob(state)));
    }
  }, [state]);

  // React.useEffect(() => {
  //   if (cartselected.length > 0 && typeof ids === "undefined") {
  //     setIds(cartselected);
  //   }
  // }, [ids, cartselected]);

  // React.useEffect(() => {
  //   if (typeof form?.billing_address !== "undefined") {
  //     let billing_address: any = JSON.parse(
  //       JSON.stringify(form?.billing_address)
  //     );

  //     if (addresssame) {
  //       setForm({ ...form, shipping_address: billing_address });
  //     } else {
  //       setForm({ ...form, shipping_address: {} });
  //     }
  //   }
  // }, [form, addresssame]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/apps/cart/list", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            // console.log("test: ", data);
            let newlists: any[] = [];
            for (let a = 0; a < data?.list.length; a++) {
              // console.log(ids, data?.list[a]?.cart_id);
              const idx = ids ? ids.indexOf(data?.list[a]?.cart_id) : -1;
              if (idx > -1) {
                newlists.push(data?.list[a]);
              }
            }
            // console.log(newlists);
            setCarts(newlists);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof carts === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof ids !== "undefined" &&
      ids.length > 0
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, carts, ids]);

  React.useEffect(() => {
    if (typeof carts !== "undefined" && carts.length > 0) {
      let cartbyagencyhash: any = {};
      for (let c = 0; c < carts.length; c++) {
        if (typeof cartbyagencyhash[carts[c].agency_id] === "undefined") {
          cartbyagencyhash[carts[c].agency_id] = {
            id: carts[c].agency_id,
            name: carts[c].agency_name,
            photourl: carts[c].agency_photourl,
            checked: carts[c].agency_checked ? carts[c].agency_checked : false,
            products: [],
          };
        }
        carts[c].idx = c;
        cartbyagencyhash[carts[c].agency_id].products.push(carts[c]);
      }
      let newcartbyagency: any[] = [];
      for (let agency in cartbyagencyhash) {
        newcartbyagency.push(cartbyagencyhash[agency]);
      }
      setCartByAgency(newcartbyagency);
    }
  }, [carts]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddressSame(event.target.checked);
    if (typeof form?.billing_address !== "undefined") {
      let billing_address: any = JSON.parse(
        JSON.stringify(form?.billing_address)
      );

      if (event.target.checked) {
        setForm({ ...form, shipping_address: billing_address });
      } else {
        setForm({ ...form, shipping_address: {} });
      }
    }
  };

  const cartToOrder = () => {
    if (typeof form !== "undefined") {
      // setLoading(true);
      if (
        typeof form?.billing_address === "undefined" ||
        typeof form?.shipping_address === "undefined" ||
        typeof ids === "undefined"
      ) {
        setError("ERROR");
        window.alert("Missing required fields.");

        // setLoading(false);
      } else {
        setError("");
        const data = new URLSearchParams();
        data.append("cart_id", JSON.stringify(ids));
        data.append("shipping_address", JSON.stringify(form?.shipping_address));
        data.append("billing_address", JSON.stringify(form?.billing_address));
        data.append("payment_method", form?.payment_method);
        data.append("bank_code", form?.bank_code);
        data.append("bank_name", form?.bank_name);

        // for (let i = 0; i < ids.length; i++) {
        //   // data.append("cart_id", ids[i].toString());
        // }

        fetch("/api/orders/place", {
          method: "post",
          body: data,
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            // setLoading(false);
            if (data?.metadata?.status === "OK") {
              setError("SUCCESS");
              navigate(
                "/store/orders/" +
                  (data?.order?.order_id
                    ? data?.order?.order_id
                    : data?.order?.id)
              );
            } else {
              setError("ERROR");
            }
          })
          .catch((e: any) => {
            setError("ERROR");
            // setLoading(false);
          });
      }
    } else {
      window.alert("missing form data.");
    }
  };

  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, payment_method: event.target.value });
  };

  // console.log(carts, state);
  let total: number = 0;
  let quantity: number = 0;
  let shipping: number = 0;

  return (
    <>
      <div style={{ display: "flex", margin: "15px" }}>
        <div style={{ paddingRight: "5px" }}>
          <ShoppingCartIcon />
        </div>
        <div>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Checkout
          </Typography>
        </div>
        <div style={{ textAlign: "right", flexGrow: 1 }}>
          {/* {loading && <CircularProgress size={30} />} */}
        </div>
      </div>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        {error && (
          <Grid item xs={12}>
            <Alert severity={error && error === "ERROR" ? "error" : "success"}>
              {error}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={6} lg={12}>
              <div className="addressformheader">
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  Alamat Pengiriman
                </Typography>
              </div>
              <Paper style={{ padding: "10px", marginBottom: "40px" }}>
                <AddressForm
                  token={token}
                  form={form?.billing_address}
                  callback={(e: any) => {
                    setForm({ ...form, billing_address: e });
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <div style={{ display: "flex" }} className="addressformheader">
                <div>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Alamat Tagihan
                  </Typography>
                </div>
                <div
                  style={{
                    flexGrow: "1",
                    textAlign: "right",
                    alignItems: "flex-end",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addresssame ? addresssame : false}
                        onChange={handleChange}
                        id="address_same"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Alamat sama seperti pengiriman
                      </Typography>
                    }
                  />
                </div>
              </div>
              <Paper style={{ padding: "10px", marginBottom: "40px" }}>
                {/* {addresssame ? (
              <div style={{ padding: "10px" }}>
                <Typography variant="body2">
                  Alamat sama seperti pengiriman
                </Typography>
              </div>
            ) : ( */}
                <AddressForm
                  token={token}
                  form={form?.shipping_address}
                  callback={(e: any) => {
                    setForm({ ...form, shipping_address: e });
                  }}
                />
                {/* )} */}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          {cartbyagency &&
            cartbyagency.map((agency: any, adx: any) => {
              let subtotal: number = 0;
              let subquantity: number = 0;
              let subshipping: number = 10000;
              shipping = shipping + subshipping;
              return (
                <Paper
                  style={{ padding: "10px", marginBottom: "40px" }}
                  key={adx}
                >
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={1} lg={1}>
                      <div
                        className="agency_thumbnail"
                        style={{
                          backgroundImage: `${
                            "url(" +
                            (typeof agency?.photourl !== "undefined" &&
                            agency?.photourl !== ""
                              ? agency?.photourl
                              : "/images/default.png") +
                            ")"
                          }`,
                          maxWidth: "50px",
                        }}
                      >
                        &nbsp;
                      </div>
                    </Grid>
                    <Grid item xs={9} sm={11} lg={11}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        {agency?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Produk Dipesan
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          style={{
                            textAlign: "right",
                            paddingRight: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          Unit Price
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Amount
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          style={{
                            textAlign: "right",
                            paddingRight: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          Sub Total
                        </Grid>
                        {agency.products &&
                          agency.products.map((cart: any, idx: any) => {
                            total =
                              total + cart?.quantity_buy * cart?.price_each;
                            subtotal =
                              subtotal + cart?.quantity_buy * cart?.price_each;
                            quantity = quantity + cart?.quantity_buy;
                            subquantity = subquantity + cart?.quantity_buy;
                            return (
                              <React.Fragment key={idx}>
                                <Grid item xs={12} sm={1} md={1} lg={2} xl={1}>
                                  <div
                                    className="cart_thumbnail"
                                    style={{
                                      backgroundImage: `${
                                        "url(" +
                                        (typeof cart?.photos[0] !==
                                          "undefined" && cart?.photos[0] !== ""
                                          ? cart?.photos[0]
                                          : "/images/default.png") +
                                        ")"
                                      }`,
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                  <strong>{cart?.product_name}</strong>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={2}
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {localeCurrency(cart?.price_each)}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={2}
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {cart?.quantity_buy}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={2}
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "5px",
                                  }}
                                >
                                  {localeCurrency(
                                    cart?.quantity_buy * cart?.price_each
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  <Divider />
                                </Grid>
                              </React.Fragment>
                            );
                          })}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        id="message"
                        label="Message"
                        multiline
                        maxRows={3}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ textAlign: "right" }}
                        >
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            Standard Delivery:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ textAlign: "right", paddingRight: "5px" }}
                        >
                          <strong>{localeCurrency(subshipping)}</strong>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ textAlign: "right" }}
                        >
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            Belian ({subquantity} barang
                            {subquantity > 1 ? "s" : ""}
                            ):
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ textAlign: "right", paddingRight: "5px" }}
                        >
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold", color: "#ed6c02" }}
                          >
                            {localeCurrency(subtotal + subshipping)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}

          <Paper>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <Grid
                container
                spacing={1}
                padding={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Payment Method:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Radio
                    checked={form?.bank_code === "BSI"}
                    onChange={handlePaymentChange}
                    value="BSI"
                    name="bank_code"
                    inputProps={{ "aria-label": "A" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={7}>
                  <img
                    src="/images/payments/bsi.png"
                    alt="Bank Syariah Indonesia"
                    style={{ height: "100%", maxHeight: "60px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography variant="body1">Bank Transfer</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </RadioGroup>
            <Grid
              container
              spacing={1}
              padding={2}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs={12} md={8}>
                <div className="totalfooter">
                  <div style={{ flexGrow: 1 }}>Shipping Total:</div>
                  <div className="subtotalnumber">
                    <Typography variant="subtitle1">
                      {localeCurrency(shipping)}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="totalfooter">
                  <div style={{ flexGrow: 1 }}>Merchandise Subtotal :</div>
                  <div className="subtotalnumber">
                    <Typography variant="subtitle1">
                      {localeCurrency(total)}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="totalfooter">
                  <div style={{ flexGrow: 1 }}>Total Payment :</div>
                  <div className="subtotalnumber">
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", color: "#ed6c02" }}
                    >
                      {localeCurrency(total + shipping)}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={8}>
                {typeof carts !== "undefined" && carts.length > 0 && (
                  <Button
                    variant="contained"
                    color="warning"
                    startIcon={<ShoppingCartCheckoutIcon />}
                    onClick={() => cartToOrder()}
                    fullWidth
                  >
                    Place Order
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Checkout;
