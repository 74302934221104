import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { format, isValid } from "date-fns";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import {
  Edit as EditIcon,
  //   Article as ArticleIcon,
  AddCircle as AddIcon,
  LockPerson as LockPersonIcon,
  VerifiedUser as VerifiedUserIcon,
} from "@mui/icons-material";
import Back from "../Back";
import Breadcrumbs from "../Breadcrumbs";

import { auth } from "../../config/Firebase";
import Table from "../Tables/DynamicTables";
import { IsGrantedAccess, AuthDisplay } from "../../config/Acl";

import type { RootState } from "../../store/index";
import { localeCurrency } from "../Store/Store";

interface DialogProps {
  user?: any;
  callback?: (data: any, type?: any) => void;
}

const IndexPanel = (props: DialogProps) => {
  const { callback } = props;
  const history = useNavigate();
  const tableRef = useRef();
  const { profile } = useSelector((state: RootState) => state.userState);

  // const [loading, setLoading] = React.useState<boolean>(false);
  // const { profile } = useSelector((state: RootState) => state.userState);
  // const [pik, setPik] = React.useState<any>();
  //   const [info, setInfo] = React.useState<any>();
  //   const [open, setOpen] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<any>("");
  const [pending, setPending] = React.useState<boolean>(false);
  const [refresh, setResfresh] = React.useState<boolean>(false);

  const [columns] = React.useState<any[]>([
    { accessorKey: "id", header: "Id", sortable: true, size: 50 },
    {
      accessorKey: "photos",
      header: "Photo",
      sortable: false,
      size: 50, //small column
      columnDefType: "display", //turns off data column features like sorting, filtering, etc.
      enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
      Cell: (item?: any) => (
        <div
          className="productthumbnail"
          style={{
            width: "100%",
            maxWidth: "40px",
            backgroundImage:
              "url(" +
              (typeof item?.row.original.picture !== "undefined" &&
              item?.row.original.picture !== ""
                ? item?.row.original.picture
                : "/images/default.png") +
              ")",
          }}
          title={item?.row.original.name ? item?.row.original.name : ""}
        />
      ),
    },
    { accessorKey: "name", header: "Name", sortable: true },
    { accessorKey: "email", header: "e-mail", sortable: true },
    { accessorKey: "position", header: "Role", sortable: true, size: 50 },
    // { accessorKey: "agency_name", header: "Toko/Agency", sortable: true },
    // {
    //   accessorKey: "total_product",
    //   enableColumnFilter: false,
    //   enableColumnOrdering: false,
    //   header: "Products",
    //   sortable: true,
    //   size: 50,
    //   Cell: (item?: any) => (
    //     <div style={{ textAlign: "right" }}>
    //       {item?.row.original.total_product
    //         ? item?.row.original.total_product
    //         : 0}
    //     </div>
    //   ),
    // },
    {
      accessorKey: "total_amount",
      enableColumnFilter: false,
      enableColumnOrdering: false,
      header: "Amount",
      sortable: true,
      size: 50,
      Cell: (item?: any) => (
        <div style={{ textAlign: "right" }}>
          {localeCurrency(
            item?.row.original.total_amount
              ? item?.row.original.total_amount - 0
              : 0
          )}
        </div>
      ),
    },
    {
      accessorKey: "created_at",
      enableColumnFilter: false,
      enableColumnOrdering: false,
      header: "Created",
      sortable: true,
      Cell: (item?: any) => (
        <div>
          {isValid(new Date(item?.row.original.created_at))
            ? format(
                new Date(
                  item?.row.original.created_at
                    ? item?.row.original.created_at
                    : ""
                ),
                "yyyy-MM-dd HH:mm:ss"
              )
            : "-"}
        </div>
      ),
    },
    {
      accessorKey: "is_active",
      header: "Status",
      enableColumnFilter: false,
      enableColumnOrdering: false,
      sortable: true,
      Cell: (item?: any) => {
        if (item?.row.original?.status > 1) {
          return <LockPersonIcon style={{ color: "red" }} />;
        } else if (item?.row.original?.status < 1) {
          return <PsychologyAltIcon style={{ color: "orange" }} />;
        } else {
          return <VerifiedUserIcon style={{ color: "green" }} />;
        }
      },
    },
  ]);

  const selectedCallback = (selecteddata?: any, type?: any) => {
    if (callback) {
      callback(selecteddata, type);
    }
  };

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof pending !== "undefined") {
      setResfresh(pending);
    }
  }, [pending]);

  if (!IsGrantedAccess("pikmembers", profile)) {
    return <AuthDisplay />;
  } else {
    return (
      <>
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <Back />
          <Typography variant="subtitle1">Pengurusan Pengguna</Typography>
        </div>

        <Breadcrumbs
          current={"Pengurusan Pengguna"}
          paths={[
            { name: "Pengurusan", url: "/dashboard" },
            // { name: "Applications", url: "/documents/application" },
          ]}
          className="margintop"
        />
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          //   style={{ marginBottom: "20px" }}
        >
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <FormControlLabel
              value={pending}
              control={
                <Checkbox
                  size="small"
                  onChange={() => {
                    setPending(!pending);
                  }}
                />
              }
              label={
                <Typography variant="caption">Pending For Approval</Typography>
              }
              labelPlacement="end"
            />
          </Grid>
        </Grid>
        {typeof token !== "undefined" && token !== "" && (
          <Table
            tableRef={tableRef}
            refresh={refresh}
            enableRowNumbers={false}
            disabledPaper={true}
            api={
              !pending ? "/api/user/index/all" : "/api/user/index/all?pending=1"
            }
            apiDownload={
              !pending
                ? "/api/user/download/all"
                : "/api/user/download/all?pending=1"
            }
            token={token}
            fieldid={"id"}
            arrayRef={"list"}
            dataCallback={(e: any) => {
              // console.log(e);
              // setCategory(e?.metadata?.category);
            }}
            onRowClick={(e: any) => {
              // console.log("here", e); //remove this if dont use
              // navigate(`/dbpedi/member/form/${e?.id}`);
              if (typeof selectedCallback !== "undefined") {
                // selectedCallback(e);
              }
            }}
            onFilterModelChange={(e: any) => {
              console.log(e); //remove this if dont use
            }}
            prefixfilename={"-agency"}
            pagesize={20}
            autoheight={true}
            columns={columns}
            columnhides={["id"]}
            toolbarButtons={{
              position: "last", //first|last
              buttons: [
                {
                  icon: <AddIcon />,
                  action: () => {
                    console.log("button");
                    // selectedCallback({}, "new");
                    history(`/dashboard/user/form/new`);
                  },
                },
              ],
            }}
            rowactions={{
              name: "Actions",
              position: "last", //first|last
              buttons: [
                // {
                //   icon: <ArticleIcon />,
                //   action: (row?: any) => {
                //     // console.log("here", row);
                //     selectedCallback(row, "info");
                //   },
                // },
                {
                  icon: <EditIcon />,
                  action: (row?: any) => {
                    // console.log("here", row, 'edit');
                    // selectedCallback(row, "edit");
                    if (row.id !== profile?.id) {
                      history(`/dashboard/user/form/${row.id}`);
                    } else {
                      window.alert("You cannot edit your own account");
                    }
                  },
                },
              ],
            }}
          />
        )}
      </>
    );
  }
};

export default IndexPanel;
