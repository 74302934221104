import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../config/Firebase";
import IPageProps from "../../interfaces/Page";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";

// export default function SignIn() {
const RegisterPage: React.FunctionComponent<IPageProps> = (props) => {
  const location = useLocation();
  let getemail: any = "";
  getemail = window.localStorage.getItem("emailForSignIn");
  if (getemail === "" || getemail === null) {
    getemail = new URLSearchParams(location.search).get("email");
  }
  console.log(getemail);

  const [registering] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(getemail);

  React.useEffect(() => {
    const confirmEmail = (email: string) => {
      // Confirm the link is a sign-in with email link.
      if (auth.isSignInWithEmailLink(window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        console.log(email);
        // The client SDK will parse the code from the link for you.
        auth
          .signInWithEmailLink(email, window.location.href)
          .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
    };

    if (typeof email !== "undefined" && email !== "") {
      confirmEmail(email);
    }
  }, [email]);

  return (
    <Container component="main" maxWidth="xs">
      <form noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email ? email : ""}
          onChange={(event) => setEmail(event.target.value)}
          disabled={registering}
        />
      </form>
    </Container>
  );
};

export default RegisterPage;
