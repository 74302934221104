import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import Button from "@mui/material/Button";

// import Grid from "@mui/material/Grid";
// import Avatar from "@material-ui/core/Avatar";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { auth } from "../../config/Firebase";
import { AuthDisplay } from "../../config/Acl";
// import type { RootState } from "./../../store/index";
import { setProfile } from "./../../store/user";
// import { isSetAccessor } from "typescript";
// import logging from "../../config/Logging";

export interface IAuthRouteProps {}

// const AuthDisplay: React.FunctionComponent<IAuthRouteProps> = (props) => {
//   const { children } = props;

//   return (
//     <div
//       style={{
//         width: "calc(100vw - 50px)",
//         // height: "100vh",
//         border: "0px solid #ff0000",
//         // paddingTop: "calc(50vh - 150px)",
//       }}
//     >
//       <Grid
//         container
//         spacing={5}
//         direction="row"
//         justifyContent="center"
//         alignItems="center"
//       >
//         {/* <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
//           <img src="/images/logo.png" alt="" />
//         </Grid> */}
//         <Grid item xs={12}>
//           {children}
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { profile } = useSelector((state: RootState) => state.userState);

  const [status, setStatus] = useState<string>();
  // const [userprofile, setUserProfile] = useState<any>();
  const [showlogin, setShowLogin] = useState<boolean>(false);
  // const location = useLocation();

  console.log();
  useEffect(() => {
    if (typeof auth !== "undefined") {
      auth.onAuthStateChanged((user: any) => {
        if (user) {
          if (user.emailVerified) {
            user.getIdToken().then((e: any) => {
              fetch("/api/user/whoami", {
                headers: {
                  Authorization: "Bearer " + e,
                  FcmToken: "",
                  Version: process.env["REACT_APP_VERSION"]
                    ? process.env["REACT_APP_VERSION"]
                    : "UNKNOWN",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data?.status === "OK") {
                    data.data.photo_url = user?.photoURL;
                    dispatch(setProfile(data?.data));
                    // setUserProfile(data?.data);
                    setStatus("pass");
                  } else {
                    setStatus(
                      data?.status + " — Need approval to access this module."
                    );
                    setShowLogin(true);
                  }
                });
            });
          } else {
            if (typeof user?.email !== "undefined" && user?.email !== "") {
              setStatus(
                "Please verify your email '" +
                  user?.email +
                  "' before logging in"
              );
            } else {
              setStatus("pass");
            }
          }

          // setStatus(true);
        } else {
          setStatus("public");
        }
      });
    }
  }, [dispatch]);

  const checkAccess = () => {
    return true;
    // if (typeof userprofile !== "undefined") {
    //   // if (location.pathname === "" || location.pathname === "/") {
    //   //   return true;
    //   // } else {
    //   //   const access = userprofile?.access.find((acc: any) => {
    //   //     // console.log(acc.path, location.pathname);
    //   //     return location.pathname.match(new RegExp(acc.path, "gi"));
    //   //   });

    //   //   if (typeof access !== "undefined") {
    //   //     return true;
    //   //   } else {
    //   //     return false;
    //   //   }
    //   // }
    //   return true;
    // } else {
    //   return false;
    // }
  };

  const goToLogin = () => {
    navigate(`/login`);
  };

  // console.log(status);

  if (typeof status === "undefined") {
    return <div className="mainloading">Loading......</div>;
  } else {
    if (status === "public" || status === "pass") {
      if (checkAccess()) {
        return <>{children}</>;
      } else {
        return (
          <AuthDisplay>
            <Alert severity="error">
              <AlertTitle>
                <strong>Forbidden!</strong>
              </AlertTitle>
              <Typography>
                You don’t have enough permission to access this module.
              </Typography>
            </Alert>
          </AuthDisplay>
        );
      }
    } else {
      return (
        <AuthDisplay>
          <Alert severity="error">
            <AlertTitle>Notification!</AlertTitle>
            <Typography>{status}</Typography>
          </Alert>
          {showlogin && (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <Button
                onClick={() => goToLogin()}
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </div>
          )}
        </AuthDisplay>
      );
    }
  }
};

export default AuthRoute;
