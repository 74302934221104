import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { format } from "date-fns";
import { localeCurrency } from "../../../components/Store/Store";
import ArticleIcon from "@mui/icons-material/Article";

import type { RootState } from "../../../store/index";
import { auth } from "../../../config/Firebase";
import { Divider, IconButton } from "@mui/material";

interface PropsTotal {
  data?: any;
  callback?: (data: any) => void;
  selected?: (data: any) => void;
}
const List: React.FC<PropsTotal> = ({ data, callback, selected }) => {
  const navigate = useNavigate();
  const { profile } = useSelector((state: RootState) => state.userState);
  const [searchresult, setSearchResult] = React.useState<any[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [total, setTotal] = React.useState<number>(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [token, setToken] = React.useState<any>("");

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const handleRowAction = (item: any) => {
    navigate(`/store/myaccount/orders/info/${item.id}`);
  };

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      setLoading(true);
      fetch("/api/zakat/mylist", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setSearchResult(data.list);
            // getDownloadData(data.list);
            let newtotal: number = 0;
            for (let a = 0; a < data.list.length; a++) {
              newtotal =
                newtotal + (data.list[a].amount ? data.list[a].amount - 0 : 0);
            }
            setTotal(newtotal);
            setLoading(false);
          }
        });
    };

    if (typeof searchresult === "undefined" && token !== "") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [searchresult, token]);

  const setNew = () => {
    if (callback) {
      if (
        typeof searchresult !== "undefined" &&
        /MANAGER/i.test(profile?.position)
      ) {
        if (searchresult.length <= 0) {
          console.log(profile);
          callback({
            state: profile?.state ? profile?.state : "",
            district: profile?.district ? profile?.district : "",
            refid: profile?.pi1mid ? profile?.pi1mid : "",
          });
        } else {
          callback({
            state: searchresult[0]?.state ? searchresult[0]?.state : "",
            district: searchresult[0]?.district
              ? searchresult[0]?.district
              : "",
            refid: searchresult[0]?.pi1mname ? searchresult[0]?.pi1mname : "",
          });
        }
      } else {
        callback({ dig_lit_id: "", state: "", district: "", refid: "" });
      }
    } else {
      navigate(`/store/myaccount/form/new`);
    }
  };

  let recordLength =
    typeof searchresult !== "undefined" ? searchresult.length : 0;
  let copy = typeof searchresult !== "undefined" ? searchresult : [];
  if (search !== "" && typeof searchresult !== "undefined") {
    const keyword = search.toLowerCase();

    copy = searchresult.filter((row: any) => {
      for (let r in row) {
        if (
          typeof row[r] !== "object" &&
          row[r] &&
          row[r].toString().toLowerCase().indexOf(keyword) >= 0
        ) {
          return row;
        }
      }
      return false;
    });
    recordLength = copy.length;
  }

  const rows = copy.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={3} md={2}>
          <div style={{ width: "50px", height: "50px" }}>
            <CircularProgress />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Zakat
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        {typeof searchresult !== "undefined" && (
          <>
            {searchresult.length > 0 ? (
              <>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={3} style={{ textAlign: "right" }}>
                    <TextField
                      label="Find"
                      placeholder="Find"
                      value={search}
                      onChange={onSearchChange}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  {/* <Grid
                    item
                    xs={12}
                    sm={1}
                    md={1}
                    style={{ textAlign: "center" }}
                  >
                    #
                  </Grid> */}
                  <Grid item xs={12} sm={3} md={3}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Tahun
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Zakat
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Transaction ID
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Tarikh
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Type
                    </Typography>
                  </Grid>
                </Grid>

                {rows.length > 0 &&
                  rows.map((item: any, idx: number) => (
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      style={{
                        marginTop: "10px",
                        borderBottom: "1px solid #eeeeee",
                      }}
                      key={idx}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        md={1}
                        style={{ textAlign: "center" }}
                      >
                        {idx + 1}.
                      </Grid>
                      <Grid item xs={12} sm={2} md={2}>
                        {item?.year}
                      </Grid>
                      <Grid item xs={12} sm={2} md={2}>
                        {localeCurrency(item?.amount ? item?.amount : 0)}
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        {item?.trans_id
                          ? item?.trans_id
                          : "2201023012348782164"}
                      </Grid>
                      <Grid item xs={12} sm={2} md={2}>
                        {item?.created_at
                          ? format(
                              new Date(item?.created_at),
                              "yyyy-MM-dd HH:mm:00"
                            )
                          : ""}
                      </Grid>
                      <Grid item xs={12} sm={2} md={2}>
                        FPX
                      </Grid>
                      {/* <Grid item xs={12} sm={2} md={1}>
                        <IconButton onClick={() => handleRowAction(item)}>
                          <ArticleIcon />
                        </IconButton>
                      </Grid> */}
                    </Grid>
                  ))}
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} md={3}></Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bold" }}
                    >
                      {localeCurrency(total)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7} md={7}></Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Grid item xs={12} sm={4}></Grid>
                  <Grid item xs={12} sm={8}>
                    {recordLength > rowsPerPage && (
                      <TablePagination
                        component="div"
                        count={recordLength}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <div style={{ textAlign: "right" }}>
                    <Fab
                      size="small"
                      color="secondary"
                      aria-label="Add New"
                      title="Add New"
                      // component={Link}
                      // to={`user/form/new`}
                      onClick={() => {
                        navigate("/store/zakat/form/pendapatan");
                      }}
                    >
                      <AddIcon />
                    </Fab>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ textAlign: "center" }}>
                    Your query return empty result.
                  </div>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </>
    );
  }
};

export default List;
