// import logo from "../../../Asset/images/logo.png";

function Logo({ logo, disabledradius }: any) {
  return (
    <img
      src={logo ? logo : "/images/default.png"}
      alt="CrowdZakat"
      style={{
        // width: "100%",
        // maxWidth: "200px",
        height: "100%",
        maxHeight: "40px",
        borderRadius:
          (typeof disabledradius !== "undefined" && !disabledradius) ||
          typeof disabledradius === "undefined"
            ? "50%"
            : "0",
      }}
    />
  );
}

export default Logo;
