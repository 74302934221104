import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";

interface PropsTotal {
  id?: string;
}

const Back: React.FC<PropsTotal> = ({ id }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {location.pathname !== "/" && (
        <div style={{ marginRight: "5px" }}>
          <IconButton
            aria-label="back"
            size="small"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon fontSize="inherit" />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default Back;
