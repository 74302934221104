const Config = {
  firebase: {
    // apiKey: "AIzaSyCjknthWUAK0yqahZEOV_jUr_OiMxX7EWw",
    // authDomain: "auth-mcmc-development.firebaseapp.com",
    // projectId: "auth-mcmc-development",
    // storageBucket: "auth-mcmc-development.appspot.com",
    // messagingSenderId: "558813205839",
    // appId: "1:558813205839:web:d92366b844927657f8613a",
    //pedi
    // apiKey: "AIzaSyCNdjpbzBO1TVD7wMFOctidxUhVR7dBhJY",
    // authDomain: "mcmc-pik.firebaseapp.com",
    // projectId: "mcmc-pik",
    // storageBucket: "mcmc-pik.appspot.com",
    // messagingSenderId: "478516206033",
    // appId: "1:478516206033:web:99f1b5deb46ffefca0a8f0",
    // measurementId: "G-CEKJ0H0VMW",

    //syarikat islam
    // apiKey: "AIzaSyAvaIRD3fPcn4yErFS3JndQjt5BSDzcTf0",
    // authDomain: "syarikat-islam.firebaseapp.com",
    // databaseURL: "https://syarikat-islam-default-rtdb.firebaseio.com",
    // projectId: "syarikat-islam",
    // storageBucket: "syarikat-islam.appspot.com",
    // messagingSenderId: "284565032395",
    // appId: "1:284565032395:web:576a92ba69f4a053937a4e",
    // measurementId: "G-XMSD3EQB6P",

    // apiKey: "AIzaSyDGvHWFbnNgG_34FhpX8Tkt_gmx1XbGu9c",
    // authDomain: "ihalals.firebaseapp.com",
    // databaseURL:
    //   "https://ihalals-default-rtdb.asia-southeast1.firebasedatabase.app",
    // projectId: "ihalals",
    // storageBucket: "ihalals.appspot.com",
    // messagingSenderId: "513449833984",
    // appId: "1:513449833984:web:3553ef961baf5246ba8ae5",
    // measurementId: "G-JRRJ71RGT0",

    apiKey: "AIzaSyBtbhZ1PekhKRhSHLHruXu2ez_YNfBHmh8",
    authDomain: "lzs-crowdzakat.firebaseapp.com",
    projectId: "lzs-crowdzakat",
    storageBucket: "lzs-crowdzakat.appspot.com",
    messagingSenderId: "670358687770",
    appId: "1:670358687770:web:c94021b76bb8f9060141a3",
    measurementId: "G-B4SBE916QL",
  },
};

export default Config;
