import React, { Fragment, useState } from "react";

import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";

// import { auth } from "../config/Firebase";
import Button from "@mui/material/Button";

// import HomeIcon from "@mui/icons-material/Home";
import type { RootState } from "../store/index";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import LogoutDialog from "./auth/LogoutDialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import MenuOpenIcon from "@mui/icons-material/MenuOpen";
// import MenuIcon from "@mui/icons-material/Menu";
import StoreIcon from "@mui/icons-material/Store";

import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import Drawer from "@mui/material/Drawer";

// import LockIcon from "@mui/icons-material/Lock";

// import Chip from "@mui/material/Chip";
// import Stack from "@mui/material/Stack";
// import { Avatar } from "@material-ui/core";
import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CartButton from "../components/Store/CartButton";
import LoginForm from "./auth/LoginForm";
import DrawerCart from "../components/Store/DrawerCart";
import "./Header.css";
import { MenuItem, Popover } from "@mui/material";
import { CommentsDisabledOutlined } from "@mui/icons-material";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       display: "flex",
//     },
//     paper: {
//       marginRight: theme.spacing(2),
//     },
//   })
// );

// const settings = ["ADMIN", "Logout"];
interface HeaderProps {
  openCallback?: (data: any) => void;
}

// let mouseovertimeout: any = null;

const Header = ({ openCallback }: HeaderProps) => {
  // const classes = useStyles();
  const { profile, tenant } = useSelector(
    (state: RootState) => state.userState
  );
  const navigate = useNavigate();
  const location = useLocation();
  // const [open, setOpen] = React.useState(true);
  const [open] = React.useState(true);
  const [logout, setLogout] = React.useState(false);
  const [drawer, setDrawer] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  // const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  // const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  //   console.log("open popover");
  //   if (mouseovertimeout !== null) {
  //     clearTimeout(mouseovertimeout);
  //   }
  //   mouseovertimeout = setTimeout(() => {
  //     setAnchorEl(null);
  //   }, 3000);
  // };

  // const handlePopoverClose = () => {
  //   console.log("close popover");
  //   setAnchorEl(null);
  // };

  // const openPopover = Boolean(anchorEl);

  // const isPopoverOpen = Boolean(anchorEl);
  // const id = isPopoverOpen ? "dashboard-popover" : undefined;

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  // const handleClose = (event: React.MouseEvent<EventTarget>, list?: any) => {
  //   if (
  //     anchorRef.current &&
  //     anchorRef.current.contains(event.target as HTMLElement)
  //   ) {
  //     return;
  //   }
  //   setOpen(false);
  //   if (list?.id) {
  //     navigate("/form/" + list?.id);
  //   }
  // };

  // function handleListKeyDown(event: React.KeyboardEvent) {
  //   if (event.key === "Tab") {
  //     event.preventDefault();
  //     setOpen(false);
  //   }
  // }

  const logoutCallback = (cdata: any) => {
    console.log(cdata);
    // if(cdata?.status === 'cancel'){

    // }else{
    //   setLogout(cdata?.status);
    // }
    setLogout(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // const [user, setUser] = React.useState<any>();
  // React.useEffect(() => {
  //   auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       setUser(user);
  //     } else {
  //       console.log("No user detected");
  //     }
  //   });
  // }, []);

  // const onHideSideBar = () => {
  //   if (typeof openCallback !== "undefined") {
  //     openCallback(!open);
  //   }
  //   setOpen(!open);
  // };

  const toggleDrawer =
    (newdrawer: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      console.log(newdrawer);
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawer(newdrawer);
    };

  // console.log("profile here", profile, user);
  // const checkPermission = (mod: string) => {
  //   // console.log(profile?.position);

  //   if (mod === "provider") {
  //     if (/provider$/i.test(profile?.position)) {
  //       // if (/mcmc|admin|manager$/i.test(profile?.position)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  //   if (mod === "admin") {
  //     if (/mcmc|admin$/i.test(profile?.position)) {
  //       // if (/mcmc|admin|manager$/i.test(profile?.position)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  //   if (mod === "manager") {
  //     if (/manager$/i.test(profile?.position)) {
  //       // if (/mcmc|admin|manager$/i.test(profile?.position)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }

  //   // profile
  // };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className="menubar"
        style={{
          backgroundColor: tenant?.theme_color
            ? tenant?.theme_color
            : "#e5e5e5",
          color: tenant?.theme_font_color
            ? tenant?.theme_font_color
            : "#ffffff",
          width: !open ? "90vw" : "calc(100vw - 300px)",
        }}
      >
        <Box
          style={{
            width: !open ? "100vw" : "calc(100vw - 300px)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box>
              {/* <div style={{ display: "flex" }}>
                <div style={{ margin: "auto" }}>
                  <Button
                    style={{ color: "#FFFFFF", minWidth: "unset" }}
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={() => onHideSideBar()}
                  >
                    {open ? <MenuOpenIcon /> : <MenuIcon />}
                  </Button>
                </div>
                <div style={{ margin: "auto", marginRight: "10px" }}>
                  <Button
                    style={{ color: "#FFFFFF", minWidth: "unset" }}
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={() => {
                      navigate("/");
                    }}
                  ></Button>
                </div>
                <div style={{ margin: "auto" }}>
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "bolder",
                    }}
                  ></span>
                </div>
              </div> */}
            </Box>

            {/* marginRight: "70px" */}
            <Box
              sx={{
                flexGrow: 0,
                paddingRight: "10px",
                maxWdith: "50vw",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="header_flex_item">
                  <Tooltip title="Store">
                    <IconButton
                      aria-label="Store"
                      size="small"
                      onClick={() => {
                        if (/\/store/.test(location.pathname)) {
                          navigate("/store");
                        } else {
                          navigate("/store/browse");
                        }
                      }}
                    >
                      <StoreIcon
                        fontSize="inherit"
                        style={{
                          color: tenant?.theme_font_color
                            ? tenant?.theme_font_color
                            : "#ffffff",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
                {/\/store/.test(location.pathname) && profile?.id && (
                  <div className="header_flex_item">
                    <Tooltip title="Bakul">
                      <IconButton
                        aria-label="Store"
                        size="small"
                        onClick={toggleDrawer(true)}
                      >
                        <CartButton
                          fontColor={
                            tenant?.theme_font_color
                              ? tenant?.theme_font_color
                              : "#ffffff"
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Drawer
                      anchor={"right"}
                      open={drawer}
                      onClose={toggleDrawer(false)}
                    >
                      <DrawerCart
                        callback={(e: any) => {
                          console.log(e);
                          setDrawer(e);
                        }}
                      />
                    </Drawer>
                  </div>
                )}
                {/\/store/.test(location.pathname) && profile?.id && (
                  <div className="header_flex_item">
                    <Tooltip title="Pembelian Saya">
                      <IconButton aria-owns="My Purchase" size="small">
                        <DashboardCustomizeIcon
                          fontSize="inherit"
                          onClick={() => {
                            navigate("/store/myaccount/cart");
                          }}
                          style={{
                            color: tenant?.theme_font_color
                              ? tenant?.theme_font_color
                              : "#ffffff",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}

                <div
                  className="header_flex_item"
                  title={profile?.staff_name ? profile?.staff_name : "Unknown"}
                  onMouseLeave={handleCloseUserMenu}
                >
                  {/\/store/.test(location.pathname) ? (
                    <Typography
                      variant="body2"
                      className="header_username"
                      component={Link}
                      to="/store/myaccount"
                      onMouseEnter={handleOpenUserMenu}
                    >
                      Selamat Datang,{" "}
                      {profile?.staff_name
                        ? profile?.staff_name
                        : "Pembayar Zakat"}
                    </Typography>
                  ) : (
                    <Typography variant="body2" className="header_username">
                      Selamat Datang,{" "}
                      {profile?.staff_name
                        ? profile?.staff_name
                        : "Pembayar Zakat"}
                    </Typography>
                  )}
                  {/* <Menu
                    id="profile-menu"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    // keepMounted
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate("/store/myaccount");
                        handleCloseUserMenu();
                      }}
                    >
                      Pembelian Saya
                    </MenuItem>
                  </Menu> */}
                </div>

                <div className="header_flex_item">
                  {profile?.id ? (
                    <Button
                      variant="outlined"
                      startIcon={<LogoutIcon />}
                      sx={{
                        color: tenant?.theme_font_color
                          ? tenant?.theme_font_color
                          : "#ffffff",
                        textTransform: "none",
                        margin: "0px 10px 0px 10px",
                        borderColor: "white",
                      }}
                      onClick={() => setLogout(!logout)}
                    >
                      <Typography variant="body2">Log Keluar</Typography>
                    </Button>
                  ) : (
                    <>
                      <Tooltip
                        title={
                          profile?.staff_name
                            ? profile?.staff_name
                            : "Open settings"
                        }
                      >
                        <Button
                          variant="outlined"
                          startIcon={<LoginIcon />}
                          style={{
                            color: tenant?.theme_font_color
                              ? tenant?.theme_font_color
                              : "#ffffff",
                            textTransform: "none",
                            margin: "0px 10px 0px 10px",
                            borderColor: "white",
                          }}
                          // onClick={() => navigate("/login")}
                          onClick={handleOpenUserMenu}
                        >
                          <Typography variant="body2">Login</Typography>
                        </Button>
                      </Tooltip>
                      <Menu
                        sx={{ mt: "35px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {/* <MenuItem
                          onClick={handleCloseUserMenu}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                          }}
                        > */}
                        <div style={{ width: "400px", padding: "20px" }}>
                          <LoginForm tenant={tenant} />
                        </div>
                        {/* </MenuItem> */}
                      </Menu>
                    </>
                  )}
                </div>
              </div>
              {/* <Grid
                container
                // spacing={1}
                // padding={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <IconButton
                    aria-label="Store"
                    size="small"
                    onClick={() => {
                      if (/\/store/.test(location.pathname)) {
                        navigate("/store");
                      } else {
                        navigate("/store/browse");
                      }
                    }}
                  >
                    <StoreIcon
                      fontSize="inherit"
                      style={{
                        color: tenant?.theme_font_color
                          ? tenant?.theme_font_color
                          : "#ffffff",
                      }}
                    />
                  </IconButton>
                </Grid>
                {/\/store/.test(location.pathname) && profile?.id && (
                  <Grid item xs={12} sm={2} md={2} lg={2}>
                    <IconButton
                      aria-label="Store"
                      size="small"
                      onClick={toggleDrawer(true)}
                    >
                      <CartButton
                        fontColor={
                          tenant?.theme_font_color
                            ? tenant?.theme_font_color
                            : "#ffffff"
                        }
                      />
                    </IconButton>
                    <Drawer
                      anchor={"right"}
                      open={drawer}
                      onClose={toggleDrawer(false)}
                    >
                      <DrawerCart
                        callback={(e: any) => {
                          console.log(e);
                          setDrawer(e);
                        }}
                      />
                    </Drawer>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={6}
                  lg={5}
                  style={
                    {
                      // whiteSpace: "nowrap"
                    }
                  }
                  title={profile?.staff_name ? profile?.staff_name : "Unknown"}
                >
                  <Typography variant="body2" className="header_username">
                    Hi, {profile?.staff_name ? profile?.staff_name : "Unknown"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={2} lg={2}>
                  {profile?.id ? (
                    <Button
                      variant="outlined"
                      startIcon={<LogoutIcon />}
                      style={{
                        color: tenant?.theme_font_color
                          ? tenant?.theme_font_color
                          : "#ffffff",
                        textTransform: "none",
                      }}
                      onClick={() => setLogout(!logout)}
                    >
                      <Typography variant="body2">Logout</Typography>
                    </Button>
                  ) : (
                    <>
                      <Tooltip
                        title={
                          profile?.staff_name
                            ? profile?.staff_name
                            : "Open settings"
                        }
                      >
                        <Button
                          variant="outlined"
                          startIcon={<LoginIcon />}
                          style={{
                            color: tenant?.theme_font_color
                              ? tenant?.theme_font_color
                              : "#ffffff",
                            textTransform: "none",
                          }}
                          // onClick={() => navigate("/login")}
                          onClick={handleOpenUserMenu}
                        >
                          <Typography variant="body2">Login</Typography>
                        </Button>
                      </Tooltip>
                      <Menu
                        sx={{ mt: "35px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        
                        <div style={{ width: "400px", padding: "20px" }}>
                          <LoginForm tenant={tenant} />
                        </div>
                      </Menu>
                    </>
                  )}
                </Grid>
              </Grid> */}
            </Box>
          </div>
        </Box>
      </Grid>
      <LogoutDialog open={logout} callback={logoutCallback} />
    </>
  );
};

export default Header;
