import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface SectionAProps {
  onResultChange?: (result: number) => void;
  sectionA_exist: boolean;
}

interface Values {
  B1: number | null;
  B2: number | null;
  B3: number | null;
  B4: number | null;
  B5: number | null;
  B6: number | null;
}

const SectionB1: React.FC<SectionAProps> = ({
  onResultChange,
  sectionA_exist,
}) => {
  const [values, setValues] = useState<Values>({
    B1: null,
    B2: null,
    B3: null,
    B4: null,
    B5: null,
    B6: null,
  });

  useEffect(() => {
    if (sectionA_exist) {
      setValues((prevValues) => ({ ...prevValues, B1: 14580 }));
      let newTotal = values.B1 || 0;

      if (values.B2) {
        const dewasaBekerjaValue = values.B2 || 0;
        const multipliedValue = dewasaBekerjaValue * 4944;

        newTotal += multipliedValue;
      }
      if (values.B3) {
        const dewasaBekerjaValue = values.B3 || 0;
        const multipliedValue = dewasaBekerjaValue * 2004;

        newTotal += multipliedValue;
      }
      if (values.B4) {
        const dewasaBekerjaValue = values.B4 || 0;
        const multipliedValue = dewasaBekerjaValue * 7356;

        newTotal += multipliedValue;
      }
      if (values.B5) {
        const dewasaBekerjaValue = values.B5 || 0;
        const multipliedValue = dewasaBekerjaValue * 4896;

        newTotal += multipliedValue;
      }
      if (values.B6) {
        const dewasaBekerjaValue = values.B6 || 0;
        const multipliedValue = dewasaBekerjaValue * 2100;

        newTotal += multipliedValue;
      }
      onResultChange && onResultChange(newTotal);
    }
  }, [onResultChange, sectionA_exist, values]);

  const handleInputChange = (
    event: { target: { value: string } },
    id: string
  ) => {
    const newValue = event.target.value.replace(/^0+/, "");
    setValues((prevValues) => ({ ...prevValues, [id]: parseFloat(newValue) }));
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#2980B9" }}>
            <TableRow>
              <TableCell sx={{ color: "#ffffff" }}>Bil</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Butiran</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>RM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>B1.1</TableCell>
              <TableCell>Ketua Keluarga</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <input
                    type="number"
                    id="B1"
                    value={values.B1 || ""}
                    onChange={(e) => handleInputChange(e, "B1")}
                    readOnly
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B1.2</TableCell>
              <TableCell>
                Dewasa Bekerja (18 tahun ke atas) (RM4,944 seorang)
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <select
                    id="B2"
                    value={values.B2 || ""}
                    onChange={(e) => handleInputChange(e, "B2")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  >
                    <option value={0}>Tiada</option>
                    {[...Array(10)].map((a, number) => (
                      <option key={number + 1} value={number + 1}>
                        {number + 1} orang
                      </option>
                    ))}
                  </select>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B1.3</TableCell>
              <TableCell>
                Dewasa Tidak Bekerja (18 tahun ke atas) (RM2,004 seorang)
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <select
                    id="B3"
                    value={values.B3 || ""}
                    onChange={(e) => handleInputChange(e, "B3")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  >
                    <option value={0}>Tiada</option>
                    {[...Array(10)].map((a, number) => (
                      <option key={number + 1} value={number + 1}>
                        {number + 1} orang
                      </option>
                    ))}
                  </select>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B1.4</TableCell>
              <TableCell>Tanggungan Belajar IPT (RM 7,356 seorang)</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <select
                    id="B4"
                    value={values.B4 || ""}
                    onChange={(e) => handleInputChange(e, "B4")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  >
                    <option value={0}>Tiada</option>
                    {[...Array(10)].map((a, number) => (
                      <option key={number + 1} value={number + 1}>
                        {number + 1} orang
                      </option>
                    ))}
                  </select>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B1.5</TableCell>
              <TableCell>
                Tanggungan Berumur 7-17 tahun (RM4,896 seorang)
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <select
                    id="B5"
                    value={values.B5 || ""}
                    onChange={(e) => handleInputChange(e, "B5")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  >
                    <option value={0}>Tiada</option>
                    {[...Array(10)].map((a, number) => (
                      <option key={number + 1} value={number + 1}>
                        {number + 1} orang
                      </option>
                    ))}
                  </select>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B1.6</TableCell>
              <TableCell>
                Tanggungan 6 Tahun Ke Bawah (RM2,100 seorang){" "}
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <select
                    id="B6"
                    value={values.B6 || ""}
                    onChange={(e) => handleInputChange(e, "B6")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  >
                    <option value={0}>Tiada</option>
                    {[...Array(10)].map((a, number) => (
                      <option key={number + 1} value={number + 1}>
                        {number + 1} orang
                      </option>
                    ))}
                  </select>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SectionB1;
