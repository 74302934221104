import { Divider, Typography } from "@mui/material";
import Module from "./Module";

function Address() {
  return (
    <div>
      <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
        Alamat
      </Typography>
      <Divider />
      <div style={{ width: "100vw" }}>
        <Module />
      </div>
    </div>
  );
}

export default Address;
