import React from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import parse from "html-react-parser";

// import AlertTitle from "@mui/material/AlertTitle";

import ProductByAgency from "../ProductByAgency";

import "./AgencyPage.css";

interface DialogProps {
  user?: any;
  data?: any;
}

const InfoPanel = (props: DialogProps) => {
  const { id } = useParams();

  const [formdata, setFormData] = React.useState<any>();
  const [curid, setCurid] = React.useState<string>("");

  React.useEffect(() => {
    let isMounted = true;

    // console.log(id);
    if (
      typeof id !== "undefined" &&
      id !== "" &&
      id !== "new" &&
      id !== curid
    ) {
      if (isMounted) {
        setCurid(id);
        setFormData(undefined);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [id, curid]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      // setLoading(true);
      fetch("/api/public/agency/detail/" + curid)
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setFormData(data.agency);
            // if (data.agency?.photos.length > 0) {
            //   setActivePhoto(data.agency?.photos[0]);
            // }
            // setLoading(false);
          }
        });
    };

    // console.log(id);
    if (
      typeof curid !== "undefined" &&
      curid !== "" &&
      curid !== "new" &&
      typeof formdata === "undefined"
    ) {
      // console.log(curid);
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [formdata, curid]);

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {formdata?.id ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">{formdata?.name}</Typography>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <div
                    style={{
                      backgroundImage: `${
                        "url(" +
                        (formdata?.photourl
                          ? formdata?.photourl
                          : "/images/default.png") +
                        ")"
                      }`,
                    }}
                    className="agencythumbnail"
                  >
                    &nbsp;
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={9} lg={9}>
              <Paper elevation={3} style={{ padding: "20px" }}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {formdata?.description && (
                    <Grid item xs={12}>
                      {parse(
                        formdata?.description ? formdata?.description : ""
                      )}
                    </Grid>
                  )}

                  {formdata?.email && (
                    <Grid item xs={12}>
                      {formdata?.email}
                    </Grid>
                  )}

                  {formdata?.url && (
                    <Grid item xs={12}>
                      {formdata?.url}
                    </Grid>
                  )}

                  {formdata?.address && (
                    <Grid item xs={12}>
                      {formdata?.address}
                    </Grid>
                  )}

                  {formdata?.keluruhan_name && (
                    <Grid item xs={12}>
                      {formdata?.keluruhan_name}
                    </Grid>
                  )}

                  {formdata?.postcode && (
                    <Grid item xs={12}>
                      {formdata?.postcode}
                    </Grid>
                  )}

                  {formdata?.kecamatan_name && (
                    <Grid item xs={12}>
                      {formdata?.kecamatan_name}
                    </Grid>
                  )}

                  {formdata?.kota_name && (
                    <Grid item xs={12}>
                      {formdata?.kota_name}
                    </Grid>
                  )}

                  {formdata?.provinsi_name && (
                    <Grid item xs={12}>
                      {formdata?.provinsi_name}
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h5">Page Not Found</Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          {formdata?.id && <ProductByAgency curid={formdata?.id} />}
        </Grid>
      </Grid>
    </>
  );
};

export default InfoPanel;
