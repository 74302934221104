import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
// import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { auth } from "../../config/Firebase";
// import type { RootState } from "../../store/index";
import "./Index.css";
import Product from "../../components/Store/Product";

const StoreSelectedProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [token, setToken] = React.useState<any>();
  const [products, setProducts] = React.useState<any[]>();
  const [category, setCategory] = React.useState<any>({});
  // const { tenant } = useSelector((state: RootState) => state.userState);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        } else {
          setToken("");
        }
      } else {
        setToken("");
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;

    const fetchData = () => {
      let fetchurl: string = "/api/public/product/list";
      if (token !== "") {
        fetchurl = "/api/product/list";
      }
      if (id && id !== "") {
        fetchurl += "?category=" + id;
      }
      fetch(fetchurl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            if (data?.list) {
              setProducts(data?.list);
            }
            if (data?.metadata?.category) {
              setCategory(data?.metadata?.category);
            }
          }
        });
    };

    if (typeof products === "undefined" && typeof token !== "undefined") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, id, products]);

  const callback = (product?: any) => {
    console.log(product);
    navigate("/store/product/" + product?.id);
  };

  return (
    <Grid
      container
      spacing={2}
      padding={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {/* <Grid item xs={12}>
        <Typography variant="body2" className="productcategory_label">
          Katalog
        </Typography>
      </Grid> */}
      {category.id && (
        <>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              textAlign: "center",
            }}
          >
            <div
              className="productcategory_thumbnail"
              style={{
                backgroundImage: `${"url(" + category?.photourl + ")"}`,
              }}
            >
              &nbsp;
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <div className="productcategory_label_container">
              <Typography variant="body2" className="productcategory_label">
                {category?.name}
              </Typography>
              <Typography variant="body2">{category?.description}</Typography>
            </div>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Typography variant="body2" className="productcategory_label">
          Produk
        </Typography>
      </Grid>
      {products && products.length <= 0 ? (
        <Grid item xs={12}>
          <Typography variant="body2">
            Tiada produk didaftarkan di bawah kategori ini.
          </Typography>
        </Grid>
      ) : (
        <>
          {products &&
            products.map((product: any, idx: any) => (
              <Grid item xs={6} sm={4} md={3} lg={2} xl={1} key={idx}>
                <Product product={product} callback={callback} />
              </Grid>
            ))}
        </>
      )}
    </Grid>
  );
};

export default StoreSelectedProduct;
