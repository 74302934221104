import { Outlet, Route, Routes, useLocation } from "react-router-dom";

import EmailIcon from "@mui/icons-material/AlternateEmail";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import MarkerIcon from "@mui/icons-material/Room";
import TwitterIcon from "@mui/icons-material/Twitter";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
// import { auth } from "../../config/Firebase";
import type { RootState } from "../../store/index";
import Agency from "./Agency";
import AgencyPage from "./Agency/AgencyPage";
import Browse from "./Browse";
import Carousel from "./Carousel";
import Checkout from "./Checkout";
import Default from "./Default";
import "./Index.css";
import MyAccount from "./MyAccount";
import RatingProduct from "./MyAccount/RatingProduct";
import Orders from "./Orders";
import ProductInfo from "./ProductInfo";
import ProductByCategory from "./ProductLists";
import ShoppingCart from "./ShoppingCart";
import ZakatPendapatan from "../../components/ZakatForm/Pendapatan";

const StoreIndex = () => {
  const location = useLocation();
  // const [token, setToken] = React.useState<any>("");
  const { tenant } = useSelector((state: RootState) => state.userState);

  // React.useEffect(() => {
  //   auth.onAuthStateChanged((user: any) => {
  //     if (user) {
  //       if (typeof user !== "undefined") {
  //         user.getIdToken().then((e: any) => {
  //           setToken(e);
  //         });
  //       }
  //     } else {
  //       console.log("No user detected");
  //     }
  //   });
  // }, []);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        // minHeight: "calc(100vh - 70px)",
      }}
    >
      {/\/store$/.test(location.pathname) && (
        <div
          // className="storeheader"
          style={{
            // backgroundImage: `url(${tenant?.store_banner})`,
            // padding: "20px",
            // height: "300px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginBottom: "50px",
            // border: "1px solid #ff0000",
          }}
        >
          <Carousel />
          {/* <div className="storeheadercontent">
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Typography variant="h2" style={{ fontWeight: "bold" }}>
                  SELAMAT DATANG KE PASARHALAL
                </Typography>
              </Grid>
              <Grid item xs={12} fontSize={19}>
                CrowdZakat menyediakan platform terintegrasi untuk UKM
                melakukan penjualan tanpa dipungut biaya diawal! Ayo miliki
                tokomu sekarang Juga!
              </Grid>
            </Grid>
          </div> */}
        </div>
      )}

      <Container
        // fixed
        className={
          /\/store\/(browse$|myaccount)/.test(location.pathname)
            ? "container_browse"
            : "container_other"
        }
      >
        <Routes>
          <Route path="*" element={<Default />}></Route>
          <Route path="product/:id" element={<ProductInfo />} />
          <Route path="catagory/:id/product" element={<ProductByCategory />} />
          <Route path="cart" element={<ShoppingCart />} />
          <Route path="checkout/:state" element={<Checkout />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="browse" element={<Browse />} />
          <Route path="browse/category/:cat" element={<Browse />} />
          <Route path="browse/product/:id" element={<ProductInfo />} />
          <Route path="orders/:id" element={<Orders />} />
          <Route path="myaccount/*" element={<MyAccount />} />
          <Route path="agency" element={<Agency />} />
          <Route path="agency/directory/:id" element={<Agency />} />
          <Route path="page/:id" element={<AgencyPage />} />
          <Route path="zakat/form/pendapatan" element={<ZakatPendapatan />} />
          {/* <Route path="rating" element={<RatingProduct />} /> */}
        </Routes>

        <Outlet />
      </Container>

      <div
        style={{
          backgroundColor: tenant?.theme_color,
          // padding: "20px",
          // height: "300px",
          justifyContent: "center",
          alignItems: "center",
          //   position: "absolute",
          bottom: 0,
          width: "100%",
          color: tenant?.theme_font_color,
        }}
      >
        <Container fixed>
          <Grid
            container
            spacing={2}
            padding={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={8}>
              {/* <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Contact Us
              </Typography> */}
              <Grid
                container
                spacing={2}
                padding={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <img
                    src={
                      tenant?.logo_small
                        ? tenant?.logo_small
                        : "/images/default.png"
                    }
                    alt="CrowdZakat by LZS"
                    style={{
                      maxHeight: "50px",
                    }}
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <MarkerIcon />
                </Grid>
                <Grid item xs={11} md={11}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Lembaga Zakat Selangor
                  </Typography>
                  <Typography variant="body2">
                    Bangunan Zakat Selangor 3-01-1, Presint ALAMi, Pusat
                    Perniagaan Worldwide 2, Persiaran Akuatik, Seksyen 13, 40100
                    Shah Alam, Selangor Darul Ehsan
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1}>
                  <PhoneIcon />
                </Grid>
                <Grid item xs={11} md={11}>
                  <Typography variant="body2">
                    Talian Zakat Selangor: 1-300-88-4343
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1}>
                  <EmailIcon />
                </Grid>
                <Grid item xs={11} md={11}>
                  <Typography variant="body2">
                    maklumbalasaduan@zakatselangor.com.my
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={5}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                WAKTU OPERASI CAWANGAN LZS
              </Typography>
              <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                Hari bekerja :
              </Typography>
              <ul className="footerlist">
                <li>Talian Zakat Selangor : 8.30am - 5.30pm</li>
                <li>Talian Umum : 8.30am- 5.00pm</li>
              </ul>
              <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                Waktu Operasi Cawangan LZS (Selain UTC Shah Alam)
              </Typography>
              <ul className="footerlist">
                <li>Isnin - Jumaat : 8.30 pagi - 4.30 petang</li>
                <li>Rehat Jumaat : 12:15 petang - 2:45 petang </li>
                <li>Sabtu, Ahad & cuti umum : TUTUP </li>
              </ul>
              <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                Waktu Operasi Cawangan UTC Shah Alam :
              </Typography>
              <ul className="footerlist">
                <li>Isnin - Jumaat : 8.30 pagi - 7:00 malam </li>
                <li>Rehat Jumaat : 12:15 petang - 2:45 petang</li>
                <li>Sabtu - Ahad : 8:30 pagi – 5:00 petang</li>
                <li>Cuti Umum : TUTUP</li>
              </ul>
            </Grid> */}
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Find Us In
              </Typography>
              <Grid
                container
                spacing={2}
                padding={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={1} md={2}>
                  <InstagramIcon />
                </Grid>
                <Grid item xs={11} md={10}>
                  <Typography variant="body2">@zakatselangor</Typography>
                </Grid>
                <Grid item xs={1} md={2}>
                  <FacebookIcon />
                </Grid>
                <Grid item xs={11} md={10}>
                  <Typography variant="body2">zakatselangor</Typography>
                </Grid>
                <Grid item xs={1} md={2}>
                  <TwitterIcon />
                </Grid>
                <Grid item xs={11} md={10}>
                  <Typography variant="body2">@zakatselangor</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", padding: "10px" }}>
            <Typography variant="caption">Copyright LZS 2023</Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", padding: "10px" }}>
            <Typography variant="body2">
              CrowdZakat adalah platform yang membolehkan pemnbayar zakat
              menentukan agihan zakat mengikut kehendak sendiri.
            </Typography>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default StoreIndex;
