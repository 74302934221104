import { makeStyles } from "@material-ui/core/styles";
import React from "react";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import CardMedia from "@material-ui/core/CardMedia";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Routes from "../config/Routes";
import { Paper } from "@mui/material";
import Back from "../components/Back";

// import type { RootState } from "../store/index";
// import Maps from "./ModuleMaps";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: "100%",
  },
  media: {
    height: 80,
  },
  rootheader: {
    marginTop: "20px",
  },
});

interface PropsTotal {
  user?: any;
}
const Home: React.FC<PropsTotal> = ({ user }) => {
  const classes = useStyles();
  // const { profile } = useSelector((state: RootState) => state.userState);

  // console.log(profile);
  return (
    <>
      <Paper elevation={6} sx={{ padding: "10px" }}>
        <Grid
          className={classes.rootheader}
          item
          xs={12}
          style={{ color: "#0450AA", fontWeight: "bolder" }}
        >
          <div style={{ display: "flex" }}>
            <Back />
            <Typography variant="h6" component="h5">
              PEDi Module
            </Typography>
          </div>

          <div style={{ flex: 2, height: "1px", backgroundColor: "#707070" }} />
        </Grid>
        {/* <div className="mapcontainer_module"><Maps /></div> */}
        {/* {profile?.position !== "ADMIN" && (
      )} */}
        {/* <div className="mapcontainer_module">
          <Maps />
        </div> */}
        {/* <Paper> */}
        <Grid
          container
          spacing={4}
          direction="row"
          justifyContent="flex-end"
          alignItems="stretch"
          className={classes.rootheader}
          // style={{
          //   flex: 0,
          //   padding: 20,
          // }}
        >
          {/* {profile?.position !== "ADMIN" && (
          <Grid item xs={12}>
            <div className="mapcontainer_module_dummy"></div>
          </Grid>
        )} */}

          {Routes.map((route: any, index: number) => {
            if (/\/module\//i.test(route.path) && route.module) {
              return (
                <Grid
                  item
                  xs={10}
                  sm={3}
                  key={index}
                  style={{ textAlign: "left" }}
                >
                  <Card className={classes.root} key={index}>
                    <CardActionArea component={Link} to={route?.path}>
                      <CardContent>
                        <Grid
                          container
                          spacing={0}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <img
                              src={route?.icon}
                              alt={route?.name}
                              style={{
                                height: 70,
                                width: 70,
                                alignItems: "left",
                              }}
                            />
                            {/* <HomeIcon /> */}
                          </Grid>
                          <Grid item sm={12} md={10}>
                            <Typography
                              gutterBottom
                              variant="subtitle2"
                              component="p"
                            >
                              {route?.name}
                            </Typography>
                          </Grid>
                          <Grid item sm={12} md={1}>
                            <ArrowCircleRightIcon
                              style={{ color: "#0074C8" }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            } else {
              return "";
            }
          })}
        </Grid>
      </Paper>
    </>
  );
};

export default Home;
