import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { auth } from "../../config/Firebase";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MuiAlert from "@material-ui/lab/Alert";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import type { RootState } from "../../store/index";
import { AuthDisplay } from "../../config/Acl";
import Breadcrumbs from "../Breadcrumbs";
// import { preProcessFile } from "typescript";
import "./Acl.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  margin: {
    // margin: theme.spacing(1),
  },
  textField: {
    width: "100%",
  },
  rootheader: {
    flexGrow: 1,
    // height: "100%",
    width: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.5) !important",
    border: "0x solid #ff0000",
    margin: "0px !important",
    padding: "0px !important",
    marginBottom: "5px !important;",
    marginTop: "5px !important",
  },
  center: {
    textAlign: "center",
  },
  label: {
    backgroundColor: "#ffffff",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

interface PropsTotal {
  id?: string;
  callback?: (data: object | null) => void;
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UserForm: React.FC<PropsTotal> = (props) => {
  const classes = useStyles();
  const [acls, setAcls] = React.useState<any>();
  const [form, setForm] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [querystatus, setQuerystatus] = React.useState<string>("");
  const [token, setToken] = React.useState<any>("");
  const { profile } = useSelector((state: RootState) => state.userState);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/user/acl", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setAcls(data);
            let newform: any = {};
            for (let d = 0; d < data?.modcats.length; d++) {
              newform[
                data?.modcats[d].module_id + "_" + data?.modcats[d].user_cat
              ] = data?.modcats[d].access;
            }
            // console.log(newform);
            setForm(newform);
          }
        });
    };

    if (token && typeof acls === "undefined") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, acls]);

  const setValue = (id: string) => {
    setForm({
      ...form,
      [id]: form[id] > 0 ? 0 : 1,
    });
    console.log(id);
  };

  const updateUserProfile = () => {
    setLoading(true);
    const data = new URLSearchParams();
    data.append("acls", JSON.stringify(form));

    if (typeof form === "undefined") {
      window.alert("Missing required fields.");
      setLoading(false);
      return false;
    }

    fetch("/api/user/acl", {
      method: "post",
      body: data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.acls);
        setQuerystatus(data.status);
        setLoading(false);
      });
  };

  // console.log(profile.position);

  if (
    typeof profile.position === "undefined" ||
    (typeof profile.position !== "undefined" && profile.position !== "ADMIN")
  ) {
    return <AuthDisplay />;
  } else {
    let modsize: number = 1;
    let namesize: number = 4;
    if (typeof acls?.category !== "undefined" && acls?.category.length <= 7) {
      modsize = 2;
      namesize = 11 - modsize * acls?.category.length;
    } else {
      namesize = 11 - acls?.category.length;
    }

    return (
      <>
        <Typography variant="subtitle1">Access Control Manager</Typography>

        <Breadcrumbs
          current={"Access Control Manager"}
          paths={[
            { name: "Pengurusan", url: "/dashboard" },
            // { name: "Applications", url: "/documents/application" },
          ]}
          className="margintop"
        />

        <Paper elevation={0} className={"papercontainer margintop"}>
          <Grid container spacing={0}>
            {querystatus !== "" && (
              <Grid
                item
                xs={10}
                style={{
                  paddingBottom: "20px",
                  paddingTop: "20px",

                  // color: "#0F2D72",
                }}
              >
                {querystatus === "UPDATED" && (
                  <Alert severity="success">
                    ACL Profile has been updated.
                  </Alert>
                )}
                {querystatus === "SAVED" && (
                  <Alert severity="success">ACL Profile has been saved.</Alert>
                )}
                {querystatus === "ERROR" && (
                  <Alert severity="error">Query failed - Unknown Reason.</Alert>
                )}
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.rootheader} spacing={0}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                className="aclheader"
                style={{ color: "#0F0101" }}
              >
                <Grid
                  item
                  xs={1}
                  style={{ textAlign: "right", paddingRight: "10px" }}
                >
                  ID
                </Grid>
                <Grid item xs={namesize}>
                  Module
                </Grid>
                {acls?.category &&
                  acls?.category.map((cat: any, idx: number) => (
                    <Grid item xs={modsize} key={idx}>
                      {cat?.description}
                    </Grid>
                  ))}
              </Grid>
              {acls?.modules &&
                acls?.modules.map((module: any, isx: number) => (
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    key={isx}
                    className="aclborder"
                  >
                    <Grid
                      item
                      xs={1}
                      style={{ textAlign: "right", paddingRight: "10px" }}
                    >
                      {isx + 1}.
                    </Grid>
                    <Grid item xs={namesize}>
                      {module?.description}
                    </Grid>
                    {acls?.category &&
                      acls?.category.map((cat: any, idx: number) => (
                        <Grid
                          item
                          xs={modsize}
                          key={idx}
                          style={{ textAlign: "center" }}
                        >
                          <IconButton
                            aria-label="access"
                            className={classes.margin}
                            size="small"
                            onClick={() => setValue(module?.id + "_" + cat?.id)}
                          >
                            {form && form[module?.id + "_" + cat?.id] ? (
                              <CheckIcon style={{ color: "green" }} />
                            ) : (
                              <ClearIcon style={{ color: "red" }} />
                            )}
                          </IconButton>
                        </Grid>
                      ))}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Paper>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.rootheader}
        >
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => updateUserProfile()}
            >
              Update
            </Button>
          </Grid>
          {loading ? (
            <>
              <Grid item xs={12} sm={1} style={{ textAlign: "center" }}>
                <CircularProgress size={20} />
              </Grid>
              <Grid item xs={12} sm={9}>
                Updating....
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={10}></Grid>
          )}
        </Grid>
      </>
    );
  }
};
export default UserForm;
