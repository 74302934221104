import React from "react";
// import MenuItem from "@mui/material/MenuItem";
// import { SelectChangeEvent } from "@mui/material/Select";
// import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import { Headers, fetchPlus } from "../../store/headers";

interface PropsTotal {
  id: string;
  label?: string;
  row?: number | string;
  defaultValue?: string;
  setValue?: (
    id: string,
    value: string | AgencyArray[],
    row?: number | string
  ) => void;
  filter?: number | string;
  data?: any[];
  token?: string;
}

export interface Agency {
  id?: number;
  label?: string;
}

export interface AgencyArray {
  id: number;
  label?: string;
}

const SelectAgencyAutocomplete: React.FC<PropsTotal> = (props) => {
  const { id, setValue, row, data, token, label } = props;
  const [newvalue, setNewValue] = React.useState<any>("");
  const [agencylist, setAgencyList] = React.useState<Agency[]>();
  // const [selectagent, setSelectagent] = React.useState<string>(
  //   defaultValue || ""
  // );

  React.useEffect(() => {
    let isMounted = true;

    if (typeof token !== "undefined" && token !== "") {
      if (typeof data !== "undefined" && data.length > 0) {
        setAgencyList(data);
      } else {
        fetch("/api/agency/list?compact=true", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (isMounted) {
              // setAgencyList(data.agencies);
              let newdata: any[] = [];
              for (let a = 0; a < data.agencies.length; a++) {
                newdata.push({
                  id: data.agencies[a].id,
                  label: data.agencies[a].id + " - " + data.agencies[a].name,
                });
              }
              setAgencyList(newdata);
            }
          });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [data, token]);

  // React.useEffect(() => {
  //   if (typeof defaultValue !== "undefined") {
  //     setSelectagent(defaultValue);
  //   }
  // }, [defaultValue]);

  // const handleInputChange = (event?: any) => {
  //   if (typeof event !== "undefined" && event.target && event.target.value) {
  //     setSelectagent(event.target.value);
  //     if (setValue) {
  //       setValue(id, event.target.value, row);
  //     }
  //   }
  // };

  // const handleChange = (event?: SelectChangeEvent) => {
  //   if (typeof event !== "undefined" && event.target && event.target.value) {
  //     setSelectagent(event.target.value);
  //     if (setValue) {
  //       setValue(id, event.target.value, row);
  //     }
  //   }
  // };

  if (typeof agencylist !== "undefined") {
    return (
      <>
        {label && (
          <InputLabel id={id ? id : "agency"}>{label ? label : id}</InputLabel>
        )}
        <Autocomplete
          disabled={false}
          getOptionLabel={(option) => (option?.label ? option?.label : "")}
          onChange={(event: any, newValue: any) => {
            // console.log(newValue);
            setNewValue(newValue);
            if (typeof setValue !== "undefined") {
              if (newValue === null) {
                setValue(id, "", row);
              } else {
                setValue(id, newValue?.id, row);
              }
            }
          }}
          value={newvalue ? newvalue : ""}
          id={id ? id : "agency"}
          options={agencylist ? agencylist : []}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} label="Toko/Agency" />
          )}
          size="small"
          isOptionEqualToValue={(option: any, value: any) => {
            return option?.label === value.label;
          }}
        />
        {/* <Autocomplete
          value={newvalue ? newvalue : ""}
          getOptionLabel={(option) =>
            option?.name ? option?.name.toString() : ""
          }
          onChange={(event: any, newValue: string | null) => {
            setNewValue(newValue?.id);
          }}
          inputValue={inputValue ? inputValue : ""}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controllable-states-demo"
          isOptionEqualToValue={(option, value) => option.id.toString() === value.id.toString()}}
          options={agencylist ? agencylist : []}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Controllable" />
          )}
        /> */}
        {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={agencylist}
          fullWidth
          getOptionLabel={(option) =>
            option?.name ? option?.name.toString() : ""
          }
          onChange={(event: any, newValue: string | null) => {
            // setValue(newValue);
            console.log(newValue?.id);
          }}
          inputValue={inputValue ? inputValue : ""}
          onInputChange={(event, newInputValue) => {
            setInputValue(inputValue);
            // console.log(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              // labelId={id}
              label={label ? label : id}
              id={id ? id : "agency"}
              value={selectagent}
              onChange={handleInputChange}
            />
          )}
        /> */}
        {/* <Select
          labelId={id}
          label={label ? label : id}
          id={id ? id : "agency"}
          value={selectagent}
          onChange={handleChange}
        >
          {agencylist &&
            agencylist.map((count: any, idx) => (
              <MenuItem key={idx} value={count.id ? count.id.toString() : ""}>
                {count.name}
              </MenuItem>
            ))}
        </Select> */}
      </>
    );
  } else {
    return <></>;
  }
};
export default SelectAgencyAutocomplete;
