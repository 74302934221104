import React, { useCallback } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
// import Input from "@mui/material/Input";
// import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { auth } from "../../config/Firebase";
import type { RootState } from "../../store/index";
import Product from "../../components/Store/Product";

import Slider from "../../components/Slider";
import { localeCurrency } from "../../components/Store/Store";

import "./Browse.css";
// import { min } from "date-fns";

const StoreBrowser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { cat } = useParams();

  const [searchParams] = useSearchParams();
  const { tenant } = useSelector((state: RootState) => state.userState);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchloading, seSearchLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<any>({
    min: 0.0 * 1000000,
    max: 1.0 * 1000000,
    q: searchParams.get("q"),
  });
  const [price, setPrice] = React.useState<any>({
    min: 0,
    max: 100,
  });
  const [token, setToken] = React.useState<any>();
  const [categories, setCategories] = React.useState<any[]>();
  const [searchresults, setSearchResults] = React.useState<any[]>();
  const [total, setTotal] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(1);
  const [perpage] = React.useState<number>(10);
  const [q, setQ] = React.useState<string>();
  const [catsearch, setCatSearch] = React.useState<boolean>();
  const [provinsi, setProvinsi] = React.useState<any[]>();
  const [kota, setKota] = React.useState<any[]>();

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        } else {
          setToken("");
        }
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/public/ref/provinsi")
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setProvinsi(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (typeof provinsi === "undefined") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [provinsi]);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetch("/api/public/ref/kota/" + form?.provinsi)
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setKota(data.list);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
        });
    };
    if (
      typeof form?.provinsi !== "undefined" &&
      form?.provinsi !== "" &&
      typeof kota === "undefined"
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [kota, form]);

  React.useEffect(() => {
    let isMounted = true;

    const fetchData = () => {
      setLoading(true);
      let fetchurl: string = "/api/public/category/list";
      if (typeof token !== "undefined" && token !== "") {
        fetchurl = "/api/category/list";
      }
      fetch(fetchurl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        // fetch("/api/public/category/list")
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            if (data?.list) {
              for (let l = 0; l < data?.list.length; l++) {
                // data.list[l].checked = true;
                if (data.list[l].category_id.toString() === cat) {
                  data.list[l].checked = true;
                  setCatSearch(true);
                }
              }

              setCategories(data?.list);
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    if (typeof categories === "undefined") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, categories, cat]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    idx?: number
  ) => {
    if (
      typeof categories !== "undefined" &&
      categories.length > 0 &&
      typeof idx !== "undefined" &&
      idx >= 0
    ) {
      if (typeof categories[idx] !== "undefined") {
        let newcategories = JSON.parse(JSON.stringify(categories));
        newcategories[idx].checked = event.target.checked;
        setCategories(newcategories);
      }
    }
    // setChecked(event.target.checked);
  };

  const doSearch = useCallback(
    (passpage?: number) => {
      seSearchLoading(true);

      if (typeof form.q !== "undefined" && form.q !== "" && form.q !== null) {
        window.history.pushState(null, "", location.pathname + "?q=" + form.q);
      }

      let cats: string[] = [];
      if (typeof categories !== "undefined" && categories.length > 0) {
        for (let c = 0; c < categories.length; c++) {
          if (categories[c].checked) {
            cats.push(categories[c].category_id);
          }
        }
      }
      // const data = new URLSearchParams();
      // data.append("q", form.q);
      // data.append("min", form.min);
      // data.append("max", form.max);
      // data.append("categories", JSON.stringify(cats));
      let newparams: string[] = [];
      if (
        typeof form?.q !== "undefined" &&
        form?.q !== "" &&
        form?.q !== null
      ) {
        newparams.push("q=" + form?.q);
      }
      if (form?.min !== "") {
        newparams.push("min=" + form?.min);
      }
      if (form?.max !== "") {
        newparams.push("max=" + form?.max);
      }
      if (typeof form?.provinsi !== "undefined" && form?.provinsi !== "") {
        newparams.push("provinsi=" + form?.provinsi);
      }
      if (typeof form?.kota !== "undefined" && form?.kota !== "") {
        newparams.push("kota=" + form?.kota);
      }
      if (cats.length > 0) {
        newparams.push("categories=" + JSON.stringify(cats));
      }
      newparams.push(
        "start=" +
          ((typeof passpage !== "undefined" ? passpage : page) - 1) * perpage
      );
      newparams.push("limit=" + perpage);

      if (newparams.length < 1) {
        window.alert("Missing required fields.");
        setLoading(false);
        return false;
      }

      let fetchurl: string = "/api/public/product/list";
      if (typeof token !== "undefined" && token !== "") {
        fetchurl = "/api/product/list";
      }

      fetch(fetchurl + "?" + newparams.join("&"), {
        // method: "post",
        // body: data,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setSearchResults(data.list);
          setTotal(data?.metadata?.total ? data?.metadata?.total : 0);
          seSearchLoading(false);
        })
        .catch(() => {
          seSearchLoading(false);
        });
    },
    [categories, form, page, perpage, token, location.pathname]
  );

  // const doSearch2 = (passpage?: number) => {
  //   setLoading(true);

  //   if (typeof form.q !== "undefined" && form.q !== "" && form.q !== null) {
  //     window.history.pushState(null, "", location.pathname + "?q=" + form.q);
  //   }

  //   let cats: string[] = [];
  //   if (typeof categories !== "undefined" && categories.length > 0) {
  //     for (let c = 0; c < categories.length; c++) {
  //       if (categories[c].checked) {
  //         cats.push(categories[c].category_id);
  //       }
  //     }
  //   }
  //   // const data = new URLSearchParams();
  //   // data.append("q", form.q);
  //   // data.append("min", form.min);
  //   // data.append("max", form.max);
  //   // data.append("categories", JSON.stringify(cats));
  //   let newparams: string[] = [];
  //   if (typeof form?.q !== "undefined" && form?.q !== "" && form?.q !== null) {
  //     newparams.push("q=" + form?.q);
  //   }
  //   if (form?.min !== "") {
  //     newparams.push("min=" + form?.min);
  //   }
  //   if (form?.max !== "") {
  //     newparams.push("max=" + form?.max);
  //   }
  //   if (cats.length > 0) {
  //     newparams.push("categories=" + JSON.stringify(cats));
  //   }
  //   newparams.push(
  //     "start=" +
  //       ((typeof passpage !== "undefined" ? passpage : page) - 1) * perpage
  //   );
  //   newparams.push("limit=" + perpage);

  //   if (newparams.length < 1) {
  //     window.alert("Missing required fields.");
  //     setLoading(false);
  //     return false;
  //   }

  //   let fetchurl: string = "/api/public/product/list";
  //   if (typeof token !== "undefined" && token !== "") {
  //     fetchurl = "/api/product/list";
  //   }

  //   fetch(fetchurl + "?" + newparams.join("&"), {
  //     // method: "post",
  //     // body: data,
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       setSearchResults(data.list);
  //       setTotal(data?.metadata?.total ? data?.metadata?.total : 0);
  //       setLoading(false);
  //     });
  // };

  React.useEffect(() => {
    if (
      typeof q === "undefined" &&
      searchParams.get("q") !== "" &&
      searchParams.get("q") !== null
    ) {
      setQ(searchParams.get("q"));
      doSearch(1);
    }
  }, [q, searchParams, doSearch]);

  React.useEffect(() => {
    if (
      typeof categories !== "undefined" &&
      typeof catsearch !== "undefined" &&
      catsearch &&
      catsearch !== null
    ) {
      doSearch(1);
    }
  }, [catsearch, categories, doSearch]);

  const callback = (product?: any) => {
    // console.log(product);
    navigate("/store/browse/product/" + product?.id);
  };

  const handleOptionChange = (event: SelectChangeEvent) => {
    if (event.target.name === "provinsi") {
      setKota(undefined);
      setForm({
        ...form,
        kota: "",
        [event.target.name]: event.target.value,
      });
    } else if (event.target.name === "kota") {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
    }
  };

  // console.log(categories);

  return (
    <div className="browse_container">
      <div className="browse_sidebar">
        <img
          src="/images/default.png"
          width={250}
          height={0}
          alt={"hidden"}
          // style={{ width: "250px" }}
        />
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Tapisan Pencarian
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Kata kunci:</Typography>
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              size="small"
              value={form?.q ? form?.q : ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setForm({ ...form, q: event.target.value });
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    // onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                    onClick={() => doSearch(1)}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              // label="Find"
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                Provinsi:
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <Select
                    name="provinsi"
                    value={provinsi && form?.provinsi ? form?.provinsi : ""}
                    onChange={handleOptionChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {provinsi &&
                      provinsi.map((item: any, idx: any) => (
                        <MenuItem value={item?.code} key={idx}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                Kota/Kabupaten:
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <Select
                    name="kota"
                    value={kota && form?.kota ? form?.kota : ""}
                    onChange={handleOptionChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {kota &&
                      kota.map((item: any, idx: any) => (
                        <MenuItem value={item?.code} key={idx}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid> */}

          <Grid item xs={12}>
            <Typography variant="body2">Katalog:</Typography>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <FormGroup>
                {categories &&
                  categories.map((category: any, idx: any) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            category?.checked ? category?.checked : false
                          }
                          onChange={(e: any) => handleChange(e, idx)}
                        />
                      }
                      label={category?.category_name}
                      key={idx}
                    />
                  ))}
              </FormGroup>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Kisaran harga:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Slider
              type="price"
              range={[price?.min, price?.max]}
              callback={(r: any) => {
                setPrice({
                  min: r[0],
                  max: r[1],
                });
                setForm({
                  ...form,
                  min: (r[0] / 100) * 1000000,
                  max: (r[1] / 100) * 1000000,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {localeCurrency(form?.min)}
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
            {localeCurrency(form?.max)}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              style={{
                backgroundColor: tenant?.theme_color ? tenant?.theme_color : "",
                color: tenant?.theme_font_color ? tenant?.theme_font_color : "",
              }}
              onClick={() => doSearch(1)}
            >
              Cari
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="browse_content">
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Hasil Carian
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">Hasil ditemukan: {total}</Typography>
          </Grid>
          <Grid item xs={12}>
            {searchloading ? (
              <CircularProgress />
            ) : (
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {searchresults &&
                  searchresults.map((product: any, idx: any) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                      <Product product={product} callback={callback} />
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>

          {total > perpage && (
            <Grid item xs={12}>
              <Pagination
                count={Math.ceil(total / perpage)}
                boundaryCount={2}
                page={page}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  value: number
                ) => {
                  setPage(value);
                  doSearch(value);
                }}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default StoreBrowser;
