import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
// value used by filter

export interface AppUser {
  user: any;
  profile: any;
  tenant: any;
}

const initialState: AppUser = {
  user: {},
  profile: {},
  tenant: {},
};

const userStateSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<string>) {
      state.user = action.payload;
    },
    setProfile(state, action: PayloadAction<string>) {
      state.profile = action.payload;
    },
    setTenant(state, action: PayloadAction<string>) {
      state.tenant = action.payload;
    },
  },
});

export const { setUser, setProfile, setTenant } = userStateSlice.actions;

export default userStateSlice.reducer;
