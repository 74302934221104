import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
// value used by filter

export interface AppForm {
  label: string | any;
  search: string | any;
  pendingstatus: string | any;
  cartstatus: string | any;
  cartselected: any[] | any;
}

const initialState: AppForm = {
  label: "",
  search: "",
  pendingstatus: "",
  cartstatus: "",
  cartselected: [],
};

const formStateSlice = createSlice({
  name: "formState",
  initialState,
  reducers: {
    setLabel(state, action: PayloadAction<string>) {
      state.label = action.payload;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setPendingStatus(state, action: PayloadAction<string>) {
      state.pendingstatus = action.payload;
    },
    setCartStatus(state, action: PayloadAction<string>) {
      state.cartstatus = action.payload;
    },
    setCartSelected(state, action: PayloadAction<any[]>) {
      state.cartselected = action.payload;
    },
  },
});

export const {
  setLabel,
  setSearch,
  setPendingStatus,
  setCartStatus,
  setCartSelected,
} = formStateSlice.actions;

export default formStateSlice.reducer;
