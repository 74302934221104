import React from "react";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";

import { Paper } from "@mui/material";
import Back from "../Back";
import Breadcrumbs from "../Breadcrumbs";
import Module from "./Module";
import { auth } from "../../config/Firebase";
import { IsGrantedAccess, AuthDisplay } from "../../config/Acl";

import type { RootState } from "../../store/index";
import { useSelector } from "react-redux";
interface DialogProps {
  user?: any;
}

const IndexPanel = (props: DialogProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = React.useState<any>();
  const [token, setToken] = React.useState<any>("");
  const { profile } = useSelector((state: RootState) => state.userState);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      // setLoading(true);
      fetch("/api/category/find/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setCategory(data.category);
            // setLoading(false);
          }
        });
    };

    if (
      typeof category === "undefined" &&
      token !== "" &&
      typeof id !== "undefined" &&
      id !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [category, token, id]);
  const editAbleForm = (info?: any, type?: string) => {
    if (type === "new") {
      navigate(`/dashboard/product/form/new`);
    } else if (type === "info" && info?.id !== "" && info?.id !== "new") {
      navigate(`/dashboard/product/${info?.id}`);
    } else {
      navigate(`/dashboard/product/form/${info?.id ? info?.id : "new"}`);
    }
  };

  if (!IsGrantedAccess("orders", profile)) {
    return <AuthDisplay />;
  } else {
    return (
      <>
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <Back />
          <Typography variant="subtitle1" component="h6">
            Order Manager
          </Typography>
        </div>
        {typeof category?.id !== "undefined" ? (
          <Breadcrumbs
            current={category?.name ? category?.name : "Orders"}
            paths={[
              { name: "Pengurusan", url: "/administration" },
              { name: "Orders", url: "/dashboard/orders" },
            ]}
            className="margintop"
          />
        ) : (
          <Breadcrumbs
            current={"Produk"}
            paths={[{ name: "Pengurusan", url: "/administration" }]}
            className="margintop"
          />
        )}
        <Paper elevation={0} className={"papercontainer margintop"}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              {category && category?.id && (
                <>
                  <Grid
                    container
                    padding={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ borderBottom: "1px solid #dddddd" }}
                  >
                    <Grid item xs={12} sm={3} md={2} lg={1}>
                      <div
                        className="productthumbnail"
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "100%",
                          maxWidth: "50px",
                          backgroundImage:
                            "url(" +
                            (typeof category?.photourl !== "undefined" &&
                            category?.photourl !== ""
                              ? category?.photourl
                              : "/images/default.png") +
                            ")",
                        }}
                        title={category?.name ? category?.name : ""}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9} md={10} lg={11}>
                      <div>
                        <Typography variant="subtitle1">
                          {category?.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="body2">
                          {category?.description}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {/* <List
              // data={pik}
              callback={editAbleForm}
              selected={moduleCallback}
            /> */}
              <Module id={id} callback={editAbleForm} />
            </Grid>
            {/* {info && (
            <InfoDialog data={info} open={open} callback={infoCallback} />
          )} */}
          </Grid>
        </Paper>
      </>
    );
  }
};

export default IndexPanel;
