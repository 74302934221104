import React from "react";
// import {
//   Edit as EditIcon,
//   Article as ArticleIcon,
//   AddCircle as AddIcon,
// } from "@mui/icons-material";
import { format, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";

import Table from "../../../../components/Tables/DynamicTables";
import { auth } from "../../../../config/Firebase";

interface DialogProps {
  data?: any;
  id?: any;
  callback?: (data: any, type?: any) => void;
}

const IndexPanel = (props: DialogProps) => {
  const { callback } = props;
  const navigate = useNavigate();
  const [token, setToken] = React.useState<any>("");
  const [columns] = React.useState<any[]>([
    { accessorKey: "id", header: "ID", sortable: true, size: 50 },
    { accessorKey: "first_name", header: "Name Depan", sortable: true },
    { accessorKey: "last_name", header: "Name Belakang", sortable: true },
    { accessorKey: "phone_number", header: "Nombor Telefon", sortable: true },
    { accessorKey: "email", header: "Email", sortable: true },
    { accessorKey: "address", header: "Alamat", sortable: true },
    { accessorKey: "keluruhan_name", header: "Keluruhan", sortable: true },
    { accessorKey: "kecamatan_name", header: "Kecamatan", sortable: true },
    { accessorKey: "kabupaten_name", header: "Kabupaten", sortable: true },
    { accessorKey: "postcode", header: "Kode Pos", sortable: true },
    { accessorKey: "provinsi_name", header: "Provinsi", sortable: true },
  ]);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  const selectedCallback = (selecteddata?: any, type?: any) => {
    if (callback) {
      callback(selecteddata, type);
    }
  };

  return (
    <>
      {token ? (
        <Table
          enableRowNumbers={false}
          disabledPaper={true}
          api={"/api/address/list"}
          // apiDownload={"/api/order/download"}
          token={token}
          fieldid={"id"}
          arrayRef={"list"}
          dataCallback={(e: any) => {
            // console.log(e);
            // setCategory(e?.metadata?.category);
          }}
          onRowClick={(e: any) => {
            // console.log("here", e); //remove this if dont use
            // navigate(`/dbpedi/member/form/${e?.id}`);
            if (typeof selectedCallback !== "undefined") {
              // console.log(e);
              navigate(`/store/myaccount/address/info/${e?.id}`);
              // selectedCallback(e);
            }
          }}
          onFilterModelChange={(e: any) => {
            console.log(e); //remove this if dont use
          }}
          // prefixfilename={"-selling"}
          pagesize={20}
          autoheight={true}
          columns={columns}
          columnhides={["id"]}
          // rowactions={{
          //   name: "Actions",
          //   position: "last", //first|last
          //   buttons: [
          //     {
          //       icon: <ArticleIcon />,
          //       action: (row?: any) => {
          //         // console.log("here", row);
          //         selectedCallback(row, "info");
          //       },
          //     },
          //     {
          //       icon: <EditIcon />,
          //       action: (row?: any) => {
          //         // console.log("here", row, 'edit');
          //         selectedCallback(row, "edit");
          //       },
          //     },
          //   ],
          // }}
        />
      ) : (
        <>Loading...</>
      )}
    </>
  );
};

export default IndexPanel;
