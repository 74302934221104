import React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import NavItem from "../Sidebar/NavItem/Index";
import { auth } from "../../config/Firebase";
import type { RootState } from "../../store/index";
// import  from "./ProductCategory";
// import "./Index.css";

const SideBar = () => {
  // const [token, setToken] = React.useState<any>("");
  const [user, setUser] = React.useState<any>();
  const { profile } = useSelector((state: RootState) => state.userState);
  const [open] = React.useState(true);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            // setToken(e);
            setUser(user);
          });
        }
      }
    });
  }, []);

  return (
    <>
      {typeof profile?.id !== "undefined" && (
        <>
          {open && (
            <>
              <div>
                <Grid
                  container
                  padding={2}
                  spacing={1}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <div className="sidebar_name">
                      <Typography
                        variant="subtitle1"
                        style={{ textAlign: "center" }}
                      >
                        {profile?.staff_name ? profile?.staff_name : ""}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Avatar
                      alt={profile?.staff_name ? profile?.staff_name : ""}
                      src={
                        typeof profile.photo_url === "string"
                          ? profile.photo_url
                          : typeof user?.photoURL !== "undefined"
                          ? user?.photoURL
                          : ""
                      }
                      style={{
                        width: 70,
                        height: 70,
                        backgroundColor: "#ffffff",
                      }}
                      imgProps={{ referrerPolicy: "no-referrer" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      <i>
                        {profile?.contact_email ? profile?.contact_email : ""}
                      </i>
                    </Typography>
                  </Grid>
                  {profile?.agency_name &&
                    /MANAGER/i.test(profile?.position) && (
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          <i>
                            {profile?.agency_name ? profile?.agency_name : ""}
                          </i>
                        </Typography>
                      </Grid>
                    )}
                </Grid>
              </div>
              <div style={{ padding: "10px" }}>
                <NavItem />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SideBar;
