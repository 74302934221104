import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
// value used by filter

export interface AppMap {
  gmapIsLoaded: boolean | any;
  handler?: string | any;
}

const initialState: AppMap = {
  gmapIsLoaded: false,
};

const gmapStateSlice = createSlice({
  name: "gmapState",
  initialState,
  reducers: {
    setGmapIsLoaded(state, action: PayloadAction<string>) {
      state.gmapIsLoaded = action.payload;
    },
    setHandler(state, action: PayloadAction<string>) {
      state.handler = action.payload;
    },
  },
});

export const { setGmapIsLoaded, setHandler } = gmapStateSlice.actions;

export default gmapStateSlice.reducer;
