import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// const data = [
//   {
//     name: "Page A",
//     semasa: 4000,
//     kurang: 2400,
//     sasaran: 2400,
//   },
//   {
//     name: "Page B",
//     semasa: 3000,
//     kurang: 1398,
//     sasaran: 2210,
//   },
//   {
//     name: "Page C",
//     semasa: 2000,
//     kurang: 9800,
//     sasaran: 2290,
//   },
//   {
//     name: "Page D",
//     semasa: 2780,
//     kurang: 3908,
//     sasaran: 2000,
//   },
//   {
//     name: "Page E",
//     semasa: 1890,
//     kurang: 4800,
//     sasaran: 2181,
//   },
//   {
//     name: "Page F",
//     semasa: 2390,
//     kurang: 3800,
//     sasaran: 2500,
//   },
//   {
//     name: "Page G",
//     semasa: 3490,
//     kurang: 4300,
//     sasaran: 2100,
//   },
// ];

const Stacked = ({ datas }: any) => {
  //   static demoUrl = 'https://codesandbox.io/s/stacked-bar-chart-s47i2';
  const [data, setData] = React.useState<any[]>();

  React.useEffect(() => {
    console.log(datas);
    if (typeof datas !== "undefined" && typeof data === "undefined") {
      let newdata: any[] = [];
      for (let a = 0; a < datas.length; a++) {
        newdata.push({
          name: datas[a].name,
          semasa: datas[a].current_allocation,
          kurang: datas[a].balance,
          sasaran: datas[a].price,
        });
      }
      setData(newdata);
    }
  }, [datas, data]);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        layout="horizontal"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="semasa" stackId="a" fill="#00ff00" />
        <Bar dataKey="kurang" stackId="a" fill="#ff0000" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Stacked;
