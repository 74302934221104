import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import Button from "@material-ui/core/Button";

// import Grid from "@material-ui/core/Grid";
// import Avatar from "@material-ui/core/Avatar";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { auth } from "../../config/Firebase";
import { AuthDisplay } from "../../config/Acl";
// import type { RootState } from "./../../store/index";
import { setProfile } from "./../../store/user";
// import { isSetAccessor } from "typescript";
// import logging from "../../config/Logging";

export interface IAuthRouteProps {}

// const AuthDisplay: React.FunctionComponent<IAuthRouteProps> = (props) => {
//   const { children } = props;

//   return (
//     <div
//       style={{
//         width: "calc(100vw - 50px)",
//         // height: "100vh",
//         border: "0px solid #ff0000",
//         // paddingTop: "calc(50vh - 150px)",
//       }}
//     >
//       <Grid
//         container
//         spacing={5}
//         direction="row"
//         justifyContent="center"
//         alignItems="center"
//       >
//         {/* <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
//           <img src="/images/logo.png" alt="" />
//         </Grid> */}
//         <Grid item xs={12}>
//           {children}
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { profile } = useSelector((state: RootState) => state.userState);

  const [status, setStatus] = useState<string>();
  const [userprofile, setUserProfile] = useState<any>();
  const [showlogin, setShowLogin] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (/logout/i.test(location.hash) || /logout/i.test(location.pathname)) {
      setStatus("pass");
    } else {
      if (typeof auth !== "undefined") {
        auth.onAuthStateChanged((user: any) => {
          if (user) {
            // console.log(user);
            if (user.emailVerified) {
              user.getIdToken().then((e: any) => {
                fetch("/api/user/whoami", {
                  headers: {
                    Authorization: "Bearer " + e,
                  },
                })
                  .then((response) => response.json())
                  .then((data) => {
                    // console.log(data);
                    if (data?.status === "OK") {
                      data.data.photo_url = user?.photoURL;
                      dispatch(setProfile(data?.data));
                      setUserProfile(data?.data);
                      setStatus("pass");
                    } else {
                      setStatus(
                        data?.status +
                          " — Admin will approve your registration within 24 hours"
                      );
                      setShowLogin(true);
                    }
                  });
              });
            } else {
              // console.log(user);
              if (typeof user?.email !== "undefined" && user?.email !== "") {
                setStatus(
                  "Please verify your email '" +
                    user?.email +
                    "' before logging in"
                );
              } else {
                return <Navigate to="/login" />;
              }
            }

            // setStatus(true);
          } else {
            setStatus("invalid");
          }
        });
      }
    }
  }, [location, dispatch]);

  const checkAccess = () => {
    if (typeof userprofile !== "undefined") {
      // if (location.pathname === "" || location.pathname === "/") {
      //   return true;
      // } else {
      //   const access = userprofile?.access.find((acc: any) => {
      //     // console.log(acc.path, location.pathname);
      //     return location.pathname.match(new RegExp(acc.path, "gi"));
      //   });

      //   if (typeof access !== "undefined") {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
      return true;
    } else {
      return false;
    }
  };

  const goToLogin = () => {
    navigate(`/login`);
  };

  // console.log(status);

  if (typeof status === "undefined") {
    return <div className="mainloading">Loading......</div>;
  } else {
    if (status === "invalid") {
      return <Navigate to="/login" />;
    } else {
      if (status === "pass") {
        if (checkAccess()) {
          return <>{children}</>;
        } else {
          return (
            <AuthDisplay>
              <Alert severity="error">
                <AlertTitle>
                  <strong>Forbidden!</strong>
                </AlertTitle>
                <Typography>
                  You don’t have enough permission to access this module.
                </Typography>
              </Alert>
            </AuthDisplay>
          );
        }
      } else {
        return (
          <AuthDisplay>
            <Alert severity="error">
              <AlertTitle>Notification!</AlertTitle>
              <Typography>{status}</Typography>
            </Alert>
            {showlogin && (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <Button
                  onClick={() => goToLogin()}
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
              </div>
            )}
          </AuthDisplay>
        );
      }
    }
  }
};

export default AuthRoute;
