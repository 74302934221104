import React from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { IsGrantedAccess, AuthDisplay } from "../../config/Acl";

import type { RootState } from "../../store/index";
import { useSelector } from "react-redux";
// import Module from "./Module";
import InfoDialog from "./InfoDialog";
import { Paper } from "@mui/material";
import Back from "../Back";
import List from "./ListNew";
import Breadcrumbs from "../Breadcrumbs";

interface DialogProps {
  user?: any;
}

const IndexPanel = (props: DialogProps) => {
  // const {} = props;
  const history = useNavigate();
  // const [loading, setLoading] = React.useState<boolean>(false);
  const { profile } = useSelector((state: RootState) => state.userState);

  // const [pik, setPik] = React.useState<any>();
  const [info] = React.useState<any>();
  const [open, setOpen] = React.useState<boolean>(false);
  // const [token, setToken] = React.useState<any>("");

  // const moduleCallback = (newdata: any) => {
  //   console.log(newdata);
  //   setInfo(newdata);
  //   setOpen(true);
  // };

  const infoCallback = () => {
    setOpen(false);
  };

  const editAbleForm = (info?: any, type?: any) => {
    // console.log(info);
    if (typeof type !== "undefined" && type === "info") {
      history(`/dashboard/advertisement/info/${info?.id ? info?.id : "new"}`);
    } else {
      history(`/dashboard/advertisement/${info?.id ? info?.id : "new"}`);
    }
    // setEdited(true);
    // setFormData(editdata);
  };

  if (!IsGrantedAccess("advertisement", profile)) {
    return <AuthDisplay />;
  } else {
    return (
      <>
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <Back />
          <Typography variant="subtitle1" component="h6">
            Pengurusan Iklan
          </Typography>
        </div>
        <Breadcrumbs
          current={"Pengurusan Iklan"}
          paths={[
            { name: "Pengurusan", url: "/dashboard" },
            // { name: "Applications", url: "/documents/application" },
          ]}
          className="margintop"
        />
        <Paper elevation={0} className={"papercontainer margintop"}>
          <Grid
            container
            // spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              {/* <Module data={pik} callback={moduleCallback} /> */}
              <List
                // data={pik}
                callback={editAbleForm}
                // selected={moduleCallback}
              />
              {/* {pik && <Module data={pik} callback={moduleCallback} />} */}
            </Grid>
            {info && (
              <InfoDialog data={info} open={open} callback={infoCallback} />
            )}
          </Grid>
        </Paper>
      </>
    );
  }
};

export default IndexPanel;
