import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth } from "../../config/Firebase";
import Back from "../Back";
import Breadcrumbs from "../Breadcrumbs";
import { localeCurrency } from "../Store/Store";
import { OverviewType } from "./Props";
// import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
// import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
// import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
// import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";

const Info = ({}: any) => {
  const { id } = useParams();
  const [token, setToken] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [open, setOpen] = useState<boolean>(false);
  const [agency, setAgency] = useState<string>();
  const [courier, setCourier] = useState<string>();
  const [tracking, setTracking] = useState<string>();
  const [overview, setOverview] = useState<OverviewType>();
  const [trigger, setTrigger] = useState<boolean>();

  useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!token) return;
    setLoading(true);

    fetch("/api/seller/orders/summary/" + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.metadata.status === "OK") {
          setOverview(data.overview);
        }
        setLoading(false);
      });

    return () => {
      setLoading(false);
    };
  }, [token, id, trigger]);

  function handleUpdate() {
    fetch("/api/seller/shipping/update", {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        order_id: parseInt(id),
        agency_id: agency,
        courier: courier,
        tracking_id: tracking,
      }),
    }).then(() => {
      setTrigger(true);
    });
  }

  let component = null;
  if (overview) {
    component = (
      <div style={{ height: "100%", width: "100%" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <Typography
                    fontWeight="bold"
                    fontSize={18}
                    variant="subtitle1"
                  >
                    Profil Pesanaan
                  </Typography>
                  <Paper
                    elevation={0}
                    sx={{
                      padding: "10px",
                      backgroundColor: "#F8F8F8",
                      marginTop: "15px",
                    }}
                  >
                    {overview.order_profile ? (
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                          >
                            Peserta
                          </Typography>
                          <Typography>
                            {overview.order_profile.first_name}
                            {overview.order_profile.last_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                          >
                            Alamat
                          </Typography>
                          <Typography>
                            {overview.order_profile.full_address}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold", marginTop: "20px" }}
                          >
                            Alamat Email
                          </Typography>
                          <Typography>
                            {overview.order_profile.email}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold", marginTop: "20px" }}
                          >
                            Nomor telepon
                          </Typography>
                          <Typography>
                            {overview.order_profile.phone_number}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Paper>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight="bold"
                    fontSize={18}
                    variant="subtitle1"
                  >
                    Rincian Pembayaran
                  </Typography>
                  <Paper
                    elevation={0}
                    sx={{
                      padding: "10px",
                      backgroundColor: "#F8F8F8",
                      marginTop: "15px",
                    }}
                  >
                    {overview.payment_details ? (
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                      >
                        <Grid item xs={12}>
                          <Typography fontWeight="bold">Nama Bank</Typography>
                          <Typography>
                            {overview.payment_details.bank_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography fontWeight="bold">
                            Status Transaksi
                          </Typography>
                          <Typography>
                            {overview.payment_details.payment_status
                              ? overview.payment_details.payment_status
                              : "Tiada transaksi"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography fontWeight="bold">
                            Metode Pembayaran
                          </Typography>
                          <Typography>
                            {overview.payment_details.payment_method
                              ? overview.payment_details.payment_method
                                  .split("_")
                                  .join(" ")
                              : "Tiada method pembayaran"}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <>NO DATA</>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  fontWeight="bold"
                  fontSize={18}
                  variant="subtitle1"
                  margin="22px 0 18px 0"
                >
                  Rincian Kurir
                </Typography>
                <Paper
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#F8F8F8",
                    padding: "20px",
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      tableLayout: "fixed",
                      borderBottom: "1px solid black",
                      borderSpacing: "20px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Toko</th>
                        <th>Kurir</th>
                        <th>Pelacakan ID</th>
                      </tr>
                    </thead>
                  </table>
                  <table
                    style={{
                      width: "100%",
                      tableLayout: "fixed",
                      paddingTop: "10px",
                    }}
                  >
                    {overview.courier_details &&
                    overview.courier_details.length > 0 ? (
                      overview.courier_details.map((key, index) => (
                        <thead key={index}>
                          <tr>
                            <td>{key.agency_name}</td>
                            <td>{key.courier}</td>
                            <td>{key.tracking_id}</td>
                          </tr>
                        </thead>
                      ))
                    ) : (
                      <thead>
                        <tr>
                          <td>NO DATA</td>
                          <td>NO DATA</td>
                          <td>NO DATA</td>
                        </tr>
                      </thead>
                    )}
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "25px",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => setOpen(true)}
                      sx={{
                        backgroundColor: "#003A19",
                        "&:hover": { backgroundColor: "#003A19" },
                      }}
                    >
                      Edit
                    </Button>
                    <Dialog
                      open={open}
                      onClose={() => setOpen(false)}
                      fullWidth
                    >
                      <DialogTitle>Tambah / Edit Kurir</DialogTitle>
                      <DialogContent>
                        <FormControl sx={{ width: "100%", marginTop: "10px" }}>
                          <InputLabel>Pilih Toko</InputLabel>
                          <Select
                            onChange={(e: SelectChangeEvent) =>
                              setAgency(e.target.value)
                            }
                            label="Pilih Toko"
                            fullWidth
                          >
                            {overview.shipping_details.shipment &&
                            overview.shipping_details.shipment.length > 0
                              ? overview.shipping_details.shipment.map(
                                  (akey, aidx) => (
                                    <MenuItem value={akey.agency_id} key={aidx}>
                                      {akey.agency_name}
                                    </MenuItem>
                                  )
                                )
                              : null}
                          </Select>
                        </FormControl>
                        <FormControl
                          sx={{ width: "100%", margin: "15px 0px 15px 0px" }}
                        >
                          <InputLabel>Pilih Kurir</InputLabel>
                          <Select
                            onChange={(e: SelectChangeEvent) =>
                              setCourier(e.target.value)
                            }
                            label="Choose Courier"
                          >
                            <MenuItem value="JNE">JNE</MenuItem>
                            <MenuItem value="FEDEX">FEDEX</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          label="Tracking ID"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setTracking(e.target.value)
                          }
                          sx={{ width: "100%" }}
                        />
                      </DialogContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <Button
                          onClick={() => setOpen(false)}
                          variant="outlined"
                          sx={{ width: "20%" }}
                        >
                          Batal
                        </Button>
                        <div style={{ margin: "0 10px 0 10px" }} />
                        <Button
                          onClick={() => {
                            handleUpdate();
                            setOpen(false);
                          }}
                          variant="contained"
                          type="submit"
                          sx={{ width: "20%" }}
                        >
                          Simpan
                        </Button>
                      </Box>
                    </Dialog>
                  </div>
                </Paper>
              </Grid>
              <Grid item>
                <Typography
                  fontWeight="bold"
                  fontSize={18}
                  variant="subtitle1"
                  margin="22px 0 18px 0"
                >
                  Barang
                </Typography>
                <Paper
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#F8F8F8",
                    padding: "20px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={4.5}>
                      <Typography fontWeight="bold">Nama Produk</Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                      <Typography fontWeight="bold">Harga</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography fontWeight="bold">Kuantiti</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography fontWeight="bold">Total</Typography>
                    </Grid>
                  </Grid>
                  {overview.order_items && overview.order_items.length > 0 ? (
                    overview.order_items.map((key, index) => (
                      <Grid
                        container
                        sx={{ paddingTop: "20px", marginBottom: "20px" }}
                        key={index}
                      >
                        <Grid item xs={4.5}>
                          <Stack direction="row">
                            <img
                              src={
                                key.photos.length > 0
                                  ? key.photos[0]
                                  : "/images/default.png"
                              }
                              alt={key.product_name}
                              style={{
                                width: "40px",
                                height: "45px",
                                marginRight: "10px",
                              }}
                            />
                            <Stack direction="column">
                              <Typography fontSize={12} fontWeight="bold">
                                Agensi: {key.agency_name}
                              </Typography>
                              <Typography fontSize={12}>
                                {key.product_name}
                              </Typography>

                              <Typography fontSize={10}>{key.sku}</Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={2.5}>
                          <Typography>
                            {localeCurrency(key?.price ? key?.price - 0 : 0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography>{key.quantity}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography>
                            {" "}
                            {localeCurrency(
                              key?.total_price ? key?.total_price - 0 : 0
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <>NO DATA</>
                  )}
                  {/* <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      paddingTop: "10px",
                      borderTop: "1px solid black",
                      borderColor: "#e0e0e0",
                    }}
                  >
                    <Grid item>
                      <Typography fontSize={15}>Harga Jualan</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontWeight="bolder" fontSize={20}>
                        -
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      paddingTop: "10px",
                      borderTop: "1px solid black",
                      borderColor: "#e0e0e0",
                    }}
                  >
                    <Grid item>
                      <Typography fontSize={15}>Potongan Harga</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontWeight="bolder" fontSize={20}>
                        -
                      </Typography>
                    </Grid>
                  </Grid> */}
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      paddingTop: "10px",
                      borderTop: "1px solid black",
                      borderColor: "#e0e0e0",
                    }}
                  >
                    <Grid item>
                      <Typography fontSize={15}>Jumlah Pajak: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontWeight="bolder" fontSize={20}>
                        {localeCurrency(
                          overview.tax_amount ? overview.tax_amount - 0 : 0
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      paddingTop: "10px",
                      borderTop: "1px solid black",
                      borderColor: "#e0e0e0",
                    }}
                  >
                    <Grid item>
                      <Typography fontSize={15}>Biaya Pengiriman:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontWeight="bolder" fontSize={20}>
                        {localeCurrency(
                          overview.shipping_cost
                            ? overview.shipping_cost - 0
                            : 0
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      paddingTop: "10px",
                      borderTop: "1px solid black",
                      borderColor: "#e0e0e0",
                    }}
                  >
                    <Grid item>
                      <Typography fontSize={15}>Jumlah:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontWeight="bolder" fontSize={20}>
                        {localeCurrency(
                          overview.total ? overview.total - 0 : 0
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography fontWeight="bold" fontSize={18} variant="subtitle1">
                Ringkasan Penghantaran
              </Typography>
              <Grid container spacing={3} direction="column">
                {overview.shipping_details.shipment &&
                overview.shipping_details.shipment.length > 0
                  ? overview.shipping_details.shipment.map((skey, sidx) => (
                      <Grid item xs={12} md={12} lg={6} key={sidx}>
                        <Paper
                          elevation={0}
                          style={{
                            backgroundColor: "#F8F8F8",
                            padding: "10px",
                            marginTop: "15px",
                          }}
                        >
                          <Typography variant="subtitle1">
                            Status Penghantaran
                          </Typography>
                          <Divider sx={{ margin: "5px 0 12px 0" }} />
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyItems="center"
                            alignItems="center"
                          >
                            <img
                              src={
                                skey.photos.length > 0
                                  ? skey.photos[0]
                                  : "/images/default.png"
                              }
                              alt={skey.agency_name}
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                              }}
                            />
                            <Typography>{skey.agency_name}</Typography>
                          </Stack>
                          <div style={{ margin: "12px 0 12px 0" }} />
                          <Stepper
                            orientation="vertical"
                            sx={{ padding: "10px" }}
                          >
                            <Step active={true} completed={true}>
                              <StepLabel
                              // StepIconComponent={Inventory2OutlinedIcon}
                              >
                                <div style={{ marginLeft: "15px" }}>
                                  <Typography variant="body2">
                                    Tanggal Pemesanan
                                  </Typography>
                                  <Typography variant="caption">
                                    {overview.shipping_details.placed_date
                                      ? overview.shipping_details.placed_date
                                      : null}
                                  </Typography>
                                </div>
                              </StepLabel>
                            </Step>
                            <Step
                              active={
                                overview.shipping_details.payment_date
                                  ? true
                                  : false
                              }
                              completed={
                                overview.shipping_details.payment_date
                                  ? true
                                  : false
                              }
                            >
                              <StepLabel
                                // StepIconComponent={PaymentsOutlinedIcon}
                                sx={{
                                  color: overview.shipping_details.payment_date
                                    ? ""
                                    : "grey",
                                }}
                              >
                                <div style={{ marginLeft: "15px" }}>
                                  <Typography variant="body2">
                                    Pembayaran
                                  </Typography>
                                  <Typography variant="caption">
                                    {overview.shipping_details.payment_date
                                      ? overview.shipping_details.payment_date
                                      : "Pembayaran belum dilaksanakan"}
                                  </Typography>
                                </div>
                              </StepLabel>
                            </Step>
                            <Step
                              active={
                                skey.status_processed === "Yes" ? true : false
                              }
                              completed={
                                skey.status_processed === "Yes" ? true : false
                              }
                            >
                              <StepLabel
                                // StepIconComponent={LocalShippingOutlinedIcon}
                                sx={{
                                  color:
                                    skey.status_processed === "Yes"
                                      ? ""
                                      : "grey",
                                }}
                              >
                                <div style={{ marginLeft: "15px" }}>
                                  <Typography variant="body2">
                                    Diproses
                                  </Typography>
                                  <Typography variant="caption">
                                    {skey.status_processed === "Yes"
                                      ? skey.date_processed
                                      : "Diproses dalam masa 3 hari"}
                                  </Typography>
                                </div>
                              </StepLabel>
                            </Step>
                            <Step
                              active={
                                skey.status_delivered === "Yes" ? true : false
                              }
                              completed={
                                skey.status_delivered === "Yes" ? true : false
                              }
                            >
                              <StepLabel
                                // StepIconComponent={BeenhereOutlinedIcon}
                                sx={{
                                  color:
                                    skey.status_delivered === "Yes"
                                      ? ""
                                      : "grey",
                                }}
                              >
                                <div style={{ marginLeft: "15px" }}>
                                  <Typography variant="body2">
                                    Diproses
                                  </Typography>
                                  <Typography variant="caption">
                                    {skey.date_delivered === "Yes"
                                      ? skey.date_delivered
                                      : null}
                                  </Typography>
                                </div>
                              </StepLabel>
                            </Step>
                          </Stepper>
                        </Paper>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <Back />
        <Typography variant="subtitle1" component="h6">
          Info Pesanan
        </Typography>
      </div>

      <Breadcrumbs
        // current={formdata?.name ? formdata?.name : "info"}
        paths={[
          { name: "Pengurusan", url: "/administration" },
          {
            name: "Pesanan",
            url: "/dashboard/orders",
          },
          {
            name: "Info Pesanan",
            url: "",
          },
        ]}
      />

      <Divider sx={{ margin: "15px 0px 15px 0px" }} />

      {component}
    </div>
  );
};

export default Info;
