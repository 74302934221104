import { Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { auth } from "../../config/Firebase";
import Module from "./Module";
import Breadcrumbs from "../Breadcrumbs";
import { IsGrantedAccess, AuthDisplay } from "../../config/Acl";
import type { RootState } from "../../store/index";
import { useSelector } from "react-redux";
import Back from "../Back";

interface Token {
  token: string;
}

function IndexPanel() {
  const [token, setToken] = useState<Token>();
  const [salesToday, setSalesToday] = useState<number>();
  const [total, setTotal] = useState<number>();
  const [totalReceipt, setTotalReceipt] = useState<number>();
  const [loading, setLoading] = useState<boolean>();
  const { profile } = useSelector((state: RootState) => state.userState);

  useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!token) return;

    setLoading(true);

    fetch("/api/contributor/overview", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.metadata.status === "OK") {
          setSalesToday(data.overview.sales_today);
          setTotal(data.overview.total_net_income);
          setTotalReceipt(data.overview.total_receipt);
          setLoading(false);
        }
      });

    return () => {
      setSalesToday(0);
      setTotal(0);
      setTotalReceipt(0);
      setLoading(true);
    };
  }, [token]);

  if (loading) return <div>Loading...</div>;

  if (!IsGrantedAccess("sales", profile)) {
    return <AuthDisplay />;
  } else {
    return (
      <div>
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <Back />
          <Typography variant="subtitle1" component="h6">
            Pegurusan Agihan
          </Typography>
        </div>
        <div>
          <Breadcrumbs
            paths={[{ name: "Agihan", url: "/dashboard/contribution" }]}
          />
        </div>
        <Divider sx={{ paddingTop: "20px" }} />
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
          paddingTop="20px"

          //   <Typography
          //   variant="h5"
          //   style={{ fontWeight: "bold", color: "#2C2B78" }}
          // >
        >
          <Grid item xs={6} sm={4} md={4}>
            <Paper sx={{ padding: "12px", height: "90%" }}>
              <Typography color="#003A19" fontWeight="bold" variant="subtitle1">
                Jumlah Agihan Bersih
              </Typography>
              <Typography variant="h4" color="#003A19">
                RM {total ? total : 0}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Paper sx={{ padding: "12px", height: "90%" }}>
              <Typography color="#003A19" fontWeight="bold" variant="subtitle1">
                Agihan Hari Ini
              </Typography>
              <Typography variant="h4">
                {salesToday ? salesToday : 0}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Paper sx={{ padding: "12px", height: "90%" }}>
              <Typography color="#003A19" fontWeight="bold" variant="subtitle1">
                Jumlah Agihan
              </Typography>
              <Typography variant="h4">
                {totalReceipt ? totalReceipt : 0}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <div style={{ paddingTop: "30px" }}>
          <Module />
        </div>
      </div>
    );
  }
}

export default IndexPanel;
