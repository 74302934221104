import { Box, Typography } from "@mui/material";
import Logo from "./SILogo";
import { useNavigate } from "react-router-dom";

function TopNav({ logo, title, themecolor, fontcolor, hidetitle }: any) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        // marginLeft: "-25px",
        width: 300,
        display: "flex",
      }}
    >
      <Box
        component="span"
        sx={{
          height: "70px",
          paddingLeft: "15px",
          background: themecolor ? themecolor : "#e5e5e5",

          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          columnGap: 2,
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        <Logo logo={logo} disabledradius={hidetitle} />
        {((typeof hidetitle !== "undefined" && !hidetitle) ||
          typeof hidetitle === "undefined") && (
          <Typography
            variant="h6"
            color="text.primary"
            style={{ color: fontcolor ? fontcolor : "" }}
          >
            {title ? title : ""}
          </Typography>
        )}
      </Box>
      {/* <Box sx={{ flexGrow: 1 }} /> */}
      {/* <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} /> */}
    </Box>
  );
}

export default TopNav;
