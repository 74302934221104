import React, { useState } from "react";
// import MaterialReactTable from "material-react-table"; // type MRT_SortingState, // type MRT_PaginationState, // type MRT_ColumnFiltersState, // type MRT_ColumnDef,
import {
  MaterialReactTable,
  MRT_FullScreenToggleButton as MRTFullScreenToggleButton,
  MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
  MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MRTToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
} from "material-react-table";

import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import { format } from "date-fns";

// import {
//   Edit as EditIcon,
//   Delete as DeleteIcon,
//   Email as EmailIcon,
// } from "@mui/icons-material";

// type UserApiResponse = {
//   searchresults: Array<any>;
//   metadata: {
//     total: number;
//     api_key?: any;
//   };
// };

// type User = {
//   firstName: string;
//   lastName: string;
//   address: string;
//   state: string;
//   phoneNumber: string;
// };

const ITEM_HEIGHT = 48;

const DataTable = ({
  token,
  columns,
  api,
  prefixfilename,
  fieldid,
  pagesize,
  autoheight,
  onRowClick,
  onFilterModelChange,
  apiDownload,
  arrayRef,
  columnhides,
  rowactions,
  disabledPaper,
  toolbarButtons,
  enableRowNumbers,
  dataCallback,
  refresh,
}: any) => {
  // const [defaultcolumn, setDefaultColumn] = React.useState<any[]>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<any>({});
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<any>([]);
  const [pagination, setPagination] = useState<any>({
    pageIndex: 0,
    pageSize: typeof pagesize !== "undefined" && pagesize > 0 ? pagesize : 10, //customize the default page size,
  });

  React.useEffect(() => {
    if (typeof columnhides !== "undefined" && columnhides.length > 0) {
      let newcolumnvisibility: any = {};
      for (let a = 0; a < columnhides.length; a++) {
        newcolumnvisibility[columnhides[a]] = false;
      }
      setColumnVisibility(newcolumnvisibility);
    }
  }, [columnhides]);

  const { data, isError, isFetching, isLoading, refetch } = useQuery<any>({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(api, window?.location.href);
      fetchURL.searchParams.set(
        "start",
        `${pagination.pageIndex * pagination.pageSize}`
      );
      fetchURL.searchParams.set("page", `${pagination.pageIndex + 1}`);
      fetchURL.searchParams.set("limit", `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("q", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response = await fetch(fetchURL.href, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      const json = (await response.json()) as any;
      return json;
    },
    keepPreviousData: true,
    // refetchOnReconnect: false,
  });

  React.useEffect(() => {
    if (typeof refresh !== "undefined" && typeof refetch !== "undefined") {
      refetch();
    }
  }, [refresh, refetch]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const CSVExportOptions: any = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename:
      typeof prefixfilename !== "undefined" ? prefixfilename : "pedi-data-",
    headers: [],
  };

  React.useEffect(() => {
    if (
      typeof dataCallback !== "undefined" &&
      typeof data !== "undefined" &&
      data
    ) {
      dataCallback(data);
    }
  }, [data, dataCallback]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportFromServer = (type: any) => {
    window.alert(
      "Depending on the data size, it may take some time to process this " +
        (data?.metadata?.total
          ? "" + data?.metadata?.total + " rows of data"
          : " data")
    );
    let headers: any[] = [];
    for (let c = 0; c < columns.length; c++) {
      if (type === "column") {
        if (
          typeof columnVisibility[columns[c].accessorKey] === "undefined" ||
          (typeof columnVisibility[columns[c].accessorKey] !== "undefined" &&
            columnVisibility[columns[c].accessorKey])
        ) {
          headers.push({
            id: columns[c].accessorKey,
            label: columns[c].header,
          });
        }
      } else {
        headers.push({
          id: columns[c].accessorKey,
          label: columns[c].header,
        });
      }
    }

    let newurl: string = apiDownload ? apiDownload : api;
    if (/\?/i.test(newurl)) {
      newurl += "&";
    } else {
      newurl += "?";
    }
    newurl += "apikey=" + data?.metadata?.api_key;
    console.log(newurl);

    let windowName =
      "w_" + Date.now() + Math.floor(Math.random() * 100000).toString();
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute(
      "action",
      newurl
      // (apiDownload ? apiDownload : api) + "?apikey=" + data?.metadata?.api_key
    );

    form.setAttribute("target", windowName);

    let params: any[] = [
      {
        id: "start",
        value: pagination.pageIndex * pagination.pageSize,
      },
      {
        id: "limit",
        value: 0,
      },
      {
        id: "filters",
        value: JSON.stringify(columnFilters ?? []),
      },
      {
        id: "q",
        value: globalFilter ?? "",
      },
      {
        id: "sorting",
        value: JSON.stringify(sorting ?? []),
      },
      {
        id: "columns",
        value: JSON.stringify(headers ?? []),
      },
    ];

    for (let a = 0; a < params.length; a++) {
      let hiddenField: any = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", params[a].id);
      hiddenField.setAttribute("value", params[a].value);
      form.appendChild(hiddenField);
      document.body.appendChild(form);
    }

    window.open("", windowName);

    form.submit();
  };

  const handleExportRows = (rows: any) => {
    let options: any = JSON.parse(JSON.stringify(CSVExportOptions));
    options.headers = [];
    options.header_keys = [];
    for (let c = 0; c < columns.length; c++) {
      if (
        typeof columnVisibility[columns[c].accessorKey] === "undefined" ||
        (typeof columnVisibility[columns[c].accessorKey] !== "undefined" &&
          columnVisibility[columns[c].accessorKey])
      ) {
        options.headers.push(columns[c].header);
        options.header_keys.push(columns[c].accessorKey);
      }
    }

    options.filename = options.filename + "selected-" + getDateAsFilename();
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(
      rows.map((row: any) => {
        let newrow: any = {};

        for (let c in options.headers) {
          if (typeof row?.original[options.header_keys[c]] !== "undefined") {
            newrow[options.headers[c]] = row?.original[options.header_keys[c]];
          } else {
            newrow[options.headers[c]] = "";
          }
        }

        return newrow;
      })
    );
  };

  const getDateAsFilename = () => {
    return format(new Date(), "yyyy-MM-dd-HH-mm-SS");
  };

  // const columns = useMemo<MRT_ColumnDef<User>[]>(
  //   () => [
  //     {
  //       accessorKey: "firstName",
  //       header: "First Name",
  //     },
  //     {
  //       accessorKey: "lastName",
  //       header: "Last Name",
  //     },
  //     {
  //       accessorKey: "address",
  //       header: "Address",
  //     },
  //     {
  //       accessorKey: "state",
  //       header: "State",
  //     },
  //     {
  //       accessorKey: "phoneNumber",
  //       header: "Phone Number",
  //     },
  //   ],
  //   []
  // );

  // console.log(columns, columns);
  return (
    <MaterialReactTable
      enableRowNumbers={enableRowNumbers}
      muiTablePaperProps={{
        elevation:
          typeof disabledPaper !== "undefined" && disabledPaper ? 0 : 2,
      }}
      columns={columns ?? []}
      data={
        data && data[arrayRef ? arrayRef : "searchresults"]
          ? data[arrayRef ? arrayRef : "searchresults"]
          : []
      } //data is undefined on first render
      initialState={{ showColumnFilters: false }}
      manualFiltering
      manualPagination
      manualSorting
      enableRowSelection
      muiTableContainerProps={{
        sx: {
          maxHeight:
            typeof autoheight !== "undefined" && autoheight ? "unset" : "500px",
        },
      }}
      muiTableBodyRowProps={({ row }) => ({
        onClick: (event: any) => {
          // console.info(event, row.id);
          if (typeof onRowClick !== "undefined") {
            onRowClick(row.original);
          }
        },
        sx: {
          cursor: "pointer", //you might want to change the cursor too when adding an onClick
        },
      })}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            // p: "0.5rem",
            flexWrap: "wrap",
          }}
        >
          <Button
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            size="small"
            color="info"
            startIcon={<MoreVertIcon fontSize="inherit" />}
            // variant="outlined"
            title="Download Options"
          >
            <Typography variant="caption" sx={{ textTransform: "none" }} noWrap>
              Muat Turun
            </Typography>
          </Button>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "30ch",
                // paddingLeft: "5px",
                // paddingRight: "5px",
              },
            }}
          >
            <MenuItem
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => {
                handleExportFromServer("all");
                handleClose();
              }}
            >
              <ListItemIcon>
                <FileDownloadIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2" noWrap>
                All Data
              </Typography>
            </MenuItem>

            <MenuItem
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                // handleExportFromServer(table.getPrePaginationRowModel().rows);
                handleExportFromServer("column");
                handleClose();
              }}
            >
              <ListItemIcon>
                <FileDownloadIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2" noWrap>
                Selected Column
              </Typography>
            </MenuItem>

            <MenuItem
              disabled={table.getRowModel().rows.length === 0}
              //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
              onClick={() => {
                handleExportRows(table.getRowModel().rows);
                handleClose();
              }}
            >
              <ListItemIcon>
                <FileDownloadIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2" noWrap>
                Current Page Rows
              </Typography>
            </MenuItem>

            <MenuItem
              // onClick={handleClose}
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() => {
                handleExportRows(table.getSelectedRowModel().rows);
                handleClose();
              }}
            >
              <ListItemIcon>
                <FileDownloadIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2" noWrap>
                Selected Rows
              </Typography>
            </MenuItem>
          </Menu>
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      renderToolbarInternalActions={({ table }) => (
        <Box>
          <MRTToggleGlobalFilterButton table={table} />
          <MRTToggleFiltersButton table={table} />
          <MRTShowHideColumnsButton table={table} />
          <MRTToggleDensePaddingButton table={table} />
          <MRTFullScreenToggleButton table={table} />

          {toolbarButtons &&
            typeof toolbarButtons?.buttons !== "undefined" &&
            toolbarButtons?.buttons.length &&
            toolbarButtons?.buttons.map((button: any, idx: any) => (
              <IconButton
                key={idx}
                onClick={() => {
                  if (button?.action) {
                    button?.action();
                  } else {
                    console.log("clicked");
                  }
                }}
              >
                {button?.icon}
              </IconButton>
            ))}
          {/* {toolbarButtons && (
            <IconButton
              onClick={() => {
                // window.print();
                // window.location.href = addButtonToolbar;
              }}
            >
              <AddIcon />
            </IconButton>
          )} */}
        </Box>
      )}
      rowCount={data?.metadata?.total ?? 0}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isFetching,
        sorting,
        columnVisibility,
      }}
      onColumnVisibilityChange={setColumnVisibility}
      enableRowActions={
        rowactions &&
        typeof rowactions?.buttons !== "undefined" &&
        rowactions?.buttons.length > 0
          ? true
          : false
      }
      positionActionsColumn={
        rowactions && rowactions?.position ? rowactions?.position : "last"
      }
      renderRowActions={(rowitem: any) => (
        <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "0px" }}>
          {rowactions &&
            typeof rowactions?.buttons !== "undefined" &&
            rowactions?.buttons.length &&
            rowactions?.buttons.map((button: any, idx: any) => (
              <IconButton
                key={idx}
                color="primary"
                size="small"
                onClick={() => {
                  if (button?.action) {
                    button?.action(
                      rowitem?.row ? rowitem?.row?.original : null
                    );
                    // console.log("here", rowitem.row.original);
                  } else {
                    console.log("clicked");
                  }
                }}
              >
                {button?.icon}
              </IconButton>
            ))}
        </Box>
      )}
    />
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const DataTableWithReactQueryProvider = (
  props: any
  //   {
  //   token,
  //   columns,
  //   api,
  //   prefixfilename,
  //   fieldid,
  //   pagesize,
  //   autoheight,
  //   onRowClick,
  //   onFilterModelChange,
  //   apiDownload,
  //   arrayRef,
  //   columnhides,
  //   rowactions,
  //   disabledPaper,
  //   toolbarButtons,
  //   enableRowNumbers,
  // }: any
) => (
  <QueryClientProvider client={queryClient}>
    <DataTable
      {...props}
      // token={token}
      // columns={columns}
      // api={api}
      // prefixfilename={prefixfilename}
      // fieldid={fieldid}
      // pagesize={pagesize}
      // autoheight={autoheight}
      // onRowClick={onRowClick}
      // onFilterModelChange={onFilterModelChange}
      // apiDownload={apiDownload}
      // arrayRef={arrayRef}
      // columnhides={columnhides}
      // rowactions={rowactions}
      // disabledPaper={disabledPaper}
      // toolbarButtons={toolbarButtons}
      // enableRowNumbers={enableRowNumbers}
    />
  </QueryClientProvider>
);

export default DataTableWithReactQueryProvider;
