import React from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

// import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { auth } from "../../config/Firebase";
// import type { RootState } from "../../store/index";
import "./Index.css";

const StoreCategory = () => {
  const navigate = useNavigate();
  const [token, setToken] = React.useState<any>();
  const [categories, setCategories] = React.useState<any[]>();
  // const { tenant } = useSelector((state: RootState) => state.userState);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        } else {
          setToken("");
        }
      }
    });
  }, []);

  // React.useEffect(() => {
  //   let isMounted = true;

  //   const fetchData = () => {
  //     fetch(
  //       "/api/public/category/list?apikey=5229b774-791d-4c9c-a623-339214ea0a61",
  //       {
  //         headers: {
  //           Authorization: "Bearer " + token,
  //           Accept: "application/json",
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (isMounted) {
  //           if (data?.list) {
  //             setCategories(data?.list);
  //           }
  //         }
  //       });
  //   };

  //   if (typeof categories === "undefined" && token !== "") {
  //     fetchData();
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [token, categories]);

  React.useEffect(() => {
    let isMounted = true;

    const fetchData = () => {
      let fetchurl: string = "/api/public/category/list";
      if (typeof token !== "undefined" && token !== "") {
        fetchurl = "/api/category/list";
      }
      fetch(fetchurl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        // fetch("/api/public/category/list")
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            if (data?.list) {
              setCategories(data?.list);
            }
          }
        });
    };

    if (typeof categories === "undefined") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, categories]);

  return (
    <>
      <Typography variant="body2" className="productcategory_label">
        Katalog
      </Typography>
      <Grid
        container
        spacing={2}
        padding={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {/* <Grid item xs={12}>
      </Grid> */}
        {categories &&
          categories.map((category: any, idx: any) => (
            <Grid
              item
              xs={6}
              sm={3}
              md={2}
              // lg={1}
              // xl={1}
              key={idx}
              style={{
                textAlign: "center",
              }}
            >
              <div
                className="productcategory_thumbnail"
                style={{
                  backgroundImage: `${
                    "url(" +
                    (category?.photourl
                      ? category?.photourl
                      : "/images/default.png") +
                    ")"
                  }`,
                }}
                onClick={() => {
                  navigate(`catagory/${category?.category_id}/product`);
                }}
              >
                &nbsp;
              </div>
              <div className="productcategory_label_container">
                <Typography variant="body2" className="productcategory_label">
                  {category?.category_name}
                </Typography>
              </div>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default StoreCategory;
