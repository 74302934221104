import * as React from "react";
import Slider, {
  SliderThumb,
  // SliderValueLabelProps,
} from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
// import Typography from "@mui/material/Typography";
// import Tooltip from "@mui/material/Tooltip";
// import Box from "@mui/material/Box";

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

const InputSlider = ({ type, range, callback }: any) => {
  return (
    <AirbnbSlider
      slots={{ thumb: AirbnbThumbComponent }}
      onChange={(e: any) => {
        // console.log(e.target.value);
        if (typeof callback !== "undefined") {
          callback(e.target.value);
        }
      }}
      getAriaLabel={(index) =>
        index === 0
          ? "Minimum " + (typeof type !== "undefined" ? type : "")
          : "Maximum " + (typeof type !== "undefined" ? type : "")
      }
      value={typeof range !== "undefined" ? range : [20, 40]}
    />
  );
};

export default InputSlider;
