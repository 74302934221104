import React from "react";
import Typography from "@mui/material/Typography";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Grid from "@material-ui/core/Grid";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

interface Type {
  [key: string]: number | string | object | any;
}

interface DialogProps {
  id?: string;
  token?: string;
  callback?: (data: Type) => void;
}

const UsersPanel = (props: DialogProps) => {
  const { token, id } = props;
  const [loading, setLoading] = React.useState<boolean>(false);

  const [data, setData] = React.useState<any>();

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      setLoading(true);

      fetch("/api/user/list?agency=" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setData(data.users);
            setLoading(false);
          }
        });
    };

    if (
      typeof data === "undefined" &&
      typeof token !== "undefined" &&
      token !== "" &&
      typeof id !== "undefined" &&
      id !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [data, token, id]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {loading ? (
          <Grid
            item
            xs={12}
            sm={12}
            style={{ textAlign: "center", padding: "5px" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12}>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={1} style={{ textAlign: "center" }}>
                #
              </Grid>
              <Grid item xs={12} sm={7} style={{ textAlign: "center" }}>
                Name
              </Grid>
              <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                Role
              </Grid>
              <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                Status
              </Grid>
            </Grid>
            {data &&
              data.map((user: any, idx: any) => (
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  key={idx}
                >
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    style={{ textAlign: "right", paddingRight: "5px" }}
                  >
                    {idx + 1}.
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    {user?.name}

                    <Typography variant="caption" component="p">
                      ({user?.email})
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {user?.position}
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                    {user.status > 0 ? (
                      <>
                        {user.status > 1 ? (
                          <LockPersonIcon style={{ color: "#ff0000" }} />
                        ) : (
                          <VerifiedUserIcon style={{ color: green[500] }} />
                        )}
                      </>
                    ) : (
                      <HourglassEmptyIcon color="secondary" />
                    )}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default UsersPanel;
