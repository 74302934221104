import React from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { auth } from "../../config/Firebase";

import Back from "../Back";
import Form from "../Forms/CategoryForm";
import UploadForm from "../Forms/Upload";
import Breadcrumbs from "../Breadcrumbs";

interface DialogProps {
  user?: any;
}

const FormPanel = (props: DialogProps) => {
  const { id } = useParams();

  const [formdata, setFormData] = React.useState<any>();
  const [token, setToken] = React.useState<any>("");

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      } else {
        console.log("No user detected");
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      // setLoading(true);
      fetch("/api/category/find/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            setFormData(data.category);
            // setLoading(false);
          }
        });
    };

    if (
      typeof id !== "undefined" &&
      id !== "" &&
      id !== "new" &&
      typeof formdata === "undefined" &&
      token !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, formdata, id]);

  return (
    <>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <Back />
        <Typography variant="subtitle1" component="h6">
          Agency/Vendor Form
        </Typography>
      </div>
      <Breadcrumbs
        current={"Agency/Vendor Form"}
        paths={[
          { name: "Pengurusan", url: "/administration" },
          {
            name: "Product Category",
            url: "/dashboard/category",
          },
        ]}
        className="margintop"
      />
      <Paper elevation={0} className={"papercontainer margintop"}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={7}>
            <Form
              id={formdata?.id}
              data={formdata}
              // callback={dataList}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            {token && formdata?.id && (
              <Paper
                style={{
                  width: "100%",
                  height: "10vh",
                  minHeight: "200px",
                  border: "1px dashed #000000",
                  textAlign: "center",
                  verticalAlign: "middle",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <UploadForm
                  // id={formdata?.id}
                  callback={(status?: string, file?: string) => {
                    console.log(status, file);
                    if (status === "COMPLETED") {
                      setFormData({
                        ...formdata,
                        photourl: file,
                      });
                    }
                  }}
                  id={formdata?.id}
                  type={"product_category"}
                  token={token}
                  name={formdata?.id}
                  maxsize={2} //mb
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default FormPanel;
