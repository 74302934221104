import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { localeCurrency } from "../../Store/Store";
import { auth } from "../../../config/Firebase";

export default function FormDialog({ amount }: any) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [token, setToken] = React.useState<any>();

  let { managerID } = useParams();

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        user.getIdToken().then((token: any) => {
          setToken(token);
        });
      }
    });
  }, []);

  const handleClickOpen = () => {
    if (amount > 0) {
      setOpen(true);
    } else {
      window.alert(
        "Sila masukkan jumlah pendapatan setahun anda dan maklumat-maklumat berkaitan"
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveToCont = () => {
    console.log();
    // navigate("/tulus");
    const data = new URLSearchParams();
    data.append("amount", amount.toString());

    fetch("/api/zakat/create", {
      method: "post",
      body: data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // setLoading(false);
        // window.alert("save to contributor");
        // dispatch(setCartStatus(new Date().getTime().toString()));
        if (data?.metadata?.status === "OK") {
          // window.alert("????");
          navigate(`/tulus`);
        } else {
          window.alert("Fail to zakat");
        }
        // navigate(`/store/contribution`);
      })
      .catch((e: any) => {
        //   setQuerystatus("ERROR");
        console.log("error");
        // window.alert("save to contributor");
        // setLoading(false);
      });
  };

  return (
    <>
      {/* <Button size="large" variant="contained" onClick={handleClickOpen}>
        Update
      </Button> */}
      <Button
        variant="contained"
        fullWidth
        // sx={{ backgroundColor: "#808080" }}
        onClick={handleClickOpen}
      >
        Bayar Zakat
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12}>
              Lafaz Zakat
              <Divider />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              Inilah Wang <strong>{amount ? localeCurrency(amount) : 0}</strong>{" "}
              Sebagai Menunaikan Zakat Yang Wajib Ke Atas Diri Saya Kerana Allah
              Ta’ala.
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: "15px", width: "100%" }}>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <Button
                  size="medium"
                  variant="contained"
                  // onClick={() => {}}
                  fullWidth
                  // component={Link}
                  // to={"/tulus"}
                  onClick={(e: any) => saveToCont()}
                >
                  Bayaran FPX
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  size="medium"
                  variant="outlined"
                  onClick={() => setOpen(!open)}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

// function useStyles() {
//   throw new Error("Function not implemented.");
// }
