import React from "react";
import {
  Edit as EditIcon,
  Article as ArticleIcon,
  AddCircle as AddIcon,
} from "@mui/icons-material";

import Table from "../Tables/DynamicTables";
import { auth } from "../../config/Firebase";

import "./Product.css";

interface DialogProps {
  data?: any;
  id?: any;
  callback?: (data: any, type?: any) => void;
}

const IndexPanel = (props: DialogProps) => {
  const { callback, id } = props;

  const [token, setToken] = React.useState<any>("");
  const [columns] = React.useState<any[]>([
    { accessorKey: "id", header: "Id", sortable: true, size: 50 },
    {
      accessorKey: "photos",
      header: "Foto",
      sortable: false,
      size: 50, //small column
      columnDefType: "display", //turns off data column features like sorting, filtering, etc.
      enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
      Cell: (item?: any) => (
        <div
          className="productthumbnail"
          style={{
            width: "100%",
            maxWidth: "40px",
            backgroundImage:
              "url(" +
              (typeof item?.row.original.photos !== "undefined" &&
              item?.row.original.photos.length > 0 &&
              item?.row.original.photos[0] !== ""
                ? item?.row.original.photos[0]
                : "/images/default.png") +
              ")",
          }}
          title={item?.row.original.name ? item?.row.original.name : ""}
        />
      ),
    },
    // { accessorKey: "sku", header: "SKU", sortable: true },
    { accessorKey: "name", header: "Nama", sortable: true },
    { accessorKey: "category_name", header: "Kategori", sortable: true },
    { accessorKey: "total", header: "Semasa", sortable: true },
    { accessorKey: "price", header: "Sasaran", sortable: true },
    { accessorKey: "quantity", header: "Peserta", sortable: true, size: 50 },
  ]);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        }
      }
    });
  }, []);

  const selectedCallback = (selecteddata?: any, type?: any) => {
    if (callback) {
      callback(selecteddata, type);
    }
  };

  return (
    <>
      {token ? (
        <Table
          enableRowNumbers={false}
          disabledPaper={true}
          api={id ? "/api/product/list?category=" + id : "/api/product/list"}
          apiDownload={
            id
              ? "/api/product/download?category=" + id
              : "/api/product/download"
          }
          token={token}
          fieldid={"id"}
          arrayRef={"list"}
          dataCallback={(e: any) => {
            // console.log(e);
            // setCategory(e?.metadata?.category);
          }}
          onRowClick={(e: any) => {
            // console.log("here", e); //remove this if dont use
            // navigate(`/dbpedi/member/form/${e?.id}`);
            if (typeof selectedCallback !== "undefined") {
              // selectedCallback(e);
            }
          }}
          onFilterModelChange={(e: any) => {
            console.log(e); //remove this if dont use
          }}
          prefixfilename={"-products"}
          pagesize={20}
          autoheight={true}
          columns={columns}
          columnhides={["id"]}
          toolbarButtons={{
            position: "last", //first|last
            buttons: [
              {
                icon: <AddIcon />,
                action: () => {
                  console.log("button");
                  selectedCallback({}, "new");
                },
              },
            ],
          }}
          rowactions={{
            name: "Actions",
            position: "last", //first|last
            buttons: [
              {
                icon: <ArticleIcon />,
                action: (row?: any) => {
                  // console.log("here", row);
                  selectedCallback(row, "info");
                },
              },
              {
                icon: <EditIcon />,
                action: (row?: any) => {
                  // console.log("here", row, 'edit');
                  selectedCallback(row, "edit");
                },
              },
            ],
          }}
        />
      ) : (
        <>Loading...</>
      )}
    </>
  );
};

export default IndexPanel;
