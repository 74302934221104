import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface SectionAProps {
  onResultChange?: (result: number) => void;
  sectionA_exist: boolean;
}

interface Values {
  C1: number | null;
}

const SectionC: React.FC<SectionAProps> = ({ onResultChange }) => {
  const [values, setValues] = useState<Values>({
    C1: null,
  });

  useEffect(() => {
    const newTotal = values.C1 || 0;

    onResultChange && onResultChange(newTotal);
  }, [onResultChange, values]);

  const handleInputChange = (
    event: { target: { value: string } },
    id: string
  ) => {
    const newValue = event.target.value.replace(/^0+/, "");
    setValues((prevValues) => ({ ...prevValues, [id]: parseFloat(newValue) }));
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#2980B9" }}>
            <TableRow>
              <TableCell sx={{ color: "#ffffff" }}>Bil</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Butiran</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>RM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>C1</TableCell>
              <TableCell>
                Caruman KWSP bahagian pekerja dibawah umur 55 tahun (jumlah gaji
                kasar x 11%)
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E9ECEF",
                    border: "1px solid #CED4DA",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <div style={{ padding: "5px" }}>RM</div>
                  <input
                    type="number"
                    id="C1"
                    value={values.C1 || ""}
                    onChange={(e) => handleInputChange(e, "C1")}
                    style={{
                      height: "30px",
                      width: "100%",
                      padding: "0",
                      border: "0px solid #CED4DA",
                      paddingLeft: "0.6rem",
                      outline: "none",
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SectionC;
