import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
// import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { auth } from "../../config/Firebase";
// import type { RootState } from "../../store/index";
import "./Index.css";
import Product from "../../components/Store/Product";

const StoreSelectedProduct = ({ curid }: any) => {
  const navigate = useNavigate();
  const [token, setToken] = React.useState<any>();
  const [categories, setCategories] = React.useState<any[]>();
  // const { tenant } = useSelector((state: RootState) => state.userState);

  React.useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        if (typeof user !== "undefined") {
          user.getIdToken().then((e: any) => {
            setToken(e);
          });
        } else {
          setToken("");
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (typeof curid !== "undefined" && curid !== "" && curid !== null) {
      setCategories(undefined);
    }
  }, [curid]);

  React.useEffect(() => {
    let isMounted = true;

    const fetchData = () => {
      let fetchurl: string = "/api/public/product/list?viewed=true";
      if (typeof token !== "undefined" && token !== "") {
        fetchurl = "/api/product/list?viewed=true";
      }

      if (typeof curid !== "undefined" && curid !== "" && curid !== null) {
        fetchurl = fetchurl + "&curid=" + curid;
      }

      fetch(fetchurl, {
        headers:
          typeof token !== "undefined"
            ? {
                Authorization: "Bearer " + token,
              }
            : {},
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            if (data?.list) {
              setCategories(data?.list);
            }
          }
        });
    };

    if (typeof categories === "undefined") {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, categories, curid]);

  const callback = (product?: any) => {
    console.log(product);
    navigate("/store/product/" + product?.id + "#product");

    window.document.body.scrollTop = 0;
    window.document.documentElement.scrollTop = 0;
  };

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Typography variant="body2" className="productcategory_label">
          Produk Lain
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          // padding={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {categories &&
            categories.map((product: any, idx: any) => (
              <Grid item xs={6} sm={4} md={3} key={idx}>
                <Product product={product} callback={callback} />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoreSelectedProduct;
