import * as React from "react";
// import Box from "@mui/material/Box";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

import { useDispatch, useSelector } from "react-redux";
import { localeCurrency } from "../../components/Store/Store";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import UnitButton from "../../components/Store/UnitButton";
import { auth } from "../../config/Firebase";
import { setCartSelected, setCartStatus } from "../../store/form";
import type { RootState } from "../../store/index";

const ShoppingCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [anonymous, setAnonymous] = React.useState<boolean>(false);
  const [checkedall, setCheckedAll] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<any>();
  const [carts, setCarts] = React.useState<any[]>();
  const [cartbyagency, setCartByAgency] = React.useState<any[]>();
  const { profile } = useSelector((state: RootState) => state.userState);
  // const { cartstatus } = useSelector((state: RootState) => state.formState);

  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setToken(token);
        });
      }
    });
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      setLoading(true);
      fetch("/api/apps/cart/list", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (isMounted) {
            console.log("test: ", data);
            setCarts(data?.list);
            setLoading(false);
          }
        })
        .catch(function (err) {
          console.log(err, "error");
          setLoading(false);
        });
    };
    if (
      typeof carts === "undefined" &&
      typeof token !== "undefined" &&
      token !== ""
    ) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [token, carts]);

  React.useEffect(() => {
    if (typeof carts !== "undefined" && carts.length > 0) {
      let cartbyagencyhash: any = {};
      for (let c = 0; c < carts.length; c++) {
        if (typeof cartbyagencyhash[carts[c].agency_id] === "undefined") {
          cartbyagencyhash[carts[c].agency_id] = {
            id: carts[c].agency_id,
            name: carts[c].agency_name,
            photourl: carts[c].agency_photourl,
            checked: carts[c].agency_checked ? carts[c].agency_checked : false,
            products: [],
          };
        }
        carts[c].idx = c;
        if (
          carts[c]?.product_quantity_remaining > 0 &&
          carts[c]?.product_quantity_remaining >= carts[c]?.quantity_buy
        ) {
          carts[c].disabled = false;
        } else {
          carts[c].disabled = true;
        }
        cartbyagencyhash[carts[c].agency_id].products.push(carts[c]);
      }
      let newcartbyagency: any[] = [];
      for (let agency in cartbyagencyhash) {
        newcartbyagency.push(cartbyagencyhash[agency]);
      }
      setCartByAgency(newcartbyagency);
    }
  }, [carts]);

  const updateCart = (idx: any, value: any, id?: any) => {
    let newcarts = JSON.parse(JSON.stringify(carts));

    if (typeof id !== "undefined" && id !== "") {
      const newidx = newcarts.findIndex((c: any) => {
        return c?.cart_id.toString() === id.toString();
      });

      if (newidx >= 0) {
        idx = newidx;
      }
    }
    newcarts[idx].quantity_buy = value;

    setCarts(newcarts);
  };

  const removeFromCart = (idx: any) => {
    // /apps/cart/delete/{id}
    if (typeof carts !== "undefined") {
      setLoading(true);
      fetch("/api/apps/cart/delete/" + carts[idx].cart_id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let newcarts = JSON.parse(JSON.stringify(carts));
          newcarts.splice(idx, 1);
          setCarts(newcarts);
          // setCartByAgency(newcarts);
          setLoading(false);
        })
        .catch((e: any) => {
          setLoading(false);
        });
    }
  };

  const cartToOrder = () => {
    if (typeof carts !== "undefined") {
      setLoading(true);
      if (carts.length < 0) {
        window.alert("Missing required fields.");

        setLoading(false);
      } else {
        const data = new URLSearchParams();

        let newcarts: any[] = [];
        let newcartids: any[] = [];
        for (let c = 0; c < carts.length; c++) {
          if (carts[c].checked) {
            if (!carts[c].disabled) {
              newcartids.push(carts[c].cart_id);
              newcarts.push({
                id: carts[c].product_id,
                cart_id: carts[c].cart_id,
                amount: carts[c].amount,
                user_zakat_id: profile?.zakat?.user_zakat_id,
                anonymous: carts[c].anonymous ? 1 : 0,
              });
            }
          }
        }
        console.log(carts);
        if (newcartids.length > 0) {
          // data.append("cartids", JSON.stringify(newcartids));
          data.append("carts", JSON.stringify(newcarts));

          // dispatch(setCartSelected(newcartids));
          // setLoading(false);
          console.log(carts);
          fetch("/api/apps/cart/contribution", {
            method: "post",
            body: data,
            headers: {
              Authorization: "Bearer " + token,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              setLoading(false);
              // window.alert("save to contributor");
              dispatch(setCartStatus(new Date().getTime().toString()));
              if (data?.metadata?.status === "OK") {
                navigate(`/store/myaccount/contribution`);
              } else {
                window.alert("Fail to contribute");
              }
              // navigate(`/store/contribution`);
            })
            .catch((e: any) => {
              //   setQuerystatus("ERROR");
              console.log("error");
              // window.alert("save to contributor");
              setLoading(false);
            });
          // navigate(`/store/checkout/${btoa(JSON.stringify(newcartids))}`);
        } else {
          setLoading(false);
          window.alert("No valid products selected.");
        }

        // return false;

        // fetch("/api/apps/cart/create", {
        //   method: "post",
        //   body: data,
        //   headers: {
        //     Authorization: "Bearer " + token,
        //   },
        // })
        //   .then((response) => response.json())
        //   .then((data) => {
        //     console.log(data);
        //     setLoading(false);
        //     if (data?.metadata?.status === "OK") {
        //     } else {
        //       // setQuerystatus("ERROR");
        //     }
        //   })
        //   .catch((e: any) => {
        //     //   setQuerystatus("ERROR");
        //     setLoading(false);
        //   });
      }
    } else {
      window.alert("missing cart");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newcarts = JSON.parse(JSON.stringify(carts));
    newcarts[event.target.id].checked = event.target.checked;
    let allchecked: number = 0;

    let hashagency: any = {};
    for (let c = 0; c < newcarts.length; c++) {
      if (typeof hashagency[newcarts[c].agency_id] === "undefined") {
        hashagency[newcarts[c].agency_id] = { total: 0, checked: 0 };
      }
      if (newcarts[c].checked) {
        allchecked++;
        hashagency[newcarts[c].agency_id].checked++;
      }
      hashagency[newcarts[c].agency_id].total++;
    }

    for (let c = 0; c < newcarts.length; c++) {
      if (
        typeof hashagency[newcarts[c].agency_id] !== "undefined" &&
        hashagency[newcarts[c].agency_id].total ===
          hashagency[newcarts[c].agency_id].checked
      ) {
        newcarts[c].agency_checked = true;
      } else {
        newcarts[c].agency_checked = false;
      }
    }

    setCarts(newcarts);
    if (newcarts.length.toString() === allchecked.toString()) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  };

  const handleCheckedAnon = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnonymous(event.target.checked);
  };

  const handleCheckedAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newcarts = JSON.parse(JSON.stringify(carts));
    for (let c = 0; c < newcarts.length; c++) {
      newcarts[c].checked = event.target.checked;
      newcarts[c].agency_checked = event.target.checked;
    }
    setCarts(newcarts);
    setCheckedAll(event.target.checked);
  };

  const handleAgencyAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    let count: number = 0;
    let newcarts = JSON.parse(JSON.stringify(carts));
    for (let c = 0; c < newcarts.length; c++) {
      if (event.target.id.toString() === newcarts[c].agency_id.toString()) {
        newcarts[c].checked = event.target.checked;
        newcarts[c].agency_checked = event.target.checked;
      }
      if (newcarts[c].checked) {
        count++;
      }
    }

    setCarts(newcarts);
    if (count.toString() === newcarts.length.toString()) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  };

  // console.log(profile);
  let total: number = 0;
  let quantity: number = 0;
  return (
    <>
      <div style={{ display: "flex", margin: "15px" }}>
        <div style={{ paddingRight: "5px" }}>
          <ShoppingCartIcon />
        </div>
        <div>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Hi, {profile?.staff_name}
          </Typography>
        </div>
        <div style={{ textAlign: "right", flexGrow: 1 }}>
          {loading && <CircularProgress size={30} />}
        </div>
      </div>
      <Typography
        variant="h6"
        style={{ fontWeight: "bold", marginBottom: "20px" }}
      >
        Bakul Zakat
      </Typography>
      <Typography variant="body2" style={{ marginBottom: "20px" }}>
        Hai {profile?.staff_name}, Hasil daripada kiraan Zakat Pendapatan Jumlah
        zakat anda setahun untuk tahun <strong>{profile?.zakat.year}</strong>{" "}
        adalah sebanyak{" "}
        <strong>{localeCurrency(profile?.zakat?.amount)}</strong>.
      </Typography>

      {(typeof cartbyagency === "undefined" ||
        (typeof cartbyagency !== "undefined" && cartbyagency.length <= 0)) && (
        <Paper
          className="stickytopcontent"
          style={{
            padding: "10px",
            marginBottom: "40px",
            zIndex: 999,
          }}
        >
          <Grid
            container
            padding={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              Bakul anda kosong, sila pilih kempen
            </Grid>
          </Grid>
        </Paper>
      )}
      {typeof cartbyagency !== "undefined" && cartbyagency.length > 0 && (
        <Paper
          className="stickytopcontent"
          style={{
            padding: "10px",
            marginBottom: "40px",
            zIndex: 999,
          }}
        >
          <Grid
            container
            padding={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              Jumlah Zakat (<strong>{profile?.zakat.year}</strong>):{" "}
              <strong>{localeCurrency(profile?.zakat.amount)}</strong>
            </Grid>
            <Grid item xs={12} md={4}>
              &nbsp;{" "}
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: "right" }}>
              Baki Sebelum Agihan:{" "}
              <strong>{localeCurrency(profile?.zakat.balance)}</strong>
            </Grid>
          </Grid>
        </Paper>
      )}

      <Paper style={{ padding: "0px" }}>
        {cartbyagency &&
          cartbyagency.map((agency: any, adx: any) => {
            return (
              // <Paper style={{ padding: "0px" }} key={adx}>
              <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
                key={adx}
              >
                {/* <Grid item xs={1}>
                  <Checkbox
                    id={agency?.id.toString()}
                    checked={agency?.checked ? agency?.checked : false}
                    onChange={handleAgencyAll}
                  />
                </Grid>
                <Grid item xs={3} sm={1} lg={1}>
                  <div
                    className="agency_thumbnail"
                    style={{
                      backgroundImage: `${
                        "url(" +
                        (typeof agency?.photourl !== "undefined" &&
                        agency?.photourl !== ""
                          ? agency?.photourl
                          : "/images/default.png") +
                        ")"
                      }`,
                      maxWidth: "50px",
                    }}
                  >
                    &nbsp;
                  </div>
                </Grid>
                <Grid item xs={8} sm={10} lg={10}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    {agency?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid> */}
                <Grid item xs={12}>
                  {agency.products &&
                    agency.products.map((cart: any, idx: any) => {
                      if (!cart?.disabled) {
                        // total = total + cart?.quantity_buy * cart?.price_each;
                        total = total + (cart?.amount - 0);
                        quantity = quantity + cart?.quantity_buy;
                      }
                      return (
                        <Grid
                          key={idx}
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            // marginBottom: "20px",
                            padding: "10px",
                            borderBottom: "1px solid #eeeeee",
                          }}
                        >
                          <Grid
                            item
                            // xs={12}
                            xs={1}
                            md={1}
                          >
                            <Checkbox
                              id={cart?.idx.toString()}
                              checked={cart?.checked ? cart?.checked : false}
                              onChange={handleChange}
                              disabled={cart?.disabled ? cart?.disabled : false}
                            />
                          </Grid>
                          <Grid
                            item
                            // xs={12}
                            xs={4}
                            md={1}
                          >
                            <div
                              className="cart_thumbnail"
                              style={{
                                backgroundImage: `${
                                  "url(" +
                                  (typeof cart?.photos[0] !== "undefined" &&
                                  cart?.photos[0] !== ""
                                    ? cart?.photos[0]
                                    : "/images/default.png") +
                                  ")"
                                }`,
                              }}
                            >
                              &nbsp;
                            </div>
                          </Grid>
                          <Grid
                            item
                            // xs={12}
                            xs={7}
                            md={3}
                          >
                            <div
                              onClick={() => {
                                navigate(`/store/product/${cart?.product_id}`);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {cart?.product_name}
                            </div>
                          </Grid>
                          {/* <Grid
                            item
                            xs={12}
                            md={2}
                            style={{
                              textAlign: "right",
                              paddingRight: "5px",
                            }}
                          >
                            {localeCurrency(cart?.price_each)}
                            {localeCurrency(cart?.amount)}
                          </Grid> */}
                          {/* <Grid
                            item
                            xs={12}
                            md={2}
                            style={{
                              textAlign: "right",
                              paddingRight: "5px",
                            }}
                          >
                            <UnitButton
                              product={{
                                cart_quantity: cart?.quantity_buy,
                                id: cart?.product_id,
                                quantity: cart?.product_quantity_remaining
                                  ? cart?.product_quantity_remaining
                                  : 0,
                              }}
                              callback={(newvalue: any, id?: any) => {
                                const newquantity = parseInt(newvalue);
                                updateCart(idx, newquantity, id);
                                if (newquantity <= 0) {
                                  removeFromCart(idx);
                                }
                              }}
                            />
                          </Grid> */}
                          <Grid
                            item
                            // xs={12}
                            xs={10}
                            md={2}
                            style={{
                              textAlign: "right",
                              paddingRight: "5px",
                            }}
                          >
                            {/* {localeCurrency(
                              cart?.quantity_buy * cart?.price_each
                            )} */}
                            {/* {localeCurrency(cart?.amount)} */}
                            <TextField
                              fullWidth
                              size={"small"}
                              id="outlined-start-adornment"
                              // sx={{ m: 1, width: "25ch" }}
                              value={cart?.amount}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    RM
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                              variant="outlined"
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                // setSearch(event.currentTarget.value);
                                let newcartbyagency = [...cartbyagency];
                                // console.log(newcartbyagency);
                                newcartbyagency[adx].products[idx].amount =
                                  event.currentTarget.value;

                                setCartByAgency(newcartbyagency);
                              }}
                            />
                          </Grid>
                          {/* <Grid
                            item
                            xs={12}
                            sm={2}
                            md={1}
                            style={{
                              textAlign: "right",
                              paddingRight: "5px",
                            }}
                          ></Grid> */}
                          <Grid
                            item
                            // xs={12}
                            xs={2}
                            md={2}
                            style={{
                              textAlign: "center",
                              paddingRight: "5px",
                            }}
                          >
                            <IconButton
                              aria-label="Store"
                              size="small"
                              color="primary"
                              onClick={() => {
                                // updateCart(cart?.idx, 0);
                                removeFromCart(cart?.idx);
                              }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
                {/* <Grid item xs={12}>
                  &nbsp;
                </Grid> */}
              </Grid>
              // </Paper>
            );
          })}
      </Paper>

      {typeof cartbyagency !== "undefined" && cartbyagency.length > 0 && (
        <Paper className="stickycontent">
          <Grid
            container
            padding={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              style={{ textAlign: "right", paddingBottom: "20px" }}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: "bold",
                  color:
                    profile?.zakat.balance - total < 0 ? "#ff0000" : "#333333",
                }}
              >
                Baki Selepas Agihan:{" "}
                <strong>
                  {localeCurrency(profile?.zakat.balance - total)}
                </strong>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "right", paddingBottom: "20px" }}
            >
              Sembunyikan Nama Saya (Dipaparkan sebagai Hamba Allah){" "}
              <Checkbox
                // id={idx.toString()}
                checked={anonymous}
                onChange={handleCheckedAnon}
              />
            </Grid>
            <Grid item xs={2} sm={5} md={6} lg={7}>
              <Checkbox
                // id={idx.toString()}
                checked={checkedall}
                onChange={handleCheckedAll}
              />{" "}
              Pilih Semua (
              {typeof carts !== "undefined" && carts.length > 0
                ? carts.length
                : 0}
              ) barang
            </Grid>
            <Grid
              item
              xs={10}
              sm={7}
              md={4}
              lg={3}
              style={{ textAlign: "right" }}
            >
              <div className="totalfooter">
                <div>Total : </div>
                <div>({quantity} item)</div>
                <div style={{ flexGrow: "1" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", color: "#ed6c02" }}
                  >
                    {localeCurrency(total)}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              style={{ paddingLeft: "10px" }}
            >
              {typeof carts !== "undefined" && carts.length > 0 && !loading && (
                <Button
                  variant="contained"
                  color="warning"
                  startIcon={<ShoppingCartCheckoutIcon />}
                  onClick={() => cartToOrder()}
                  fullWidth
                  disabled={profile?.zakat.balance - total < 0 ? true : false}
                >
                  Buat Agihan
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default ShoppingCart;
