import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";

import Routes from "../config/Routes";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

interface PropsTotal {
  user?: any;
}
const Home: React.FC<PropsTotal> = ({ user }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {Routes.map((route: any, index: number) => {
        if (/\/form\//i.test(route.path)) {
          return (
            <Grid
              item
              xs={12}
              sm={3}
              // style={{ textAlign: "center" }}
            >
              <Card className={classes.root} key={index}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="/images/pusatinternet.png"
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="p">
                      {route?.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    ></Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  {/* <Button size="small" color="primary">
                    Share
                  </Button> */}
                  <Button
                    size="small"
                    color="primary"
                    component={Link}
                    to={route?.path}
                  >
                    Form
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        } else {
          return "";
        }
      })}
    </Grid>
  );
};

export default Home;
